import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";

export const SnackBarConfig = {
    Duration: {
        SHORT: 1000,
        MEDIUM: 3000,
        LONG: 5000,
        INDEFINITE: 0,
    },
    Actions: {
        OKAY: "Okay",
        CANCEL: "Cancel",
        YES: "Yes",
        NO: "No",
        CLOSE: "Close",
        RETRY: "Retry",
        UNDO: "Undo",
        DISMISS: "Dismiss",
        GOT_IT: "Got it",
    },
    Position: {
        TOP_START: { verticalPosition: "top", horizontalPosition: "start" },
        TOP_CENTER: { verticalPosition: "top", horizontalPosition: "center" },
        TOP_END: { verticalPosition: "top", horizontalPosition: "end" },
        TOP_LEFT: { verticalPosition: "top", horizontalPosition: "left" },
        TOP_RIGHT: { verticalPosition: "top", horizontalPosition: "right" },
        BOTTOM_START: { verticalPosition: "bottom", horizontalPosition: "start" },
        BOTTOM_CENTER: { verticalPosition: "bottom", horizontalPosition: "center" },
        BOTTOM_END: { verticalPosition: "bottom", horizontalPosition: "end" },
        BOTTOM_LEFT: { verticalPosition: "bottom", horizontalPosition: "left" },
        BOTTOM_RIGHT: { verticalPosition: "bottom", horizontalPosition: "right" },
    } satisfies Record<
        string,
        { verticalPosition: MatSnackBarVerticalPosition; horizontalPosition: MatSnackBarHorizontalPosition }
    >, // ✅ Enforces Correct Types Without `as`
    Types: {
        SUCCESS: {
            panelClass: ["snackbar", "snackbar-success"],
            duration: 3000,
        },
        ERROR: {
            panelClass: ["snackbar", "snackbar-error"],
            duration: 5000,
        },
        WARNING: {
            panelClass: ["snackbar", "snackbar-warning"],
            duration: 4000,
        },
        INFO: {
            panelClass: ["snackbar", "snackbar-info"],
            duration: 3000,
        },
    },
    Defaults: {
        panelClass: ["snackbar"],
        duration: 3000,
        horizontalPosition: "center",
        verticalPosition: "bottom",
    } satisfies {
        panelClass: string[];
        duration: number;
        horizontalPosition: MatSnackBarHorizontalPosition;
        verticalPosition: MatSnackBarVerticalPosition;
    }, // ✅ Ensures Correct Type
};
