import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ComponentInteractionSrevice } from "src/app/services/component-interaction/component-interaction.service";
import { ShapeGeneratorService } from "src/app/services/shape-generator/shape-generator.service";
import { AnimationConfig, CanvasConfig } from "src/app/shared/shape-selector/shape-selector.model";
import { ColorCodes, ColorPickerInputConfg } from "@utils/color-constants";
import { MeshType, ShapeType } from "@utils/shape-type";
import * as THREE from "three";
import { Mesh } from "three";
import { InteractionService } from "../../shared/helpers/interaction.service";
import { take } from "rxjs";
import { ShapeResourceManagerService } from "../../shared/helpers/shape-resource-manager.service";

@Component({
    selector: "shape-selector",
    templateUrl: "./shape-selector.component.html",
    styleUrls: ["./shape-selector.component.scss"],
})
export class ShapeSelectorComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild("shapeSelector", { static: true })
    shapeSelector: ElementRef | any;

    private renderer = new THREE.WebGLRenderer({
        alpha: true,
        preserveDrawingBuffer: true,
        antialias: true,
    });

    private scene: THREE.Scene;
    private camera: THREE.PerspectiveCamera;
    private shapes: THREE.Mesh[] = [];
    private raycaster = new THREE.Raycaster();
    private selectedShape: Mesh = new Mesh();
    private cube: any;
    private curvedCube: any;
    private curvedCube2: any;
    private halfCylinder: any;
    private prism: any;
    private squarePyramid: any;
    private cone: any;
    private cylinder: any;
    private hemiSphere: any;
    private rightTriangle: any;
    private concavePrism: any;
    private selectedColour: THREE.ColorRepresentation = ColorPickerInputConfg.valueAttributeForDarkGreen;
    private previousColor!: THREE.ColorRepresentation;
    private clock = new THREE.Clock();

    constructor(
        private shapeGeneratorService: ShapeGeneratorService,
        private componentInteractionSrv: ComponentInteractionSrevice,
        private interactionService: InteractionService,
        private shapeResourceManagerService: ShapeResourceManagerService,
    ) {
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(
            CanvasConfig.cameraConfig.fov,
            CanvasConfig.RenderConfig.width / CanvasConfig.RenderConfig.height, // width by height gives proper angle for camera
        );
        this.renderer.setPixelRatio(window.devicePixelRatio);
    }
    ngOnDestroy(): void {}

    ngOnInit(): void {
        this.shapeResourceManagerService.initializeResources(true);
        this.componentInteractionSrv.getSelectedColor().subscribe((color) => {
            this.previousColor = this.selectedColour;
            this.interactionService.isSelected.pipe(take(1)).subscribe((res) => {
                if (!res) {
                    this.selectedColour = color;
                } else {
                    this.selectedColour = this.previousColor;
                }
                this.shapes.map((shape) => {
                    (shape.material as THREE.MeshBasicMaterial).color.set(this.selectedColour);
                });
            });
        });
        this.generateShapesForSelection();
    }

    ngAfterViewInit(): void {
        this.canvasConfiguration();
    }

    @HostListener("click", ["$event"])
    onCanvasClick(event: MouseEvent): void {
        // Calculate normalized device coordinates and perform raycasting
        const canvasRect = this.renderer.domElement.getBoundingClientRect();
        const x = ((event.clientX - canvasRect.left) / canvasRect.width) * 2 - 1;
        const y = -((event.clientY - canvasRect.top) / canvasRect.height) * 2 + 1;

        // Update the raycaster with the mouse position
        this.raycaster.setFromCamera(new THREE.Vector2(x, y), this.camera);

        // Perform raycasting to check for intersections
        const intersects = this.raycaster.intersectObjects(this.shapes);

        let clickedShape;
        if (intersects.length > 0) {
            for (let i = 0; i < intersects.length; i++) {
                if (intersects[i].object.name !== "") {
                    clickedShape = intersects[i].object as Mesh;
                }
            }
            this.changeShapeColor(clickedShape);
        }
    }

    private canvasConfiguration(): void {
        this.renderer.setSize(CanvasConfig.RenderConfig.width, CanvasConfig.RenderConfig.height);
        this.camera.position.z = CanvasConfig.RenderConfig.cameraPositionZ;
        this.shapeSelector.nativeElement.appendChild(this.renderer.domElement);
        const ambientLight = new THREE.AmbientLight(ColorCodes.white, 1); // 1 is the intensity of the light.
        this.scene.add(ambientLight);
        this.scene.add(this.camera);
        this.generateShapesForSelection();
        this.animate();
    }

    private animate(): void {
        requestAnimationFrame(() => this.animate());
        const delta: number = this.clock.getDelta();
        this.shapes.forEach((shape) => {
            shape.rotation.x += delta * AnimationConfig.rotation.x;
            shape.rotation.y += delta * AnimationConfig.rotation.y;
            shape.rotation.z += delta * AnimationConfig.rotation.z;
        });
        this.renderer.render(this.scene, this.camera);
    }

    private generateShapesForSelection() {
        for (const shape in MeshType) {
            switch (shape) {
                case MeshType.Cube:
                    this.generateCube();
                    break;
                case MeshType.CurvedCube:
                    this.generateCurvedCube();
                    break;
                case MeshType.CurvedCube2:
                    this.generateCurvedCube2();
                    break;
                case MeshType.HalfCylinder:
                    this.generateHalfCylinder();
                    break;
                case MeshType.Prism:
                    this.generatePrism();
                    break;
                case MeshType.SquarePyramid:
                    this.generateSquarePyramid();
                    break;
                case MeshType.Cone:
                    this.generateCone();
                    break;
                case MeshType.Cylinder:
                    this.generateCylinder();
                    break;
                case MeshType.HemiSphere:
                    this.generateSemiCircle();
                    break;
                case MeshType.RightTriangle:
                    this.generateRightTriangle();
                    break;
                case MeshType.ConcavePrism:
                    this.generateConcavePrism();
                    break;
            }
        }
    }

    // Generates Cube
    private generateCube(): void {
        this.cube = this.shapeGeneratorService.generateShape(MeshType.Cube, this.selectedColour, true);
        this.cube.name = MeshType.Cube;
        this.selectedShape = this.cube;
        this.shapes.push(this.cube);
        this.scene.add(this.cube);
    }

    // Generates curved cube
    private generateCurvedCube(): void {
        this.curvedCube = this.shapeGeneratorService.generateShape(MeshType.CurvedCube, this.selectedColour, true);
        this.curvedCube.name = MeshType.CurvedCube;
        this.shapes.push(this.curvedCube);
        this.scene.add(this.curvedCube);
    }

    private generateCurvedCube2(): void {
        this.curvedCube2 = this.shapeGeneratorService.generateShape(MeshType.CurvedCube2, this.selectedColour, true);
        this.curvedCube2.name = MeshType.CurvedCube2;
        this.shapes.push(this.curvedCube2);
        this.scene.add(this.curvedCube2);
    }

    private generateHalfCylinder(): void {
        this.halfCylinder = this.shapeGeneratorService.generateShape(MeshType.HalfCylinder, this.selectedColour, true);
        this.halfCylinder.name = MeshType.HalfCylinder;
        this.shapes.push(this.halfCylinder);
        this.scene.add(this.halfCylinder);
    }

    private generatePrism(): void {
        this.prism = this.shapeGeneratorService.generateShape(MeshType.Prism, this.selectedColour, true);
        this.prism.name = MeshType.Prism;
        this.shapes.push(this.prism);
        this.scene.add(this.prism);
    }

    private generateSquarePyramid() {
        this.squarePyramid = this.shapeGeneratorService.generateShape(
            MeshType.SquarePyramid,
            this.selectedColour,
            true,
        );
        this.squarePyramid.name = MeshType.SquarePyramid;
        this.shapes.push(this.squarePyramid);
        this.scene.add(this.squarePyramid);
    }

    private generateCone() {
        this.cone = this.shapeGeneratorService.generateShape(MeshType.Cone, this.selectedColour, true);
        this.cone.name = MeshType.Cone;
        this.shapes.push(this.cone);
        this.scene.add(this.cone);
    }

    private generateCylinder() {
        this.cylinder = this.shapeGeneratorService.generateShape(MeshType.Cylinder, this.selectedColour, true);
        this.cylinder.name = MeshType.Cylinder;
        this.shapes.push(this.cylinder);
        this.scene.add(this.cylinder);
    }

    private generateSemiCircle() {
        this.hemiSphere = this.shapeGeneratorService.generateShape(MeshType.HemiSphere, this.selectedColour, true);
        this.hemiSphere.name = MeshType.HemiSphere;
        this.shapes.push(this.hemiSphere);
        this.scene.add(this.hemiSphere);
    }

    private generateRightTriangle() {
        this.rightTriangle = this.shapeGeneratorService.generateShape(
            MeshType.RightTriangle,
            this.selectedColour,
            true,
        );
        this.rightTriangle.name = MeshType.RightTriangle;
        this.shapes.push(this.rightTriangle);
        this.scene.add(this.rightTriangle);
    }

    private generateConcavePrism() {
        this.concavePrism = this.shapeGeneratorService.generateShape(MeshType.ConcavePrism, this.selectedColour, true);
        this.concavePrism.name = MeshType.ConcavePrism;
        this.shapes.push(this.concavePrism);
        this.scene.add(this.concavePrism);
    }

    private changeShapeColor(shape: any): void {
        if (shape !== undefined) {
            const mesh = shape.type === ShapeType.Mesh ? shape : (shape.parent as Mesh);
            if (this.selectedShape) {
                const prevMaterial = this.selectedShape.material as THREE.MeshStandardMaterial;
                prevMaterial.transparent = true;
                prevMaterial.opacity = CanvasConfig.transparentOpacity;
            }
            const material = mesh.material as THREE.MeshStandardMaterial;
            material.transparent = false;
            material.opacity = CanvasConfig.solidOpacity;
            this.selectedShape = mesh;
            this.componentInteractionSrv.setSelectedShape(this.selectedShape.name as MeshType);
        }
    }
}
