import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { CountOutputData } from "@utils/count-calculator/count-calculator.model";
import { MeshType } from "~/src/utils/shape-type";

@Injectable({
    providedIn: "root",
})
export class ComponentInteractionSrevice {
    private interceptInfo2 = new Subject<THREE.Intersection<THREE.Object3D<THREE.Event>>[]>();
    private interceptInfo = new Subject<THREE.Intersection<THREE.Object3D<THREE.Event>>[]>();
    private eventInfo = new Subject<THREE.Object3D>();
    private passSelectedMesh = new Subject<MeshType | null>();
    private cameraRotation = new Subject<THREE.Vector3>();
    private objectInfo = new Subject<THREE.Object3D[]>();
    private selectedColor = new Subject<THREE.ColorRepresentation>();
    private outputPattern = new Subject<CountOutputData[]>();
    private redirectionPath = new Subject<string>();
    private prevoiusColor = new Subject<THREE.ColorRepresentation>();
    private selectedShapes: THREE.Object3D[] = [];
    private invalidShapes: THREE.Object3D[] = [];
    private validShapes: THREE.Object3D[] = [];

    constructor() {}

    public getSeletedShape(): Observable<MeshType | null> {
        return this.passSelectedMesh.asObservable();
    }

    public setSelectedShape(selectedShape: MeshType | null) {
        this.passSelectedMesh.next(selectedShape);
    }

    public setGeneratedShapeInfo(event: THREE.Object3D): void {
        this.eventInfo.next(event);
    }

    public getGeneratedShapeInfo(): Observable<THREE.Object3D> {
        return this.eventInfo.asObservable();
    }

    public setInterceptInfo(interceptInfo: THREE.Intersection<THREE.Object3D<THREE.Event>>[]): void {
        interceptInfo && this.interceptInfo.next(interceptInfo);
    }

    public getInterceptInfo(): Observable<THREE.Intersection<THREE.Object3D<THREE.Event>>[]> {
        return this.interceptInfo.asObservable();
    }

    public getRotationObservable(): Observable<THREE.Vector3> {
        return this.cameraRotation.asObservable();
    }

    public updateRotation(rotation: THREE.Vector3) {
        this.cameraRotation.next(rotation);
    }

    public updateSelectedColor(color: THREE.ColorRepresentation) {
        this.selectedColor.next(color);
    }

    public getSelectedColor(): Observable<THREE.ColorRepresentation> {
        return this.selectedColor.asObservable();
    }

    public get selectedshapes1(): THREE.Object3D[] {
        return this.selectedShapes;
    }

    public setSelectedShapes(selectedShape: THREE.Object3D[]) {
        this.selectedShapes = selectedShape;
    }

    public shapeColor(color: THREE.ColorRepresentation) {
        this.prevoiusColor.next(color);
    }

    public getShapeColor(): Observable<THREE.ColorRepresentation> {
        return this.prevoiusColor.asObservable();
    }

    public setOutputPattern(outputPattern: CountOutputData[]): void {
        this.outputPattern.next(outputPattern);
    }

    public getOutputPattern(): Observable<CountOutputData[]> {
        return this.outputPattern.asObservable();
    }

    public setInterceptInfo2(interceptInfo: THREE.Intersection<THREE.Object3D<THREE.Event>>[]): void {
        interceptInfo && this.interceptInfo2.next(interceptInfo);
    }

    public getInterceptInfo2(): Observable<THREE.Intersection<THREE.Object3D<THREE.Event>>[]> {
        return this.interceptInfo2.asObservable();
    }

    public setRedirectionPath(redirectionPath: string): void {
        this.redirectionPath.next(redirectionPath);
    }

    public getRedirectionPath(): Observable<string> {
        return this.redirectionPath.asObservable();
    }
    public setInvalidShapes(shapes: THREE.Object3D[]) {
        this.invalidShapes = shapes;
    }

    public get getInvalidShapes(): THREE.Object3D[] {
        return this.invalidShapes;
    }

    public setValidShapes(shapes: THREE.Object3D[]) {
        this.validShapes = shapes;
    }
    public get getValidShapes(): THREE.Object3D[] {
        return this.validShapes;
    }
}
