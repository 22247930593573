import { ColorCodes } from "@utils/color-constants";
import { Injectable } from "@angular/core";
import { HelperService } from "src/app/shared/helpers/helper.service";
import * as THREE from "three";
import { AlteredShapeConfiguration, Object3DUserData } from "@utils/shape";
import { SetShapeUserDataService } from "src/app/shared/helpers/set-shape-userdata.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Face, FaceType } from "../../../../../utils/count-calculator/count-calculator.model";
import { CanvasConfig } from "../../../../../utils/canvas-configuration";
import { FaceIdentifierService } from "../../shared/face-identifier/face-identifier.service";
import { CubeFace } from "../../shared/face-identifier/face-identifier.constants";
import { AuxiliaryObjectType, MeshType } from "~/src/utils/shape-type";
import { MatSnackBarMessages } from "../../shared/mat-snackbar/mat-snackbar.model";
import { SnackBarConfig } from "../../constants/snackbar.constants";
@Injectable({
    providedIn: "root",
})
export class DragAndDropService {
    public draggedObject: THREE.Mesh = new THREE.Mesh();
    private meshes: THREE.Mesh[] = [];
    private groundPositionY =
        CanvasConfig.planePostionIn_Y_Coordinate +
        AlteredShapeConfiguration.standardHeigthOfShape / AlteredShapeConfiguration.divisorAdjustYPosition;
    private objectDictionary: Record<string, THREE.Object3D> = {}; // This is to optimise the search.
    private defaultScaleOfAnyShape = new THREE.Vector3(1, 1, 1);
    public isCollide = false;
    public isGridAvailable = false;

    constructor(
        private helperService: HelperService,
        private setShapeUserDataService: SetShapeUserDataService,
        private snackBar: MatSnackBar,
        private faceIdentifier: FaceIdentifierService,
    ) {}

    /*
    This method handels the dragging of the selected shapes and collision detection.
  */
    public dragObject(
        draggableMesh: THREE.Mesh | null,
        mouseMovePosition: THREE.Vector2,
        camera: THREE.Camera,
        objects: THREE.Object3D[],
    ): void {
        this.meshes = objects.filter((object) => object instanceof THREE.Mesh);
        this.draggedObject = draggableMesh as THREE.Mesh;
        if (draggableMesh) {
            this.updateObjectDictionary(this.meshes);
            const draggedObjectIndex = this.meshes.indexOf(draggableMesh);

            if (draggedObjectIndex !== -1) {
                const raycaster = new THREE.Raycaster();
                raycaster?.setFromCamera(mouseMovePosition, camera);
                const foundShapes = raycaster.intersectObjects(this.meshes, false)!; // Means the plane along with other object
                const firstShape = foundShapes[0];
                const face = this.worldFaceDetection(firstShape);
                this.isGridAvailable = this.checkGridAvailable(firstShape, face);

                if (
                    firstShape?.object instanceof THREE.Mesh &&
                    firstShape?.object.id !== draggableMesh.id && // Mouse pointed face
                    firstShape?.object.name !== AuxiliaryObjectType.Plane
                ) {
                    const realMeshFace = this.faceIdentifier.identifyFace(firstShape.face!.normal);
                    const faces = CubeFace;
                    const foundFace = Object.entries(faces).filter(([key, value]) => value === realMeshFace);
                    const faceKeyName = foundFace.length > 0 ? foundFace[0][0] : "Unknown face";
                    if (faceKeyName !== FaceType.Curved) {
                        if (!this.isGridAvailable) {
                            this.dragableMeshPosition(firstShape.object as THREE.Mesh, face);
                        } else {
                            this.snackBar.open(MatSnackBarMessages.ErrorMessage5, SnackBarConfig.Actions.CLOSE, {
                                duration: SnackBarConfig.Duration.SHORT,
                            });
                        }
                    } else {
                        this.snackBar.open(
                            MatSnackBarMessages.ErrorMessageToPlaceCurved,
                            SnackBarConfig.Actions.CLOSE,
                            {
                                duration: SnackBarConfig.Duration.SHORT,
                            },
                        );
                    }
                }

                if (foundShapes.length) {
                    let nearestCollision = false;
                    // We create a bounding box of the dragged shape first.
                    const dragBoundingBox = new THREE.Box3().setFromObject(draggableMesh);
                    // This will copy the position to the dragged shape with respect to the mouse coordinates on the plane
                    for (const shape of foundShapes) {
                        // Shape here refers to the plane
                        if (shape.object.userData[Object3DUserData.isDraggable]) continue;
                        if (
                            shape.point.x < CanvasConfig.maxMinConstant &&
                            shape.point.x > -CanvasConfig.maxMinConstant &&
                            shape.point.z > -CanvasConfig.maxMinConstant &&
                            shape.point.z < CanvasConfig.maxMinConstant &&
                            !this.isCollide
                        ) {
                            draggableMesh.position.x = shape.point.x;
                            draggableMesh.position.z = shape.point.z;
                        }
                    }

                    // We need to update the position of the bounding box simultaneously with the dragged shape
                    dragBoundingBox
                        .copy((draggableMesh as any).geometry.boundingBox)
                        .applyMatrix4(draggableMesh.matrixWorld);

                    // To end the loop as soon as the collided shape is found.
                    for (let object of objects) {
                        if (!object.userData[Object3DUserData.isDraggable] || object === draggableMesh) {
                            continue; // Skip non draggable objects and the dragged object itself
                        }
                        // We create another bounding box for the shape that is found colliding.
                        const shapeBoundingBox = new THREE.Box3().setFromObject(object);
                        // We check with the boundingboxes and detect the collision.
                        const isCollision = dragBoundingBox.intersectsBox(shapeBoundingBox);

                        if (isCollision && !nearestCollision) {
                            nearestCollision = true;
                            if (firstShape.object.name !== AuxiliaryObjectType.Plane) {
                                this.performCollisionActions(draggableMesh as THREE.Mesh, object as THREE.Mesh);
                            } else if (firstShape.object.name === AuxiliaryObjectType.Plane) {
                                if (!this.isGridAvailable) {
                                    this.isCollide = false;
                                } else {
                                    this.snackBar.open(
                                        MatSnackBarMessages.ErrorMessage5,
                                        SnackBarConfig.Actions.CLOSE,
                                        {
                                            duration: SnackBarConfig.Duration.SHORT,
                                        },
                                    );
                                }
                            }
                        }
                    }

                    if (!nearestCollision) {
                        this.revertCollisionActions(draggableMesh as THREE.Mesh);
                    }
                    this.setIncreasedYforScaledShape(draggableMesh);
                    this.draggedObject.userData[Object3DUserData.cellReference] = null;
                    this.meshes[draggedObjectIndex] = draggableMesh;
                    objects[objects.indexOf(draggableMesh)] = draggableMesh;
                }
            }
        }
    }

    // Check grid is available or not
    private checkGridAvailable(
        hitObject: THREE.Intersection<THREE.Object3D<THREE.Event>>,
        activeFace: string,
    ): boolean {
        if (hitObject?.object.name === AuxiliaryObjectType.Plane) {
            //  when hit object is plane
            const cellSize = CanvasConfig.cellSize;

            // Convert 3D coordinates to 2D grid coordinates
            const twoDCoords = new THREE.Vector2(hitObject.point.x, hitObject.point.z);
            const nearestGridCoords = this.helperService.getNearestGridCords(twoDCoords);

            // Calculate the grid row and column based on the nearest grid coordinates
            const row = this.calculateGridIndex(nearestGridCoords.y, cellSize);
            const column = this.calculateGridIndex(nearestGridCoords.x, cellSize);

            // Create cell reference object
            const cellReference = { row, column, cell: 0 };
            // Check if the cell is occupied
            return this.isMeshInCellReference(cellReference);
        } else {
            let cellReference: { row: number; column: number; cell: number } =
                hitObject?.object.userData[Object3DUserData.cellReference];
            let adjacentCellReference: { row: number; column: number; cell: number } | null = null;
            adjacentCellReference = this.getAdjacentCellForFace(activeFace, cellReference);
            return !!(adjacentCellReference && this.isMeshInCellReference(adjacentCellReference));
        }
    }

    private getAdjacentCellForFace(face: string, currentCellReference: { row: number; column: number; cell: number }) {
        let adjacentCellReference = { ...currentCellReference };
        switch (face) {
            case Face.Front:
                adjacentCellReference.column += 1;
                break;
            case Face.Right:
                adjacentCellReference.row -= 1;
                break;
            case Face.Back:
                adjacentCellReference.column -= 1;
                break;
            case Face.Left:
                adjacentCellReference.row += 1;
                break;
            case Face.Top:
                adjacentCellReference.cell += 1;
                break;
            case Face.Bottom:
                adjacentCellReference.cell -= 1;
                break;
        }
        return adjacentCellReference;
    }

    private calculateGridIndex(coordinate: number, cellSize: number): number {
        return coordinate / cellSize;
    }

    private isMeshInCellReference(cellReference: { row: number; column: number; cell: number }): boolean {
        return this.meshes.some(
            (mesh) =>
                mesh.userData?.[Object3DUserData.cellReference] &&
                mesh.userData[Object3DUserData.cellReference].row === cellReference.row &&
                mesh.userData[Object3DUserData.cellReference].column === cellReference.column &&
                mesh.userData[Object3DUserData.cellReference].cell === cellReference.cell,
        );
    }

    // world Matrix face detection
    private worldFaceDetection(intersection: THREE.Intersection<THREE.Object3D<THREE.Event>>): string {
        if (!intersection?.face) return "";

        const worldNormal = intersection
            .face!.normal.clone()
            .applyMatrix3(new THREE.Matrix3().getNormalMatrix(intersection.object.matrixWorld))
            .normalize();

        if (Math.abs(worldNormal.x - 1) < 0.1) return Face.Front;
        if (Math.abs(worldNormal.x + 1) < 0.1) return Face.Back;
        if (Math.abs(worldNormal.y - 1) < 0.1) return Face.Top;
        if (Math.abs(worldNormal.y + 1) < 0.1) return Face.Bottom;
        if (Math.abs(worldNormal.z - 1) < 0.1) return Face.Left;
        if (Math.abs(worldNormal.z + 1) < 0.1) return Face.Right;

        return "Curved face";
    }

    // This method handels dropping of the dragged shape on right click of the mouse.
    public dropObject(draggableMesh: THREE.Mesh | null, objects: THREE.Object3D[]): THREE.Mesh | null {
        this.isCollide = false;
        this.draggedObject = draggableMesh as THREE.Mesh;
        // this.objects = objects;
        this.meshes = objects.filter((object) => object instanceof THREE.Mesh);
        this.updateObjectDictionary(this.meshes);

        if (draggableMesh) {
            if (draggableMesh.userData[Object3DUserData.isInCollision]) {
                this.draggedObject.userData[Object3DUserData.isInCollision] = false;
                this.draggedObject.material = draggableMesh.userData[Object3DUserData.initialMaterial];
            } else {
                this.setShapeToNearGridCoords();
            }

            // Below lines will update the dragged object new position in the grid data.
            const { row, column, cell } = this.helperService.calculateRowAndColumn(this.draggedObject as THREE.Mesh);

            this.draggedObject.userData[Object3DUserData.cellReference] = {
                row: row,
                column: column,
                cell: cell,
            };
            this.updateInitialPosition();
        }
        this.setShapeUserDataService.adjacentData(this.meshes, draggableMesh as THREE.Mesh);
        this.setIncreasedYforScaledShape(draggableMesh);
        return draggableMesh;
    }

    private get isShapeScaled(): boolean {
        return (
            this.draggedObject.scale.x !== this.defaultScaleOfAnyShape.x ||
            this.draggedObject.scale.y !== this.defaultScaleOfAnyShape.y ||
            this.draggedObject.scale.z !== this.defaultScaleOfAnyShape.z
        );
    }

    /*
    When the shape is scaled, the shape is expanded in all the direction including y
    since y has to be constant to match the plane we need to adjust the y value of
    the shape manually.
  */

    private setIncreasedYforScaledShape(draggableMesh: THREE.Object3D<THREE.Event> | null): void {
        if (draggableMesh && this.isShapeScaled && draggableMesh.position) {
            draggableMesh.position.y = draggableMesh?.position.y + AlteredShapeConfiguration.standardHeigthOfShape / 2;
        }
    }

    /*
    This method handels the collision detection, we are currently reducing the opacity of the dragged shape if it comes in
    contact with any of the shape on the plane. And these changes are temporary.
  */
    private performCollisionActions(draggedObject: THREE.Mesh, collidedObject: THREE.Mesh): void {
        this.isCollide = true;
        this.draggedObject = draggedObject;
        draggedObject.userData[Object3DUserData.isInCollision] = true;
        draggedObject.userData[Object3DUserData.collidedObjectId] = collidedObject.uuid;
        // This is the new transperent material that is being applied on the shape
        const transparentMaterial = new THREE.MeshBasicMaterial({
            color: ColorCodes.red,
            transparent: true,
            opacity: CanvasConfig.transparentOpacity,
        });

        draggedObject.material = transparentMaterial;
        const nearestGridCords = this.helperService.getNearestGridCords(
            new THREE.Vector2(draggedObject.position.x, draggedObject.position.z),
        );
        if (draggedObject.name !== MeshType.HemiSphere && draggedObject.name !== MeshType.HalfCylinder) {
            this.draggedObject.position.set(nearestGridCords.x, this.draggedObject.position.y, nearestGridCords.y);
        }
    }

    private dragableMeshPosition(foundMesh: THREE.Mesh, faceType: string): void {
        // Dragable Mesh will Show according to face normal
        const offset = AlteredShapeConfiguration.standardHeigthOfShape;
        const nearestGridCords = this.helperService.getNearestGridCords(
            new THREE.Vector2(this.draggedObject.position.x, this.draggedObject.position.z),
        );

        const targetPosition = new THREE.Vector3(foundMesh.position.x, foundMesh.position.y, foundMesh.position.z);
        if (foundMesh.name === MeshType.HalfCylinder) {
            targetPosition.y += 5;
        }
        // Adjust position based on face type
        switch (faceType) {
            case Face.Top:
                targetPosition.y += offset; // Example adjustment for the top face
                break;
            case Face.Bottom:
                targetPosition.y -= offset; // Adjustment for the bottom face
                break;
            case Face.Front:
                targetPosition.x += offset; // Adjustment for the front face
                break;
            case Face.Back:
                targetPosition.x -= offset; // Adjustment for the back face
                break;
            case Face.Left:
                targetPosition.z += offset; // Adjustment for the left face
                break;
            case Face.Right:
                targetPosition.z -= offset; // Adjustment for the right face
                break;
            default:
                this.draggedObject.position.set(nearestGridCords.x, foundMesh.position.y, nearestGridCords.y);
                break;
        }

        // Align the bounding box center to the target position
        this.alignBoundingBoxCenter(targetPosition);
    }
    /*
  Reverts back all the temporary changes done on the shape during collision.
*/
    private revertCollisionActions(draggedObject: THREE.Mesh): void {
        draggedObject.userData[Object3DUserData.isInCollision] = false;
        draggedObject.material = draggedObject.userData[Object3DUserData.initialMaterial];
        draggedObject.position.y = this.groundPositionY;
    }

    /*
    This method loops untill it gets the empty spot on the plane
    The logic here is we get the mouse coordinates check if there is any shape on that grid if found move to the next grid
    else return the co-ordinates, It only returns the 'x' and 'y' since our 'z' remains constant.
  */
    private findEmptyGridSpot(startingGridCords: THREE.Vector2, draggableMesh: THREE.Mesh): THREE.Vector2 {
        let emptyGridCords = startingGridCords.clone();
        while (this.checkCollisionOnGrid(emptyGridCords, draggableMesh)) {
            // Move to the next grid cell
            emptyGridCords.x += CanvasConfig.cellSize;

            // If the end of the row is reached, move to the next row
            if (emptyGridCords.x >= CanvasConfig.maxMinConstant) {
                emptyGridCords.x = startingGridCords.x;
                emptyGridCords.y += CanvasConfig.cellSize;
            }
        }

        return emptyGridCords;
    }

    // Helps in looping through each grid and returns boolean based on the presence of any shape.
    private checkCollisionOnGrid(gridCords: THREE.Vector2, draggableMesh: THREE.Mesh): boolean {
        // Loop through objects and check for collision at the specified grid coordinates
        for (const mesh of this.meshes) {
            if (mesh !== draggableMesh) {
                const objectBoundingBox = new THREE.Box3().setFromObject(mesh);
                if (
                    objectBoundingBox.containsPoint(
                        new THREE.Vector3(gridCords.x, draggableMesh.position.y, gridCords.y),
                    )
                ) {
                    return true; // Collision detected
                }
            }
        }
        return false; // No collision detected
    }

    // This method handels the placement of the shapes on top of another

    private setShapeToNearGridCoords(): void {
        const boundingBox = new THREE.Box3().setFromObject(this.draggedObject);
        const boundingBoxCenter = boundingBox.getCenter(new THREE.Vector3());
        const locationX = boundingBoxCenter.x;
        let locationY = boundingBoxCenter.y;
        const locationZ = boundingBoxCenter.z;

        // Here we make the shape sit on the grid avoiding them to go inside.
        locationY = this.helperService.getGroundLocationY(locationY);

        // First we get the nearest grid co-ordinates
        const nearestGridCords = this.helperService.getNearestGridCords(new THREE.Vector2(locationX, locationZ));

        // Find an empty grid spot to place the shape
        const emptyGridCords = this.findEmptyGridSpot(nearestGridCords, this.draggedObject as THREE.Mesh);

        const snappedPosition = new THREE.Vector3(emptyGridCords.x, this.groundPositionY, emptyGridCords.y);

        // Set the position of the draggable object to the nearest grid intersection if no shape below is found within the range.
        if (!this.isShapePresentBelow(emptyGridCords.x, emptyGridCords.y)) {
            const positionKey = `${emptyGridCords.x}-${this.groundPositionY}-${emptyGridCords.y}`;
            const shapeWithExactPosition = this.objectDictionary[positionKey];

            if (shapeWithExactPosition) {
                const initialPosition = this.draggedObject.userData[Object3DUserData.initialPosition];
                this.draggedObject.position.set(initialPosition.x, initialPosition.y, initialPosition.z);
            } else {
                this.alignBoundingBoxCenter(snappedPosition);
            }
        } else {
            // Set the position of the draggable object to the nearest grid intersection.
            this.draggedObject.position.set(emptyGridCords.x, locationY, emptyGridCords.y); // prevoius code
        }
    }

    private alignBoundingBoxCenter(targetCenter: THREE.Vector3): void {
        if (this.draggedObject.name === MeshType.HemiSphere || this.draggedObject.name === MeshType.HalfCylinder) {
            this.draggedObject.geometry.computeBoundingBox();
            const boundingBox = this.draggedObject.geometry.boundingBox!;
            const boundingBoxCenter = new THREE.Vector3();
            boundingBox.getCenter(boundingBoxCenter);
            boundingBoxCenter.y = boundingBox.max.y;
            boundingBoxCenter.applyMatrix4(this.draggedObject.matrixWorld);
            const offset = new THREE.Vector3().subVectors(targetCenter, boundingBoxCenter);
            this.draggedObject.position.add(offset);
        } else {
            // Calculate the bounding box center of the object
            const boundingBox = new THREE.Box3();
            boundingBox.setFromObject(this.draggedObject);
            const currentCenter = boundingBox.getCenter(new THREE.Vector3());
            // Calculate the offset to move the object
            const updatePosition = new THREE.Vector3().subVectors(targetCenter, currentCenter);
            this.draggedObject.position.add(updatePosition);
        }
    }

    private isShapePresentBelow(x: number, z: number): boolean {
        const maxY = this.draggedObject.position.y - AlteredShapeConfiguration.standardHeigthOfShape; // Assuming locationY is the initial position

        for (const mesh of this.meshes) {
            if (mesh.position.x === x && mesh.position.z === z) {
                if (mesh.position.y === maxY) {
                    return true; // Found a shape below within the specified range
                }
            }
        }

        return false; // No shape found below within the range
    }

    private updateInitialPosition(): void {
        this.draggedObject.userData[Object3DUserData.initialPosition].copy(this.draggedObject.position);
    }

    private updateObjectDictionary(objects: THREE.Object3D[]) {
        this.objectDictionary = {};
        for (const obj of objects) {
            const positionKey = `${obj.position.x}-${obj.position.y}-${obj.position.z}`;
            this.objectDictionary[positionKey] = obj;
        }
    }
}
