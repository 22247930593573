import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { GetCountComponent } from "../get-count/get-count.component";
import { ComponentInteractionSrevice } from "src/app/services/component-interaction/component-interaction.service";
import { CountCalculatorService } from "src/app/services/count-calculator.service";
import { ActionButtonContainer, ActionsButton, SaveButton, SlideInOut } from "@utils/action";
import { DialogService } from "../../services/dialog/dialog.service";
import { StructureService } from "../../services/structure/structure.service";
import {
    StructureBaseModel,
    StructureInstance,
    StructureResponseMessage,
    StructureStatus,
    StructureUpdateModel,
} from "@models/structure.model";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { CountOutputData } from "../../../../../utils/count-calculator/count-calculator.model";
import { DialogInvokingComponents } from "@models/generic-dialog.model";
import { ActivatedRoute, Router } from "@angular/router";
import { filter, finalize, mergeMap, Subscription, switchMap } from "rxjs";
import { InteractionService } from "../../shared/helpers/interaction.service";
import { ActionHelperService } from "../../action-helper.service";
import * as THREE from "three";
import { HelperService } from "../../shared/helpers/helper.service";
import { environment } from "~/src/environments/environment";
import { S3UploadService } from "../../services/s3-upoad/s3-upload.service";
import { SceneManagerService } from "../../services/scene-manager/scene-manager.service";
import { Object3DUserData } from "~/src/utils/shape";
import { S3UploadCategoryName, S3UploadFileType } from "@models/fileUpload.model";
import { ColorCodes } from "~/src/utils/color-constants";
import { CanvasConfig } from "~/src/utils/canvas-configuration";
import { MeshType, AuxiliaryObjectType } from "~/src/utils/shape-type";
import { SnackBarConfig } from "../../constants/snackbar.constants";

@Component({
    selector: "action-button-container",
    templateUrl: "./action-button-container.component.html",
    styleUrls: ["./action-button-container.component.scss"],
    animations: [
        trigger(SlideInOut.triggerName, [
            state(
                SlideInOut.trueState,
                style({
                    top: SlideInOut.topPos,
                    right: SlideInOut.rightPoswithStateTrue,
                }),
            ),
            state(
                SlideInOut.falseState,
                style({
                    top: SlideInOut.topPos,
                    right: SlideInOut.rightPoswithStateFalse,
                }),
            ),
            transition(SlideInOut.transition_true_to_false, animate(SlideInOut.animate_duration)),
            transition(SlideInOut.transition_false_to_true, animate(SlideInOut.animate_duration)),
        ]),
    ],
})
export class ActionButtonContainerComponent implements OnInit, OnDestroy {
    public isOpen = false;
    public showButton = false;
    public closeIconLabel = ActionsButton.closeIconLabel;
    @Input() isEditMode: boolean = false;
    @Input() isEditted = false;
    @Input() structureData!: StructureInstance | null;
    public structureStatus = StructureStatus;
    public actionsBtns = [
        {
            label: ActionsButton.flipTopLabel,
            action: ActionsButton.flipTopHint,
            revertaction: ActionsButton.revertBackHint,
        },
        {
            label: ActionsButton.filpLeftLabel,
            action: ActionsButton.flipLeftHint,
        },
        {
            label: ActionsButton.flipRightLabel,
            action: ActionsButton.flipRightHint,
        },
        {
            label: ActionsButton.flipFrontLabel,
            action: ActionsButton.flipForntHint,
        },
        {
            label: ActionsButton.flipBackLabel,
            action: ActionsButton.flipBackhint,
        },
    ];

    public saveBtns = [
        {
            iconlabel: SaveButton.saveStructureIconLabel,
            iconHint1: SaveButton.saveStructureIconHint,
        },
        {
            iconlabel: SaveButton.updateStructureIconLabel,
            iconHint1: SaveButton.updateStructureIconHint,
        },
        {
            iconlabel: SaveButton.publishIconLabel,
            iconHint1: SaveButton.publishIconHint,
        },
    ];

    // Getter to filter buttons based on isEditMode
    get filteredSaveBtns() {
        return this.saveBtns.filter(
            (button) =>
                button.iconlabel === SaveButton.publishIconLabel ||
                (this.isEditMode &&
                    this.structureData!.status !== StructureStatus.Published &&
                    this.structureData!.status !== StructureStatus.Approved &&
                    button.iconlabel === SaveButton.updateStructureIconLabel) ||
                ((!this.isEditMode ||
                    this.structureData!.status === StructureStatus.Published ||
                    this.structureData!.status === StructureStatus.Approved) &&
                    button.iconlabel === SaveButton.saveStructureIconLabel),
        );
    }

    public objects!: THREE.Object3D[];
    private outputPattern!: CountOutputData[];
    public autoCapturedImageUrl!: string;
    public structureId!: number;
    @Output() showSpinner = new EventEmitter<boolean>();
    @Input() autoCaptureCanvasFunction!: () => File;

    private subscriptions: Subscription[] = [];

    public toggleNav(): void {
        this.isOpen = !this.isOpen;
        if (!this.showButton) {
            this.showButton = !this.showButton;
        } else {
            setTimeout(() => {
                this.showButton = !this.showButton;
            }, 500);
        }
    }

    constructor(
        private sceneManagerService: SceneManagerService,
        private dialog: MatDialog,
        private countCalculatorService: CountCalculatorService,
        private componentInteractionSrv: ComponentInteractionSrevice,
        private dialogService: DialogService,
        private structureService: StructureService,
        private snackBar: MatSnackBar,
        private router: Router,
        private interactionService: InteractionService,
        private actionHelperService: ActionHelperService,
        private s3UploadService: S3UploadService,
        private route: ActivatedRoute,
        private helperService: HelperService,
    ) {}

    ngOnInit(): void {
        const urlSegments = this.route.snapshot.url.map((segment) => segment.path);
        this.structureId = +urlSegments[1];

        const sceneManagerSub = this.sceneManagerService.objects$.subscribe((objects) => {
            this.objects = objects;
        });
        this.subscriptions.push(sceneManagerSub);

        const componentInteractionSub = this.componentInteractionSrv.getOutputPattern().subscribe((res) => {
            this.outputPattern = res;
        });
        this.subscriptions.push(componentInteractionSub);
    }

    public openDialogBox(): void {
        const meshes = this.sceneManagerService.getMeshes();
        if (meshes.length) {
            this.showSpinner.emit(true);
            setTimeout(() => {
                try {
                    this.countCalculatorService.calculate(meshes);
                    this.dialog.open(GetCountComponent, {
                        width: ActionButtonContainer.panelWidth,
                        panelClass: ActionButtonContainer.panelClassName,
                        data: this.outputPattern,
                        disableClose: true,
                    });
                } finally {
                    this.showSpinner.emit(false);
                }
            }, 100);
        }
    }

    public actions(
        index: number,
        actionBtn: {
            label: string;
            action: string;
        },
    ): void {
        const objects: THREE.Object3D[] = this.interactionService.intersect;
        const mesh = objects.find((obj) => obj instanceof THREE.Mesh) as THREE.Mesh;

        if (mesh) {
            if (mesh.name === MeshType.Cube) {
                return;
            }

            switch (actionBtn.label) {
                case ActionsButton.flipTopLabel:
                    this.actionHelperService.flipTop();
                    break;
                case ActionsButton.filpLeftLabel:
                    this.actionHelperService.flipLeft();
                    break;
                case ActionsButton.flipRightLabel:
                    this.actionHelperService.flipRight();
                    break;
                case ActionsButton.flipFrontLabel:
                    this.actionHelperService.flipFront();
                    break;
                case ActionsButton.flipBackLabel:
                    this.actionHelperService.flipBack();
                    break;
                default:
                    break;
            }
        }
    }

    private openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            ...SnackBarConfig.Defaults,
        });
    }

    public async saveActions(iconValue: string): Promise<void> {
        this.showSpinner.emit(true);

        if (iconValue === SaveButton.saveStructureIconLabel || iconValue === SaveButton.updateStructureIconLabel) {
            if (!this.checkIfStructureCreated(iconValue)) {
                this.showSpinner.emit(false);
                return;
            }

            setTimeout(async () => {
                if (this.processShapesAndHandleInvalid()) {
                    this.showSpinner.emit(false);
                    return;
                }

                // Stop spinner before showing dialog
                this.showSpinner.emit(false);

                const data = {
                    componentName: DialogInvokingComponents.StructureDetails,
                    title: iconValue === SaveButton.saveStructureIconLabel ? "Save Structure" : "Update Structure",
                    firstBtn: iconValue === SaveButton.saveStructureIconLabel ? "Save" : "Update",
                    secondBtn: "Cancel",
                    structureName:
                        iconValue === SaveButton.saveStructureIconLabel ||
                        (!this.isEditMode && this.structureData?.status === this.structureStatus.Saved)
                            ? ""
                            : this.structureData?.structureName || "",
                    structureDescription:
                        iconValue === SaveButton.saveStructureIconLabel ||
                        (!this.isEditMode && this.structureData?.status === this.structureStatus.Saved)
                            ? ""
                            : this.structureData?.structureDescription || "",
                };

                this.dialogService
                    .openDialog(data, "70%", { disableClose: true })
                    .afterClosed()
                    .pipe(
                        filter((response) => !!response),
                        switchMap(async (response) => {
                            this.showSpinner.emit(true);

                            // Get the file directly using the parent's function
                            const file = this.autoCaptureCanvasFunction();
                            const urls = await this.s3UploadService.multipartOperation(
                                [file],
                                S3UploadCategoryName.Structures,
                                S3UploadFileType.Images,
                            );
                            this.autoCapturedImageUrl = urls[0];

                            const meshes = this.sceneManagerService.getMeshes();
                            this.countCalculatorService.calculate(meshes);

                            let fileUrl: string = "";
                            if (this.sceneManagerService.isSaveAsGLB) {
                                // Export to GLB file and get the URL
                                fileUrl = await this.sceneManagerService.exportToS3(
                                    S3UploadCategoryName.Structures,
                                    S3UploadFileType.GlbFiles,
                                    response.structureName,
                                );
                            } else {
                                // Upload JSON file and get the URL
                                fileUrl = await this.uploadStructureDataToS3();
                            }

                            const body: StructureBaseModel = {
                                structureName: response.structureName,
                                structureDescription: response.structureDescription,
                                structureData: fileUrl,
                                isUpdated: true,
                                status: StructureStatus.Saved,
                                imageurl: this.autoCapturedImageUrl,
                                modelQuantityJson: {
                                    totalcount: meshes.length,
                                    faceinformation: this.outputPattern,
                                },
                            };

                            return iconValue === SaveButton.saveStructureIconLabel
                                ? this.structureService.saveStructure(body)
                                : this.structureService.updateStructure(this.structureData!.id, body);
                        }),
                        mergeMap((response) => response),
                        finalize(() => this.showSpinner.emit(false)),
                    )
                    .subscribe({
                        next: (response) => {
                            this.handleResponse(response);
                        },
                    });
            }, 100);
        } else if (iconValue === SaveButton.publishIconLabel) {
            if (
                !this.checkStructureStatus() ||
                !this.checkIfStructureCreated(iconValue) ||
                this.processShapesAndHandleInvalid()
            ) {
                this.showSpinner.emit(false);
                return;
            }

            // Stop spinner before showing dialog
            this.showSpinner.emit(false);

            if (this.isEditMode) {
                if (this.isEditted) {
                    // Handle case where the structure has been edited
                    this.handleEditedStructure();
                } else {
                    // Handle case where no changes were made
                    this.handleUnchangedStructure();
                }
            } else {
                // Handle the case when not in edit mode
                this.handleNonEditMode();
            }
        }
    }

    private handleNonEditMode(): void {
        const data = {
            componentName: DialogInvokingComponents.StructureDetails,
            title: "Save and Publish Structure",
            firstBtn: "Publish",
            secondBtn: "Cancel",
            isPublish: true,
            structureName: this.structureData?.structureName || "",
            structureDescription: this.structureData?.structureDescription || "",
        };

        this.dialogService
            .openDialog(data, "70%", { disableClose: true })
            .afterClosed()
            .pipe(
                // Filter out cases where the dialog was closed without a response
                filter((response) => !!response),
                switchMap(async (response) => {
                    this.showSpinner.emit(true);

                    // Get the file directly using the parent's function
                    const file = this.autoCaptureCanvasFunction();
                    const urls = await this.s3UploadService.multipartOperation(
                        [file],
                        S3UploadCategoryName.Structures,
                        S3UploadFileType.Images,
                    );
                    this.autoCapturedImageUrl = urls[0];

                    const meshes = this.sceneManagerService.getMeshes();
                    this.countCalculatorService.calculate(meshes);

                    let fileUrl: string = "";
                    if (this.sceneManagerService.isSaveAsGLB) {
                        fileUrl = await this.sceneManagerService.exportToS3(
                            S3UploadCategoryName.Structures,
                            S3UploadFileType.Images,
                            response.structureName,
                        );
                    } else {
                        fileUrl = await this.uploadStructureDataToS3();
                    }

                    const body: StructureBaseModel = {
                        structureName: response.structureName,
                        structureDescription: response.structureDescription,
                        structureData: fileUrl,
                        isUpdated: true,
                        status: StructureStatus.Published,
                        imageurl: this.autoCapturedImageUrl,
                        modelQuantityJson: {
                            totalcount: meshes.length,
                            faceinformation: this.outputPattern,
                        },
                    };
                    return this.structureService.saveAndPublishStructure(body);
                }),
                // Flatten the inner Observable
                mergeMap((response) => response),
                finalize(() => this.showSpinner.emit(false)),
            )
            .subscribe({
                next: (response) => {
                    this.handleResponse(response);
                },
            });
    }

    private handleUnchangedStructure(): void {
        const data = {
            componentName: DialogInvokingComponents.MyStructuresList,
            title: "Publish Structure",
            content:
                "Are you sure you want to publish this structure? Once published, it will be visible to all users after admin approval.",
            firstBtn: "Publish",
            secondBtn: "Cancel",
        };

        this.dialogService
            .openDialog(data, "70%", { disableClose: true })
            .afterClosed()
            .pipe(
                filter((response) => !!response), // Only proceed if result is truthy
                switchMap(() => {
                    this.showSpinner.emit(true);
                    return this.structureService.publishStructures([this.structureId]);
                }),
                finalize(() => this.showSpinner.emit(false)),
            )
            .subscribe({
                next: (response) => {
                    this.handleResponse(response);
                },
            });
    }

    private handleEditedStructure(): void {
        const data = {
            componentName: DialogInvokingComponents.StructureDetails,
            title: "Update and Publish Structure",
            content:
                "Are you sure you want to update and publish this structure? Once published, it will be visible to all users after admin approval.",
            firstBtn: "Publish",
            secondBtn: "Cancel",
            structureName: this.structureData?.structureName || "",
            structureDescription: this.structureData?.structureDescription || "",
        };

        this.dialogService
            .openDialog(data, "70%", { disableClose: true })
            .afterClosed()
            .pipe(
                filter((response) => !!response),
                switchMap(async (response) => {
                    this.showSpinner.emit(true);
                    // Get the file directly using the parent's function
                    const file = this.autoCaptureCanvasFunction();
                    const urls = await this.s3UploadService.multipartOperation(
                        [file],
                        S3UploadCategoryName.Structures,
                        S3UploadFileType.Images,
                    );
                    this.autoCapturedImageUrl = urls[0];

                    const meshes = this.sceneManagerService.getMeshes();
                    this.countCalculatorService.calculate(meshes);

                    let fileUrl: string = "";
                    if (this.sceneManagerService.isSaveAsGLB) {
                        // Export to GLB file and get the URL
                        fileUrl = await this.sceneManagerService.exportToS3(
                            S3UploadCategoryName.Structures,
                            S3UploadFileType.GlbFiles,
                            response.structureName,
                        );
                    } else {
                        // Upload JSON file and get the URL
                        fileUrl = await this.uploadStructureDataToS3();
                    }
                    const body: StructureUpdateModel = {
                        structureName: response.structureName,
                        structureDescription: response.structureDescription,
                        structureData: fileUrl,
                        isUpdated: true,
                        status: StructureStatus.Saved,
                        imageurl: this.autoCapturedImageUrl,
                        modelQuantityJson: {
                            totalcount: meshes.length,
                            faceinformation: this.outputPattern,
                        },
                    };
                    return this.structureService.updateAndPublishStructure(this.structureId, body);
                }),
                // Flatten the inner Observable
                mergeMap((response) => response),
                finalize(() => this.showSpinner.emit(false)),
            )
            .subscribe({
                next: (response) => {
                    this.handleResponse(response);
                },
            });
    }

    private checkStructureStatus(): boolean {
        if (this.structureData?.status && this.structureData?.status !== StructureStatus.Saved) {
            this.openSnackBar(StructureResponseMessage.CannotPublishCurrentStatus, SnackBarConfig.Actions.OKAY);
            return false;
        }
        return true;
    }

    private checkIfStructureCreated(iconValue: SaveButton): boolean {
        const hasShape = this.objects && !!this.getMeshesWithoutPlane().length;

        const isSaveAction = [
            SaveButton.publishIconLabel,
            SaveButton.saveStructureIconLabel,
            SaveButton.updateStructureIconLabel,
        ].includes(iconValue);

        if (isSaveAction) {
            if (!hasShape) {
                const message =
                    iconValue === SaveButton.publishIconLabel
                        ? StructureResponseMessage.NoStructureCreatedBeforePublish
                        : StructureResponseMessage.NoStructureFound;

                this.openSnackBar(message, SnackBarConfig.Actions.OKAY);
                return false;
            }
            return true;
        }
        return false; // Handle any other icon values
    }

    private markShapeAsInvalid(shape: THREE.Mesh): void {
        (shape.material as THREE.MeshBasicMaterial).color.set(ColorCodes.red);
        (shape.material as THREE.MeshStandardMaterial).transparent = true;
        (shape.material as THREE.MeshStandardMaterial).opacity = 0.5;
    }

    public markShapeAsValid(shape: THREE.Mesh): void {
        const material = shape.material as THREE.MeshBasicMaterial;
        material.color = new THREE.Color(ColorCodes.transparentBlue);
        material.transparent = true;
        material.opacity = CanvasConfig.transparentOpacity;
    }

    public resetShapeProperties(shape: THREE.Object3D): void {
        const changedColor = new THREE.Color(shape.userData[Object3DUserData.colour]);
        if (changedColor) {
            ((shape as THREE.Mesh).material as THREE.MeshStandardMaterial).color.copy(changedColor);
            ((shape as THREE.Mesh).material as THREE.MeshStandardMaterial).transparent = false;
            ((shape as THREE.Mesh).material as THREE.MeshStandardMaterial).opacity = 1;
        }
    }

    //check the valid or invalid shapes while save/publish
    private processShapesAndHandleInvalid(): boolean {
        const objectsWithoutPlaneMesh: THREE.Mesh[] = this.getMeshesWithoutPlane();

        const invalidShapes = objectsWithoutPlaneMesh.filter((shape) => {
            const isInvalid = this.helperService.isShapeOnAir(shape as THREE.Mesh, objectsWithoutPlaneMesh);
            if (isInvalid) {
                this.markShapeAsInvalid(shape);
            } else {
                this.resetShapeProperties(shape);
            }
            return isInvalid;
        });
        this.interactionService.render();

        if (invalidShapes.length) {
            const validShapes = objectsWithoutPlaneMesh.filter(
                (shape) => !invalidShapes.some((invalidShape) => invalidShape.id === shape.id),
            );
            this.componentInteractionSrv.setInvalidShapes(invalidShapes);
            this.componentInteractionSrv.setValidShapes(validShapes);
            validShapes.forEach((shape) => {
                this.markShapeAsValid(shape);
            });
            this.interactionService.render();

            this.openSnackBar(StructureResponseMessage.ShapeIsNotValid, SnackBarConfig.Actions.OKAY);
            return true;
        }
        this.componentInteractionSrv.setInvalidShapes(invalidShapes);
        return false;
    }

    private uploadStructureDataToS3(): Promise<string> {
        return new Promise((resolve, reject) => {
            const objectsWithoutPlaneMesh = this.getMeshesWithoutPlane();

            const objectsWithoutPlaneJson = objectsWithoutPlaneMesh.map((object: THREE.Mesh) => {
                const objectJson = object.toJSON();
                object.userData[Object3DUserData.rotation] = {
                    x: object.rotation.x,
                    y: object.rotation.y,
                    z: object.rotation.z,
                    order: object.rotation.order,
                };
                return objectJson;
            });

            const partSize = 5 * 1024 * 1024;
            const file = JSON.stringify(objectsWithoutPlaneJson);
            const parts = Math.ceil(file.length / partSize);
            const partNumbers = Array.from({ length: parts }, (_, i) => i + 1);

            const now = new Date();
            const formattedDate = `${now.getFullYear()}${(now.getMonth() + 1).toString().padStart(2, "0")}${now
                .getDate()
                .toString()
                .padStart(2, "0")}${now.getHours().toString().padStart(2, "0")}${now
                .getMinutes()
                .toString()
                .padStart(2, "0")}${now.getSeconds().toString().padStart(2, "0")}`;

            const filePathWithName = `${environment.name}/structures/models/${formattedDate}.json`;

            this.s3UploadService
                .uploadFile(filePathWithName, partNumbers, file)
                .then((url) => {
                    resolve(url); // Resolve the promise with the URL
                })
                .catch((error) => {
                    reject(error); // Reject the promise in case of an error
                });
        });
    }

    public convertFileToDataURL(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
        });
    }

    public isDisabled(btn: { iconlabel: SaveButton; iconHint1: SaveButton }): boolean {
        const disabledConditions: Partial<Record<SaveButton, () => boolean>> = {
            [SaveButton.updateStructureIconHint]: () => !this.isEditted,
            [SaveButton.publishIconHint]: () =>
                !!this.structureData?.status && this.structureData?.status !== StructureStatus.Saved,
        };

        return disabledConditions[btn.iconHint1]?.() ?? false;
    }

    public handleResponse(data: any): void {
        this.openSnackBar(data.message, SnackBarConfig.Actions.OKAY);
        setTimeout(() => {
            this.router.navigateByUrl("myStructures");
        }, 500);
    }

    public getMeshesWithoutPlane(): THREE.Mesh[] {
        return this.objects
            .filter((object) => object instanceof THREE.Mesh && object.name !== AuxiliaryObjectType.Plane)
            .map((object) => object as THREE.Mesh);
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions to avoid memory leaks
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }
}
