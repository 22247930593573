import { OperationsModel } from "./order.model";
import { NullableAddresses } from "./user.model";

export enum DialogInvokingComponents {
    ProductDetailPage = "Product Detail Page",
    MyStructuresList = "My Structures List",
    StructureDetails = "Structure Details",
    ImageSlider = "Image Slider",
    PreviewImage = "Preview Image",
    HelpMessage = "Help Message",
    CourierInfo = "Courier Info",
    ReturnOrder = "Return Order",
    ReturnOrderCod = "Return Order for CoD",
    PickupScheduled = "Pickup Scheduled",
    ApproveRejectReview = "Approve Reject Review",
    AddAddress = "Add Address",
    ChangeAddress = "Change Address",
    DeleteAddress = "Delete Address",
    RemoveFromCart = "Remove From Cart",
    CancelOrder = "Cancel Order",
    DeleteAdvertisement = "Delete Advertisement",
    SaveDesignConfirmation = "Save Design Confirmation",
    MyStructuresListProperties = "My Structures List Properties",
    EmailMessage = "Email message",
    RemoveFromNotification = "Remove From Notification",
    ExchangeMessage = "Exchange Products",
    RefundInitiated = " Refund Initiated",
    ActiveCoupon = "Active Coupon",
    popUP360 = "popUP360",
    RejectStructure = "Reject Structure",
}

export interface DialogData {
    componentName: DialogInvokingComponents;
    title: string;
    firstBtn: string;
    secondBtn?: string;
    structureName?: string;
    structureDescription?: string;
    content?: string;
    inputs?: OperationsModel[];
    showTextArea?: boolean;
    image?: string;
    addresses?: NullableAddresses;
    selectedAddressId?: number;
    withUserProfileDetails?: boolean;
    hideAgeAndShowMobile?: boolean;
    imageSlider?: Array<object>;
}
