export enum Action {
    add = "add",
    edit = "edit",
    delete = "delete",
}

export interface UndoRedoAction {
    actionType: Action;
    object?: object;
    meshes?: object[];
    objects?: ObjectState[]; // For edit actions
}

export interface ObjectState {
    object: object;
    previousOrientation?: number[];
    previousRotation?: object;
    previousColor?: string;
    previousPosition?: object;
}
