import { Injectable } from "@angular/core";
import * as THREE from "three";
import { AlteredShapeConfiguration } from "~/src/utils/shape";
import { ColorCodes } from "~/src/utils/color-constants";
import { AuxiliaryObjectType, MeshType } from "~/src/utils/shape-type";

@Injectable({
    providedIn: "root",
})
export class ShapeResourceManagerService {
    private geometries: {
        [key in MeshType]: THREE.BufferGeometry;
    } = {} as { [key in MeshType]: THREE.BufferGeometry };

    private outlineGeometries: {
        [key in MeshType]: THREE.EdgesGeometry;
    } = {} as { [key in MeshType]: THREE.EdgesGeometry };

    private geometriesForSelector: {
        [key in MeshType]: THREE.BufferGeometry;
    } = {} as { [key in MeshType]: THREE.BufferGeometry };

    private outlineGeometriesForSelector: {
        [key in MeshType]: THREE.EdgesGeometry;
    } = {} as { [key in MeshType]: THREE.EdgesGeometry };

    private offsetCoordinatesForSelector: {
        [key in MeshType]: THREE.Vector3 | null;
    } = {} as { [key in MeshType]: THREE.Vector3 | null };

    constructor() {}

    public initializeResources(isForSelector: boolean): void {
        // Initialize geometries and their outlines in one pass
        Object.entries(MeshType).forEach(([key, type]) => {
            let geometry: THREE.BufferGeometry;
            let offSetCoordinatesForSelector: THREE.Vector3 = new THREE.Vector3();

            switch (type) {
                case MeshType.Cube:
                    geometry = new THREE.BoxGeometry(
                        isForSelector ? 3 : 20,
                        isForSelector ? 3 : 20,
                        isForSelector ? 3 : 20,
                    );
                    offSetCoordinatesForSelector.set(-8, 5, 0);
                    break;
                case MeshType.Cuboid:
                    geometry = new THREE.BoxGeometry(20, 28.28, 20);
                    break;
                case MeshType.CurvedCube:
                    geometry = new THREE.ExtrudeGeometry(
                        [
                            new THREE.Shape()
                                .moveTo(0, 0)
                                .lineTo(0, isForSelector ? 3 : 20)
                                .absarc(0, 0, isForSelector ? 3 : 20, Math.PI / 2, 0, true)
                                .lineTo(0, 0),
                        ],
                        {
                            depth: isForSelector ? 3 : 20,
                            bevelEnabled: false,
                            bevelSegments: 0,
                            steps: 1,
                            bevelSize: 0,
                            bevelThickness: 0,
                        },
                    );
                    offSetCoordinatesForSelector.set(-3, 5, 0);
                    break;
                case MeshType.CurvedCube2:
                    geometry = new THREE.ExtrudeGeometry(
                        [
                            new THREE.Shape()
                                .moveTo(0, 0)
                                .lineTo(isForSelector ? 3 : 20, 0)
                                .lineTo(isForSelector ? 3 : 20, isForSelector ? 1.5 : 10)
                                .absarc(
                                    isForSelector ? 1.5 : 10,
                                    isForSelector ? 1.5 : 10,
                                    isForSelector ? 1.5 : 10,
                                    0,
                                    Math.PI,
                                    false,
                                )
                                .lineTo(0, 0),
                        ],
                        {
                            depth: isForSelector ? 3 : 20,
                            bevelEnabled: false,
                            bevelSegments: 0,
                            steps: 1,
                            bevelSize: 0,
                            bevelThickness: 0,
                        },
                    );
                    offSetCoordinatesForSelector.set(3, 4.5, 0);
                    break;
                case MeshType.HalfCylinder:
                    geometry = new THREE.ExtrudeGeometry(
                        [new THREE.Shape().absarc(0, 0, isForSelector ? 2 : 10, 0, Math.PI, false)],
                        {
                            depth: isForSelector ? 3 : 20,
                            bevelEnabled: false,
                            bevelSegments: 0,
                            steps: 1,
                            bevelSize: 0,
                            bevelThickness: 0,
                        },
                    );
                    offSetCoordinatesForSelector.set(8, 5, 0);
                    break;
                case MeshType.Prism:
                    geometry = new THREE.ExtrudeGeometry(
                        [
                            new THREE.Shape()
                                .moveTo(0, 0)
                                .lineTo(isForSelector ? 3 : 20, 0)
                                .lineTo(isForSelector ? 1.5 : 10, isForSelector ? 3 : 20)
                                .lineTo(0, 0),
                        ],
                        {
                            steps: 1,
                            depth: isForSelector ? 3 : 20, // Extrusion depth
                            bevelEnabled: false,
                        },
                    );
                    offSetCoordinatesForSelector.set(-8, 0, 0);
                    break;
                case MeshType.SquarePyramid:
                    geometry = new THREE.ConeGeometry(
                        isForSelector ? 2 : 14.14,
                        isForSelector ? 3 : 20,
                        4,
                        1,
                        false,
                        0.785,
                    );
                    offSetCoordinatesForSelector.set(-3, 0.5, 0);
                    break;
                case MeshType.Cone:
                    geometry = new THREE.ConeGeometry(isForSelector ? 2 : 10, isForSelector ? 3 : 20, 32, 1);
                    offSetCoordinatesForSelector.set(3, 0.5, 0);
                    break;
                case MeshType.Cylinder:
                    geometry = new THREE.CylinderGeometry(
                        isForSelector ? 2 : 10,
                        isForSelector ? 2 : 10,
                        isForSelector ? 3 : 20,
                        32,
                        1,
                    );
                    offSetCoordinatesForSelector.set(8, 0.5, 0);
                    break;
                case MeshType.HemiSphere:
                    geometry = new THREE.SphereGeometry(isForSelector ? 2 : 10, 30, 30);
                    const positionAttribute = geometry.attributes["position"] as THREE.BufferAttribute;
                    if (positionAttribute && positionAttribute.array) {
                        const verts = positionAttribute.array as Float32Array;
                        for (let i = 0; i < verts.length; i += 3) {
                            if (verts[i + 1] < 0) {
                                verts[i + 1] = 0;
                            }
                        }
                    }
                    offSetCoordinatesForSelector.set(-8, -5, 0);
                    break;
                case MeshType.RightTriangle:
                    geometry = new THREE.ExtrudeGeometry(
                        [
                            new THREE.Shape()
                                .moveTo(0, 0)
                                .lineTo(isForSelector ? 4 : 20, 0)
                                .lineTo(0, isForSelector ? 4 : 20),
                        ],
                        {
                            depth: isForSelector ? 4 : 20, // Extrusion depth for the 3D shape
                            bevelEnabled: false,
                            steps: 1,
                            bevelSize: 0,
                            bevelSegments: 0,
                            bevelThickness: 0,
                        },
                    );
                    offSetCoordinatesForSelector.set(-5, -7, -10);
                    break;
                case MeshType.ConcavePrism:
                    geometry = new THREE.ExtrudeGeometry(
                        [
                            new THREE.Shape()
                                .moveTo(0, 0)
                                .lineTo(isForSelector ? 3 : 20, 0)
                                .absarc(
                                    isForSelector ? 3 : 20,
                                    isForSelector ? 3 : 20,
                                    isForSelector ? 3 : 20,
                                    (3 * Math.PI) / 2,
                                    Math.PI,
                                    true,
                                )
                                .lineTo(0, 0),
                        ],
                        {
                            depth: isForSelector ? 3 : 20,
                            bevelEnabled: false,
                            bevelSegments: 0,
                            steps: 1,
                            bevelSize: 0,
                            bevelThickness: 0,
                        },
                    );
                    offSetCoordinatesForSelector.set(2, -5, 0);
                    break;
                default:
                    geometry = new THREE.BoxGeometry(0, 0, 0);
            }

            geometry.name = `${key}-Geometry`;
            geometry.center();

            if (isForSelector) {
                this.geometriesForSelector[type] = geometry;
                this.offsetCoordinatesForSelector[type] = offSetCoordinatesForSelector;
            } else {
                this.geometries[type] = geometry;
            }
        });
    }

    public addOutlineToMesh(mesh: THREE.Mesh, isForSelector: boolean): void {
        const outlineMaterial = new THREE.MeshBasicMaterial({
            color: ColorCodes.black as THREE.ColorRepresentation,
            side: THREE.DoubleSide,
        });

        if (isForSelector) {
            let outlineGeometry = this.outlineGeometriesForSelector[mesh.name as MeshType];
            if (!outlineGeometry) {
                outlineGeometry = new THREE.EdgesGeometry(
                    mesh.geometry,
                    AlteredShapeConfiguration.standardThreshHoldAngle,
                );
                this.outlineGeometriesForSelector[mesh.name as MeshType] = outlineGeometry;
            }
            const outline = new THREE.LineSegments(outlineGeometry, outlineMaterial);
            outline.name = AuxiliaryObjectType.Outline;
            mesh.add(outline);
        } else {
            let outlineGeometry = this.outlineGeometries[mesh.name as MeshType];
            if (!outlineGeometry) {
                outlineGeometry = new THREE.EdgesGeometry(
                    mesh.geometry,
                    AlteredShapeConfiguration.standardThreshHoldAngle,
                );
                this.outlineGeometries[mesh.name as MeshType] = outlineGeometry;
            }
            const outline = new THREE.LineSegments(outlineGeometry, outlineMaterial);
            outline.name = AuxiliaryObjectType.Outline;
            mesh.add(outline);
        }
    }

    public getGeometry(type: MeshType, isForSelector: boolean): THREE.BufferGeometry {
        return isForSelector ? this.geometriesForSelector[type] : this.geometries[type];
    }

    public getOutlineGeometry(type: MeshType, isForSelector: boolean): THREE.EdgesGeometry {
        return isForSelector ? this.outlineGeometriesForSelector[type] : this.outlineGeometries[type];
    }

    public getOffsetCoordinatesForSelector(type: MeshType) {
        return this.offsetCoordinatesForSelector[type];
    }
}
