import { Injectable } from "@angular/core";
import { ComponentInteractionSrevice } from "src/app/services/component-interaction/component-interaction.service";
import { setFaceTypeForShape } from "@utils/shape-facetype";
import { Face, FaceType } from "@utils/count-calculator/count-calculator.model";
import { Object3DUserData } from "@utils/shape";
import { Intersection, Object3D, Vector3 } from "three";
import * as THREE from "three";
import { CubeFace, FaceState } from "./face-identifier.constants";
import { NormalUtils } from "./face-identifier.utils";
import { AuxiliaryObjectType, MeshType } from "~/src/utils/shape-type";

@Injectable({
    providedIn: "root",
})
export class FaceIdentifierService {
    private readonly initialFaceState: FaceState = {
        frontFace: false,
        backFace: false,
        leftFace: false,
        rightFace: false,
        topFace: false,
        bottomFace: false,
        curveFace: false,
    };
    public intersect!: Intersection<Object3D<THREE.Event>>;
    public objectHit!: THREE.Mesh;
    public objectHitFaceNormal!: Vector3;
    private selectedShape: MeshType | null = null;
    public invalidJoin = false;
    public selectedShapeFaceTypeInvalid = false;
    public invalidPointOfGeneration = false;
    private meshRealFace: Face | null = null;

    private faceState: FaceState = { ...this.initialFaceState };

    public get state(): Readonly<FaceState> {
        return this.faceState;
    }

    constructor(private readonly compInteraction: ComponentInteractionSrevice) {
        this.initializeInteractionSubscription();
    }

    private initializeInteractionSubscription(): void {
        this.compInteraction.getSeletedShape().subscribe((selectedShape) => {
            this.selectedShape = selectedShape;
        });

        this.compInteraction.getInterceptInfo().subscribe((intersects) => {
            this.handleIntersection(intersects);
        });
    }

    private correctFloatingPointError(vector: THREE.Vector3, epsilon: number = 0.1): THREE.Vector3 {
        // Apply correction using a reusable helper for each component
        const correctValue = (value: number) => (Math.abs(value) < epsilon ? 0 : value);

        return vector.clone().set(correctValue(vector.x), correctValue(vector.y), correctValue(vector.z));
    }

    private handleIntersection(intersects: Intersection<Object3D<THREE.Event>>[]): void {
        this.intersect = intersects[0] || null;

        if (!this.intersect || !this.intersect.face) {
            this.invalidPointOfGeneration = true;
            return;
        }

        this.objectHit = this.intersect.object as THREE.Mesh;

        const faceNormal = this.intersect.face!.normal.clone();

        // Apply the current cube's rotation to the face normal
        faceNormal.applyQuaternion(this.objectHit.quaternion);

        const adjustedFaceNormal = this.correctFloatingPointError(faceNormal);

        this.objectHitFaceNormal = adjustedFaceNormal;

        if (this.objectHitFaceNormal) {
            const detectedFace = this.identifyFace(this.objectHitFaceNormal);
            this.updateFaceStates(detectedFace);
        }

        this.handlePostFaceDetection();
    }

    public identifyFace(normal: THREE.Vector3): CubeFace {
        const { x, y, z } = normal;
        const { isExactNormal, isZero } = NormalUtils;

        interface FaceCondition {
            condition: boolean;
            face: CubeFace;
        }

        const faceConditions: FaceCondition[] = [
            {
                condition: isExactNormal(x) && isZero(y) && isZero(z) && x > 0,
                face: CubeFace.Front,
            },
            {
                condition: isExactNormal(x) && isZero(y) && isZero(z) && x < 0,
                face: CubeFace.Back,
            },
            {
                condition: isZero(x) && isZero(y) && isExactNormal(z) && z > 0,
                face: CubeFace.Left,
            },
            {
                condition: isZero(x) && isZero(y) && isExactNormal(z) && z < 0,
                face: CubeFace.Right,
            },
            {
                condition: isZero(x) && isExactNormal(y) && isZero(z) && y > 0,
                face: CubeFace.Top,
            },
            {
                condition: isZero(x) && isExactNormal(y) && isZero(z) && y < 0,
                face: CubeFace.Bottom,
            },
        ];

        const matchedFace = faceConditions.find(({ condition }) => condition);
        return matchedFace?.face ?? CubeFace.Curved;
    }

    private updateFaceStates(face: CubeFace): void {
        this.resetFaceStates();

        const faceStateMap: Record<CubeFace, keyof FaceState> = {
            [CubeFace.Front]: "frontFace",
            [CubeFace.Back]: "backFace",
            [CubeFace.Left]: "leftFace",
            [CubeFace.Right]: "rightFace",
            [CubeFace.Top]: "topFace",
            [CubeFace.Bottom]: "bottomFace",
            [CubeFace.Curved]: "curveFace",
        };

        const stateKey = faceStateMap[face];
        if (stateKey) {
            this.faceState[stateKey] = true;
        }
    }

    private resetFaceStates(): void {
        this.faceState = { ...this.initialFaceState };
    }

    private handlePostFaceDetection(): void {
        this.checkForInvalidJoin();
        this.checkForInvalidPointOfGeneration();
    }

    private checkForInvalidJoin(): void {
        this.invalidJoin = false;

        if (this.faceState.frontFace) {
            this.meshRealFace = this.getOrientationFace(0);
            this.handleInvalidJoinCheck(this.meshRealFace, Face.Back);
        } else if (this.faceState.rightFace) {
            this.meshRealFace = this.getOrientationFace(1);
            this.handleInvalidJoinCheck(this.meshRealFace, Face.Left);
        } else if (this.faceState.backFace) {
            this.meshRealFace = this.getOrientationFace(2);
            this.handleInvalidJoinCheck(this.meshRealFace, Face.Front);
        } else if (this.faceState.leftFace) {
            this.meshRealFace = this.getOrientationFace(3);
            this.handleInvalidJoinCheck(this.meshRealFace, Face.Right);
        } else if (this.faceState.topFace) {
            this.meshRealFace = this.getOrientationFace(4);
            this.handleInvalidJoinCheck(this.meshRealFace, Face.Bottom);
        } else if (this.faceState.bottomFace) {
            this.meshRealFace = this.getOrientationFace(5);
            this.handleInvalidJoinCheck(this.meshRealFace, Face.Top);
        } else if (this.faceState.curveFace) {
            this.invalidJoin = true;
        }
    }

    private getOrientationFace(index: number): Face | null {
        return this.intersect?.object?.userData?.[Object3DUserData.orientationData]?.[index] ?? null;
    }

    public handleInvalidJoinCheck(meshRealFace: Face | null, oppositeFace: Face): void {
        if (!meshRealFace) return;
        const faceType = this.intersect?.object?.userData?.[Object3DUserData.faceType]?.[meshRealFace];
        const selectedShapeFaceType = setFaceTypeForShape(this.selectedShape!);

        this.invalidJoin = this.checkInvalidForConnectionPoint(meshRealFace);

        if (faceType === FaceType.Curved) {
            this.invalidJoin = true;
        }
    }

    private checkForInvalidPointOfGeneration(): void {
        this.invalidPointOfGeneration = this.intersect.object.name === AuxiliaryObjectType.Outline;
    }

    private checkInvalidForConnectionPoint(meshRealFace: Face): boolean {
        const objectName = this.objectHit.name as MeshType;
        const restrictedShapes = [
            MeshType.RightTriangle,
            MeshType.ConcavePrism,
            MeshType.HalfCylinder,
            MeshType.CurvedCube,
        ];

        const isObjectRestrictedShape = restrictedShapes.includes(objectName);
        const isSelectedRestrictedShape = restrictedShapes.includes(this.selectedShape!);
        const isLeftOrRightFace = meshRealFace === Face.Left || meshRealFace === Face.Right;

        // Check if selected shape is restricted and we're dealing with left or right face
        if (isSelectedRestrictedShape && isLeftOrRightFace) {
            if (this.state.leftFace || this.state.rightFace) {
                return objectName !== this.selectedShape && isObjectRestrictedShape;
            }
            return objectName !== this.selectedShape && isObjectRestrictedShape;
        }

        // If shape isn't restricted, return false for left or right face
        if (!isSelectedRestrictedShape && isLeftOrRightFace && isObjectRestrictedShape) {
            return true;
        }

        // If not dealing with left or right face, return false
        if (!isLeftOrRightFace && (this.state.leftFace || this.state.rightFace)) {
            return false;
        }

        return false;
    }
}
