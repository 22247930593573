import { Injectable } from "@angular/core";
import { CanvasConfig } from "@utils/canvas-configuration";
import { Object3DUserData, initialOrientation } from "@utils/shape";
import { Vector2, Mesh } from "three";
import { AlteredShapeConfiguration } from "../../../../../utils/shape";
import * as THREE from "three";
import { FaceIdentifierService } from "../face-identifier/face-identifier.service";
import { FaceType } from "../../../../../utils/count-calculator/count-calculator.model";
import { AuxiliaryObjectType, MeshType } from "~/src/utils/shape-type";

@Injectable({
    providedIn: "root",
})
export class HelperService {
    public cellReferenceForShapes!: { row: number; column: number; cell: number };
    public isShapeConnectedToCurvedFace: any;
    public foundShapeOnBottom: THREE.Mesh[] = [];

    constructor() {}

    /*
    This method is used to get the location y for all the shapes with respect to the ground.
    */
    public getGroundLocationY(initialLocationY: number): number {
        // Here we make the shape sit on the grid avoiding them to go inside.
        if (Math.round(initialLocationY) <= CanvasConfig.planePostionIn_Y_Coordinate) {
            return (
                CanvasConfig.planePostionIn_Y_Coordinate +
                AlteredShapeConfiguration.standardHeigthOfShape / AlteredShapeConfiguration.divisorAdjustYPosition
            );
        } else {
            return initialLocationY;
        }
    }

    /*
    This method will return the nearest coordinates of the grid on the plane
    helps to generate or drop the shapes on the nearest grid.
    */
    public getNearestGridCords(twoDCords: THREE.Vector2): THREE.Vector2 {
        /*
        Vector2 and THREE.Vector2 has only two coordinates 'x' and 'y', but in our case we are
        considering 'y' as 'z' since y is alredy been defined, 'x' and 'z' are calculated and altered for any shape
        to place it on the grid.
        */
        // Calculate the nearest grid intersection in X and Z directions.
        const cellSize = CanvasConfig.cellSize;

        twoDCords.x = this.roundAwayFromZero(twoDCords.x);
        twoDCords.y = this.roundAwayFromZero(twoDCords.y);

        const nearestGridX = Math.round(twoDCords.x / cellSize) * cellSize;
        const nearestGridZ = Math.round(twoDCords.y / cellSize) * cellSize;

        return new Vector2(nearestGridX, nearestGridZ);
    }

    // This will calculate the row and column of the grid if passed the position of the shape.
    public calculateRowAndColumn(shape: THREE.Mesh): {
        row: number;
        column: number;
        cell: number;
    } {
        const cellSize = CanvasConfig.cellSize;

        const boundingBox = new THREE.Box3();
        shape.geometry.computeBoundingBox();
        boundingBox.setFromObject(shape);
        const boundingBoxCenter = boundingBox.getCenter(new THREE.Vector3());

        const row = boundingBoxCenter.z / cellSize;
        const column = boundingBoxCenter.x / cellSize;
        const cell = Math.round(Math.abs((boundingBoxCenter.y - CanvasConfig.planePositionOffset) / cellSize));
        return { row, column, cell };
    }

    public isShapeOnAir(shape: THREE.Mesh, objects: THREE.Object3D[]): boolean {
        if (!shape.userData[Object3DUserData.originalColor]) {
            shape.userData[Object3DUserData.originalColor] = (shape.material as THREE.MeshBasicMaterial).color.clone();
        }
        const { row, column, cell } = this.calculateRowAndColumn(shape);
        this.cellReferenceForShapes = { row, column, cell };
        const sideInfo = this.sideShapes(objects as THREE.Mesh[]);
        const { isShapeOnTop, isShapeOnBottom, isShapeOnRight, isShapeOnLeft, isShapeOnFront, isShapeOnBack } =
            sideInfo;

        const occupiedSidesCount = [
            isShapeOnTop,
            isShapeOnBottom,
            isShapeOnRight,
            isShapeOnLeft,
            isShapeOnFront,
            isShapeOnBack,
        ].reduce((count, side) => count + (side ? 1 : 0), 0);

        const isShapeNotAttached = !occupiedSidesCount;
        const isShapeNotOnPlane = this.isShapeNotOnPlane(shape);
        const faceType = shape.userData[Object3DUserData.faceType];
        if (typeof faceType === "object") {
            for (const value of Object.values(faceType)) {
                if (value === FaceType.Curved && isShapeNotOnPlane) {
                    const isNeighborValid = this.validateCurvedShapeAttachment(
                        objects as THREE.Mesh[],
                        shape,
                        sideInfo,
                    );
                    if (shape.name === MeshType.Cone || shape.name === MeshType.SquarePyramid) {
                        if (
                            isShapeOnBottom &&
                            shape.userData[Object3DUserData.orientationData][5] === initialOrientation[5] &&
                            occupiedSidesCount === 1
                        ) {
                            return false;
                        }

                        if (
                            (isShapeOnLeft &&
                                shape.userData[Object3DUserData.orientationData][1] === initialOrientation[5]) ||
                            (isShapeOnRight &&
                                shape.userData[Object3DUserData.orientationData][3] === initialOrientation[5]) ||
                            (isShapeOnFront &&
                                shape.userData[Object3DUserData.orientationData][0] === initialOrientation[5]) ||
                            (isShapeOnBack &&
                                shape.userData[Object3DUserData.orientationData][2] === initialOrientation[5]) ||
                            (this.validateCurvedShapeAttachment(objects as THREE.Mesh[], shape, sideInfo) &&
                                occupiedSidesCount !== 1 &&
                                shape.userData[Object3DUserData.orientationData][1] !== initialOrientation[5] &&
                                shape.userData[Object3DUserData.orientationData][3] !== initialOrientation[5] &&
                                shape.userData[Object3DUserData.orientationData][0] !== initialOrientation[5] &&
                                shape.userData[Object3DUserData.orientationData][2] !== initialOrientation[5])
                        ) {
                            return false;
                        } else {
                            return true;
                        }
                    }

                    if (shape.name === MeshType.Cylinder) {
                        if (
                            ((isShapeOnRight || isShapeOnLeft) &&
                                (shape.userData[Object3DUserData.orientationData][1] === initialOrientation[5] ||
                                    shape.userData[Object3DUserData.orientationData][3] === initialOrientation[5])) ||
                            ((isShapeOnFront || isShapeOnBack) &&
                                (shape.userData[Object3DUserData.orientationData][0] === initialOrientation[5] ||
                                    shape.userData[Object3DUserData.orientationData][2] === initialOrientation[5])) ||
                            shape.userData[Object3DUserData.orientationData][4] === initialOrientation[5] ||
                            shape.userData[Object3DUserData.orientationData][5] === initialOrientation[5] ||
                            (this.validateCurvedShapeAttachment(objects as THREE.Mesh[], shape, sideInfo) &&
                                occupiedSidesCount !== 1 &&
                                shape.userData[Object3DUserData.orientationData][1] !== initialOrientation[5] &&
                                shape.userData[Object3DUserData.orientationData][3] !== initialOrientation[5] &&
                                shape.userData[Object3DUserData.orientationData][0] !== initialOrientation[5] &&
                                shape.userData[Object3DUserData.orientationData][2] !== initialOrientation[5])
                        ) {
                            return false;
                        } else {
                            return true;
                        }
                    }

                    if (shape.name === MeshType.CurvedCube2) {
                        if (
                            (isShapeOnBottom &&
                                shape.userData[Object3DUserData.orientationData][4] === initialOrientation[5]) ||
                            (isShapeOnTop &&
                                shape.userData[Object3DUserData.orientationData][5] === initialOrientation[5])
                        ) {
                            if (isNeighborValid && occupiedSidesCount !== 1) {
                                return false;
                            }
                            return true;
                        }
                    }

                    if (shape.name === MeshType.CurvedCube) {
                        if (
                            (isShapeOnBottom &&
                                shape.userData[Object3DUserData.orientationData][4] === initialOrientation[5]) ||
                            (isShapeOnTop &&
                                shape.userData[Object3DUserData.orientationData][5] === initialOrientation[5]) ||
                            (shape.userData[Object3DUserData.orientationData][0] === initialOrientation[5] &&
                                shape.userData[Object3DUserData.orientationData][4] === initialOrientation[2]) ||
                            (shape.userData[Object3DUserData.orientationData][1] === initialOrientation[5] &&
                                shape.userData[Object3DUserData.orientationData][4] === initialOrientation[2]) ||
                            (shape.userData[Object3DUserData.orientationData][3] === initialOrientation[5] &&
                                shape.userData[Object3DUserData.orientationData][4] === initialOrientation[2]) ||
                            (shape.userData[Object3DUserData.orientationData][2] === initialOrientation[5] &&
                                shape.userData[Object3DUserData.orientationData][4] === initialOrientation[2])
                        ) {
                            if (isNeighborValid && occupiedSidesCount !== 1) {
                                return false;
                            }
                            return true;
                        }
                    }

                    if (shape.name === MeshType.Prism) {
                        if (
                            shape.userData[Object3DUserData.orientationData][4] === initialOrientation[5] ||
                            (isShapeOnTop &&
                                shape.userData[Object3DUserData.orientationData][5] === initialOrientation[5]) ||
                            (shape.userData[Object3DUserData.orientationData][0] === initialOrientation[5] &&
                                shape.userData[Object3DUserData.orientationData][5] === initialOrientation[2]) ||
                            (shape.userData[Object3DUserData.orientationData][0] === initialOrientation[5] &&
                                shape.userData[Object3DUserData.orientationData][4] === initialOrientation[2]) ||
                            (shape.userData[Object3DUserData.orientationData][1] === initialOrientation[5] &&
                                shape.userData[Object3DUserData.orientationData][4] === initialOrientation[2]) ||
                            (shape.userData[Object3DUserData.orientationData][1] === initialOrientation[5] &&
                                shape.userData[Object3DUserData.orientationData][5] === initialOrientation[2]) ||
                            (shape.userData[Object3DUserData.orientationData][2] === initialOrientation[5] &&
                                shape.userData[Object3DUserData.orientationData][4] === initialOrientation[2]) ||
                            (shape.userData[Object3DUserData.orientationData][3] === initialOrientation[5] &&
                                shape.userData[Object3DUserData.orientationData][4] === initialOrientation[2]) ||
                            (shape.userData[Object3DUserData.orientationData][3] === initialOrientation[5] &&
                                shape.userData[Object3DUserData.orientationData][5] === initialOrientation[2]) ||
                            (shape.userData[Object3DUserData.orientationData][2] === initialOrientation[5] &&
                                shape.userData[Object3DUserData.orientationData][5] === initialOrientation[2])
                        ) {
                            if (isNeighborValid && occupiedSidesCount !== 1) {
                                return false;
                            }
                            return true;
                        }
                    }

                    if (shape.name === MeshType.HemiSphere || shape.name === MeshType.HalfCylinder) {
                        this.cellReferenceForShapes = shape.userData[Object3DUserData.cellReference];

                        const row = this.cellReferenceForShapes.row;
                        const column = this.cellReferenceForShapes.column;
                        const cell = this.cellReferenceForShapes.cell;

                        // Step 1: Preprocess objects into a Set for fast lookup
                        const positionSet = new Set(
                            objects.map((shape) => {
                                const { row, column, cell } = shape.userData[Object3DUserData.cellReference];
                                return `${row},${column},${cell}`;
                            }),
                        );

                        // Step 2: Check neighboring positions
                        const isShapeOnBottom = positionSet.has(`${row},${column},${cell - 1}`);
                        const isShapeOnTop = positionSet.has(`${row},${column},${cell + 1}`);
                        const isShapeOnFront = positionSet.has(`${row},${column - 1},${cell}`);
                        const isShapeOnBack = positionSet.has(`${row},${column + 1},${cell}`);
                        const isShapeOnRight = positionSet.has(`${row - 1},${column},${cell}`);
                        const isShapeOnLeft = positionSet.has(`${row + 1},${column},${cell}`);

                        const occupiedSidesCount = [
                            isShapeOnTop,
                            isShapeOnBottom,
                            isShapeOnRight,
                            isShapeOnLeft,
                            isShapeOnFront,
                            isShapeOnBack,
                        ].reduce((count, side) => count + (side ? 1 : 0), 0);

                        if (
                            isShapeOnBottom &&
                            shape.userData[Object3DUserData.orientationData][5] === initialOrientation[5] &&
                            occupiedSidesCount === 1
                        ) {
                            return false;
                        }

                        if (
                            (isShapeOnRight &&
                                shape.userData[Object3DUserData.orientationData][1] === initialOrientation[5]) ||
                            (isShapeOnLeft &&
                                shape.userData[Object3DUserData.orientationData][3] === initialOrientation[5]) ||
                            (isShapeOnBack &&
                                shape.userData[Object3DUserData.orientationData][0] === initialOrientation[5]) ||
                            (isShapeOnFront &&
                                shape.userData[Object3DUserData.orientationData][2] === initialOrientation[5]) ||
                            (this.validateCurvedShapeAttachment(objects as any, shape, sideInfo) &&
                                occupiedSidesCount !== 1 &&
                                shape.userData[Object3DUserData.orientationData][1] !== initialOrientation[5] &&
                                shape.userData[Object3DUserData.orientationData][3] !== initialOrientation[5] &&
                                shape.userData[Object3DUserData.orientationData][0] !== initialOrientation[5] &&
                                shape.userData[Object3DUserData.orientationData][2] !== initialOrientation[5])
                        ) {
                            return false;
                        } else {
                            return true;
                        }
                    }

                    if (shape.name === MeshType.RightTriangle || shape.name === MeshType.ConcavePrism) {
                        if (
                            (isShapeOnBottom &&
                                shape.userData[Object3DUserData.orientationData][4] === initialOrientation[5]) ||
                            (isShapeOnTop &&
                                shape.userData[Object3DUserData.orientationData][5] === initialOrientation[5]) ||
                            (shape.userData[Object3DUserData.orientationData][3] === initialOrientation[5] &&
                                shape.userData[Object3DUserData.orientationData][2] === initialOrientation[2]) ||
                            (shape.userData[Object3DUserData.orientationData][1] === initialOrientation[5] &&
                                shape.userData[Object3DUserData.orientationData][5] === initialOrientation[2]) ||
                            (shape.userData[Object3DUserData.orientationData][1] === initialOrientation[5] &&
                                shape.userData[Object3DUserData.orientationData][2] === initialOrientation[2]) ||
                            (shape.userData[Object3DUserData.orientationData][2] === initialOrientation[5] &&
                                shape.userData[Object3DUserData.orientationData][4] === initialOrientation[2]) ||
                            (shape.userData[Object3DUserData.orientationData][0] === initialOrientation[5] &&
                                shape.userData[Object3DUserData.orientationData][3] === initialOrientation[2]) ||
                            (shape.userData[Object3DUserData.orientationData][0] === initialOrientation[5] &&
                                shape.userData[Object3DUserData.orientationData][4] === initialOrientation[2]) ||
                            (shape.userData[Object3DUserData.orientationData][0] === initialOrientation[5] &&
                                shape.userData[Object3DUserData.orientationData][1] === initialOrientation[2])
                        ) {
                            if (isNeighborValid && occupiedSidesCount !== 1) {
                                return false;
                            }
                            return true;
                        }
                    }
                }
            }
        }

        if (isShapeNotAttached && isShapeNotOnPlane) {
            return true;
        } else {
            const changedColor = shape.userData[Object3DUserData.colour];
            if (changedColor) {
                (shape.material as THREE.MeshBasicMaterial).color.copy(changedColor);
                (shape.material as THREE.MeshBasicMaterial).transparent = false;
                (shape.material as THREE.MeshBasicMaterial).opacity = 1;
            }
        }

        const changedColor = shape.userData[Object3DUserData.colour];
        if (changedColor) {
            (shape.material as THREE.MeshBasicMaterial).color.copy(new THREE.Color(changedColor));
            (shape.material as THREE.MeshBasicMaterial).transparent = false;
            (shape.material as THREE.MeshBasicMaterial).opacity = 1;
        }

        const visitedShapes = new Set<THREE.Object3D>();
        const isConnectedToPlane = (currentShape: THREE.Mesh): boolean => {
            const cellReference = currentShape.userData[Object3DUserData.cellReference].cell;
            if (!cellReference) {
                return true;
            }
            visitedShapes.add(currentShape);

            const { row, column, cell } = this.calculateRowAndColumn(currentShape);
            const adjacent = [
                { row, column, cell: cell + 1 },
                { row, column, cell: cell - 1 },
                { row: row + 1, column, cell },
                { row: row - 1, column, cell },
                { row, column: column + 1, cell },
                { row, column: column - 1, cell },
            ];

            const foundMatch = objects.some((otherShape) => {
                if (visitedShapes.has(otherShape)) {
                    return false; // Skip if the shape is already visited
                }

                const otherCellReference = otherShape.userData[Object3DUserData.cellReference];

                return adjacent.some(({ row, column, cell }) => {
                    return (
                        otherCellReference.row === row &&
                        otherCellReference.column === column &&
                        otherCellReference.cell === cell &&
                        isConnectedToPlane(otherShape as THREE.Mesh)
                    );
                });
            });

            if (foundMatch) {
                return true; // If a match is found, return true
            }

            return false; // If no match is found, return false
        };
        if (!isConnectedToPlane(shape)) {
            return true;
        }

        return false;
    }

    public get isShapeNotOnPlane(): (shape: Mesh) => boolean {
        return (shape: Mesh): boolean => {
            if (shape.userData && shape.userData[Object3DUserData.cellReference]) {
                const cell = shape.userData[Object3DUserData.cellReference].cell;
                return cell !== 0;
            }
            return true;
        };
    }

    private roundAwayFromZero(value: number): number {
        return value > 0 ? Math.ceil(value) : Math.floor(value);
    }

    public validateCurvedShapeAttachment(
        objects: THREE.Mesh[],
        shape: THREE.Mesh,
        sideInfo: {
            isShapeOnTop: boolean;
            isShapeOnBottom: boolean;
            isShapeOnRight: boolean;
            isShapeOnLeft: boolean;
            isShapeOnFront: boolean;
            isShapeOnBack: boolean;
        },
    ): boolean {
        this.cellReferenceForShapes = shape.userData[Object3DUserData.cellReference];
        this.isShapeConnectedToCurvedFace =
            shape.userData[Object3DUserData.orientationData][4] === initialOrientation[5] ||
            shape.userData[Object3DUserData.orientationData][5] === initialOrientation[5];

        const { isShapeOnTop, isShapeOnBottom, isShapeOnRight, isShapeOnLeft, isShapeOnFront, isShapeOnBack } =
            sideInfo;

        const occupiedSidesCount = [
            isShapeOnTop,
            isShapeOnBottom,
            isShapeOnRight,
            isShapeOnLeft,
            isShapeOnFront,
            isShapeOnBack,
        ].reduce((count, side) => count + (side ? 1 : 0), 0);

        // Condition 1: If the shape is connected to a curved face and is on the bottom, it's invalid
        if (this.isShapeConnectedToCurvedFace && isShapeOnBottom && occupiedSidesCount === 1) {
            return false; // Invalid shape attached to curved face on the bottom
        }
        // Condition 2: If the shape is not on the bottom, check its neighbors for connection to ground
        if (!isShapeOnBottom || occupiedSidesCount > 1) {
            const isValid = this.checkNeighborsConnectedToGround(objects, shape);
            if (isValid) {
                return true; // Valid if any neighbor is connected to the ground
            } else {
                return false; // Invalid if no neighbors are connected to the ground
            }
        }
        // Default return for invalid shape if no other conditions apply
        return true;
    }

    // Helper to check if any neighboring shape is connected to the ground
    private checkNeighborsConnectedToGround(objects: THREE.Mesh[], shape: THREE.Mesh): boolean {
        const visited = new Set<THREE.Mesh>();
        return this.dfsCheckGround(objects, shape, visited);
    }

    // DFS to check if any neighboring shape or connected chain is connected to the ground
    private dfsCheckGround(objects: THREE.Mesh[], shape: THREE.Mesh, visited: Set<THREE.Mesh>): boolean {
        const bottomShape = objects.find((otherShape) => {
            const cellRef = otherShape.userData[Object3DUserData.cellReference];
            return (
                cellRef.row === this.cellReferenceForShapes.row &&
                cellRef.column === this.cellReferenceForShapes.column &&
                cellRef.cell === this.cellReferenceForShapes.cell - 1
            );
        });

        if (bottomShape && !this.foundShapeOnBottom.find((shape) => shape.uuid === bottomShape.uuid)) {
            this.foundShapeOnBottom.push(bottomShape);
        }
        if (this.foundShapeOnBottom?.length) {
            // If the shape has already been visited, stop
            if (visited.has(shape)) return false;
            visited.add(shape);

            // If the shape is connected to the ground, return true
            if (
                this.isConnectedToGround(shape) &&
                shape.userData[Object3DUserData.cellReference].cell === this.cellReferenceForShapes.cell - 1 &&
                this.isShapeConnectedToCurvedFace &&
                this?.foundShapeOnBottom[0]?.uuid === shape.uuid
            ) {
                return false;
            } else if (this.isConnectedToGround(shape) && this.foundShapeOnBottom[0]?.uuid !== shape.uuid) {
                return true;
            }
            // Get the neighboring shapes (top, left, right, front, back)
            const neighbors = this.getSideNeighbors(objects, shape);
            // Recursively check each neighbor
            if (neighbors.some((neighbor) => this.dfsCheckGround(objects, neighbor, visited))) {
                return true; // Found a neighbor connected to the ground
            }

            return false; // No neighbor connected to the ground
        }
        return true;
    }

    // Helper to get neighboring shapes (top, left, right, front, back)
    private getSideNeighbors(objects: THREE.Mesh[], shape: THREE.Mesh): THREE.Mesh[] {
        const { row, column, cell } = shape.userData[Object3DUserData.cellReference];
        let neighbors: THREE.Mesh[] = [];

        const positions = new Set(
            objects.map(({ userData }) => {
                const { row, column, cell } = userData[Object3DUserData.cellReference];
                return `${row},${column},${cell}`;
            }),
        );

        neighbors = objects.filter(({ userData }) => {
            const { row: otherRow, column: otherColumn, cell: otherCell } = userData[Object3DUserData.cellReference];
            return (
                positions.has(`${otherRow - 1},${otherColumn},${otherCell}`) || // Top
                positions.has(`${otherRow + 1},${otherColumn},${otherCell}`) || // Bottom
                positions.has(`${otherRow},${otherColumn - 1},${otherCell}`) || // Left
                positions.has(`${otherRow},${otherColumn + 1},${otherCell}`) || // Right
                positions.has(`${otherRow},${otherColumn},${otherCell - 1}`) || // Front
                positions.has(`${otherRow},${otherColumn},${otherCell + 1}`) // Back
            );
        });

        return neighbors;
    }

    private isConnectedToGround(shape: THREE.Mesh): boolean {
        const { row, column, cell } = shape.userData[Object3DUserData.cellReference];
        return cell === 0;
    }

    public sideShapes(objects: THREE.Mesh[]): {
        isShapeOnTop: boolean;
        isShapeOnBottom: boolean;
        isShapeOnRight: boolean;
        isShapeOnLeft: boolean;
        isShapeOnFront: boolean;
        isShapeOnBack: boolean;
    } {
        const row = this.cellReferenceForShapes.row;
        const column = this.cellReferenceForShapes.column;
        const cell = this.cellReferenceForShapes.cell;

        let isShapeOnTop = false;
        let isShapeOnBottom = false;
        let isShapeOnRight = false;
        let isShapeOnLeft = false;
        let isShapeOnFront = false;
        let isShapeOnBack = false;

        const foundShapeOnBottom: THREE.Mesh[] = [];

        for (const otherShape of objects) {
            const otherCellReference = otherShape.userData[Object3DUserData.cellReference];
            const otherRow = otherCellReference.row;
            const otherColumn = otherCellReference.column;
            const otherCell = otherCellReference.cell;

            if (otherRow === row && otherColumn === column && otherCell === cell + 1) {
                isShapeOnTop = true;
            } else if (otherRow === row && otherColumn === column && otherCell === cell - 1) {
                isShapeOnBottom = true;
                foundShapeOnBottom.push(otherShape);
            } else if (otherRow === row + 1 && otherColumn === column && otherCell === cell) {
                isShapeOnRight = true;
            } else if (otherRow === row - 1 && otherColumn === column && otherCell === cell) {
                isShapeOnLeft = true;
            } else if (otherRow === row && otherColumn === column + 1 && otherCell === cell) {
                isShapeOnFront = true;
            } else if (otherRow === row && otherColumn === column - 1 && otherCell === cell) {
                isShapeOnBack = true;
            }
        }

        return {
            isShapeOnTop,
            isShapeOnBottom,
            isShapeOnRight,
            isShapeOnLeft,
            isShapeOnFront,
            isShapeOnBack,
        };
    }

    public resetShapeProperties(shape: THREE.Object3D): void {
        const changedColor = new THREE.Color(shape.userData[Object3DUserData.colour]);
        if (changedColor) {
            ((shape as THREE.Mesh).material as THREE.MeshBasicMaterial).color.copy(changedColor);
            ((shape as THREE.Mesh).material as THREE.MeshBasicMaterial).transparent = false;
            ((shape as THREE.Mesh).material as THREE.MeshBasicMaterial).opacity = 1;
        }
    }
}
