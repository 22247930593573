import { Injectable } from "@angular/core";
import * as THREE from "three";
import { SceneManagerService } from "../scene-manager/scene-manager.service";
import { Action, ObjectState, UndoRedoAction } from "@models/undoRedo.model";
import { Object3DUserData } from "~/src/utils/shape";
@Injectable({
    providedIn: "root",
})
export class UndoRedoService {
    private readonly maxUndoAction = 5;
    private undoStack: UndoRedoAction[] = [];
    private redoStack: UndoRedoAction[] = [];
    constructor(private sceneManagerService: SceneManagerService) {}
    public addToUndo(action: UndoRedoAction): void {
        if (action.actionType === Action.edit && !this.sceneManagerService.hasShapes()) {
            return;
        }
        this.undoStack.push(action);
        if (this.undoStack.length > this.maxUndoAction) {
            this.undoStack.shift();
        }
        this.redoStack = [];
    }

    public performUndo(): void {
        this.processAction(this.undoStack, this.redoStack, true);
    }

    public performRedo(): void {
        this.processAction(this.redoStack, this.undoStack, false);
    }

    private processAction(stackFrom: UndoRedoAction[], stackTo: UndoRedoAction[], isUndo: boolean): void {
        const lastAction = stackFrom[stackFrom.length - 1];
        if (!lastAction) return;

        switch (lastAction.actionType) {
            case Action.add:
                if (lastAction.object instanceof THREE.Object3D) {
                    if (isUndo) {
                        this.sceneManagerService.removeMeshesFromScene([lastAction.object] as THREE.Mesh[]);
                    } else {
                        this.sceneManagerService.addObjectsToScene([lastAction.object]);
                    }
                }
                break;

            case Action.delete:
                if (isUndo) {
                    this.sceneManagerService.addObjectsToScene(lastAction.meshes as THREE.Object3D[]);
                } else {
                    this.sceneManagerService.removeMeshesFromScene(lastAction.meshes as THREE.Mesh[]);
                }
                break;

            case Action.edit:
                const newState = this.handleEditAction(lastAction, isUndo);
                stackTo.push(newState);
                stackFrom.pop();
                return;
        }

        stackTo.push(lastAction);
        stackFrom.pop();
    }

    private handleEditAction(action: UndoRedoAction, isUndo: boolean): UndoRedoAction {
        return {
            actionType: Action.edit,
            objects: action.objects?.map((objState) => {
                const obj = objState.object as THREE.Mesh;
                const currentState: Partial<ObjectState> = {};

                //  Store & Restore Position (Fix for Hemisphere & Half-Cylinder)
                if (objState.previousPosition) {
                    currentState.previousPosition = obj.position.clone(); // Save current position before undo
                    obj.position.copy(objState.previousPosition as THREE.Vector3); // Restore original position
                }
                // If the object was flipped, restore its previous orientation and rotation
                if (objState.previousOrientation) {
                    currentState.previousOrientation = [...obj.userData[Object3DUserData.orientationData]];
                    currentState.previousRotation = obj.rotation.clone();
                    obj.userData[Object3DUserData.orientationData] = [...objState.previousOrientation];

                    if (objState.previousRotation) {
                        obj.rotation.copy(objState.previousRotation as THREE.Euler);
                    }
                }

                // If the object's color was changed, restore its previous color
                if (objState.previousColor && obj instanceof THREE.Mesh) {
                    currentState.previousColor = obj.userData[Object3DUserData.colour];
                    obj.userData[Object3DUserData.colour] = objState.previousColor;
                    (obj.material as THREE.MeshStandardMaterial).color.set(
                        objState.previousColor as THREE.ColorRepresentation,
                    );
                }

                return { object: obj, ...currentState };
            }),
        };
    }
}
