import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { CanvasConfig, ZoomConfig } from "@utils/canvas-configuration";
import { PlaneCustomProperties } from "@utils/shape";
import { ComponentInteractionSrevice } from "../services/component-interaction/component-interaction.service";
import { ColorCodes } from "@utils/color-constants";
import { InteractionService } from "../shared/helpers/interaction.service";
import { SetShapeUserDataService } from "../shared/helpers/set-shape-userdata.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
    StructureBaseModel,
    StructureInstance,
    StructureResponseMessage,
    StructureStatus,
} from "@models/structure.model";
import { MatDialog } from "@angular/material/dialog";
import { GenericDialogComponent } from "../shared/generic-dialog/generic-dialog.component";
import { DialogInvokingComponents } from "@models/generic-dialog.model";
import { DialogService } from "../services/dialog/dialog.service";
import { StructureService } from "../services/structure/structure.service";
import { filter, distinctUntilChanged, merge, scan, Subscription } from "rxjs";
import { Location } from "@angular/common";
import { GenerateNewShapes } from "../shared/generate-new-shapes";
import { CountCalculatorService } from "../services/count-calculator.service";
import { CountOutputData } from "../../../../utils/count-calculator/count-calculator.model";
import { HelperService } from "../shared/helpers/helper.service";
import { environment } from "~/src/environments/environment";
import { S3UploadService } from "../services/s3-upoad/s3-upload.service";
import { ActionHelperService } from "../action-helper.service";
import { SceneManagerService } from "../services/scene-manager/scene-manager.service";
import { RouteTrackingService } from "../services/route-tracking/route-tracking.service";
import { ShapeSelectorComponent } from "./shape-selector/shape-selector.component";
import { FilenameService } from "../services/filename/filename.service";
import { ShapeResourceManagerService } from "../shared/helpers/shape-resource-manager.service";
import { UndoRedoService } from "../services/undoRedo/undo-redo.service";
import { Action } from "@models/undoRedo.model";
import { AuxiliaryObjectType } from "~/src/utils/shape-type";
import { SnackBarConfig } from "../constants/snackbar.constants";

@Component({
    selector: "new-canvas",
    templateUrl: "./canvascomponent.component.html",
    styleUrls: ["./canvascomponent.component.scss"],
})
export class CanvasComponent implements OnInit, AfterViewInit, OnDestroy {
    // ViewChild and Input declarations
    @ViewChild("main_canvas", { static: true }) canvasRef!: ElementRef<HTMLCanvasElement>;
    @ViewChild("shapeSelector") shapeSelectorRef!: ShapeSelectorComponent;
    @Input() isViewMode = false;
    @Input() isGlb = false;

    private scene!: THREE.Scene;
    private camera!: THREE.PerspectiveCamera;
    private renderer: THREE.WebGLRenderer | null = null;
    private orbitControl!: OrbitControls;
    private plane!: THREE.Mesh;
    private gridHelper!: THREE.GridHelper;
    private objects: THREE.Object3D[] = [];

    public isEditMode: boolean = false;
    public isEditted: boolean = false;
    public zoomConfig = ZoomConfig;
    public pushCapturedImage: File[] = [];
    public isDisabledCaptureBtn: boolean = false;
    public isDisabledCapture360Btn: boolean = false;
    public autoCapturedImageUrl!: string;
    public structureData!: StructureInstance;
    public structureStatus = StructureStatus;
    public isLoading: boolean = false;

    private outputPattern!: CountOutputData[];
    private structureId: number | null = null;
    private capturedImageLength = 0;

    // Subscriptions
    private subscriptions: Subscription[] = [];

    constructor(
        private componentInteractionSrv: ComponentInteractionSrevice,
        private interactionService: InteractionService,
        private setShapeUserDataSrv: SetShapeUserDataService,
        private router: Router,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        private dialogService: DialogService,
        private structureService: StructureService,
        private route: ActivatedRoute,
        private location: Location,
        private generateShapeService: GenerateNewShapes,
        private countCalculatorService: CountCalculatorService,
        private helperService: HelperService,
        private s3UploadService: S3UploadService,
        private actionHelperService: ActionHelperService,
        private sceneManagerService: SceneManagerService,
        private routeTrackingService: RouteTrackingService,
        private filenameService: FilenameService,
        private shapeResourceManagerService: ShapeResourceManagerService,
        private undoRedoService: UndoRedoService,
    ) {}

    ngOnInit(): void {
        this.shapeResourceManagerService.initializeResources(false);
        this.sceneManagerService.objects$.subscribe((objects) => {
            this.objects = objects;
        });
    }

    async ngAfterViewInit(): Promise<void> {
        await this.initializeCanvas();
        this.setupCameraPosition();
        this.sceneManagerService.setScene(this.scene);
        this.sceneManagerService.addObjectsToScene([this.gridHelper, this.plane]);
        if (this.renderer) {
            this.interactionService.initialize(this.scene, this.camera, this.renderer);
        }
        this.setupEventListeners();
        this.setupSubscriptions();
    }

    // Scene Initialization Methods
    private async initializeCanvas(): Promise<void> {
        await this.runAsyncTasks([
            this.initializeScene(),
            this.initializeCamera(),
            this.initializeRenderer(),
            this.initializeOrbitControl(),
            this.createPlaneAndGrid(),
        ]);

        this.animate();
    }

    // Helper function to run async tasks sequentially
    private async runAsyncTasks(tasks: Promise<void>[]): Promise<void> {
        for (const task of tasks) {
            await task; // Ensure each task is awaited one after another
        }
    }

    private async initializeScene(): Promise<void> {
        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color(ColorCodes.sceneBackground);
        this.sceneManagerService.setScene(this.scene);
    }

    private async initializeCamera(): Promise<void> {
        const { width, height } = this.getContainerDimensions();
        this.camera = new THREE.PerspectiveCamera(
            CanvasConfig.perspectiveCameraFov,
            width / height,
            CanvasConfig.perspectiveCameraNearField,
            CanvasConfig.perspectiveCameraFarField,
        );
    }

    private setupCameraPosition(): void {
        this.camera.position.set(
            CanvasConfig.cameraPosition_X_Cooridinate,
            CanvasConfig.cameraPosition_Y_Cooridinate,
            CanvasConfig.cameraPosition_Z_Cooridinate,
        );

        this.componentInteractionSrv.updateRotation(this.camera.position.clone());
    }

    private async initializeRenderer(): Promise<void> {
        if (!this.canvasRef) return;

        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvasRef.nativeElement,
            antialias: true,
            alpha: true,
        });

        const { width, height } = this.getContainerDimensions();
        this.renderer.setSize(width, height, false);
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.domElement.tabIndex = 0;
    }

    // Helper function to get the container's dimensions
    private getContainerDimensions(): { width: number; height: number } {
        const container = this.canvasRef.nativeElement;
        return { width: container.clientWidth, height: container.clientHeight };
    }

    private async initializeOrbitControl(): Promise<void> {
        if (!this.camera || !this.renderer) return;

        this.orbitControl = new OrbitControls(this.camera, this.renderer.domElement);
        this.configureOrbitControls();
    }

    private configureOrbitControls(): void {
        const config = CanvasConfig;
        this.orbitControl.enabled = true;
        this.orbitControl.enableZoom = true;
        this.orbitControl.minDistance = config.orbitControlMinDistance;
        this.orbitControl.maxDistance = config.orbitControlMaxDistance;
        this.orbitControl.target = new THREE.Vector3(
            config.orbitControlTarget_X_Coordinate,
            config.orbitControlTarget_Y_Coordinate,
            config.orbitControlTarget_Z_Coordinate,
        );
        this.orbitControl.rotateSpeed = config.oribitControlRotateSpeed;
        this.orbitControl.zoomSpeed = config.oribitControlZoomSpeed;
        this.orbitControl.panSpeed = config.oribitControlPanSpeed;
        this.orbitControl.enableDamping = true;
        this.orbitControl.dampingFactor = config.orbitControlDampingFactor;
        this.orbitControl.maxPolarAngle = Math.PI / config.orbitControlMaxPolarAngleDivisor;
    }

    private async createPlaneAndGrid(): Promise<void> {
        const canvasSize = CanvasConfig.divisions * CanvasConfig.cellSize;

        // Create plane
        this.plane = new THREE.Mesh(
            new THREE.PlaneGeometry(canvasSize, canvasSize),
            new THREE.MeshBasicMaterial({ color: 0xcccccc, side: THREE.DoubleSide }),
        );
        this.plane.rotateX(-Math.PI / 2);
        this.plane.position.y = CanvasConfig.planePostionIn_Y_Coordinate - CanvasConfig.planePositionOffset;
        this.plane.name = AuxiliaryObjectType.Plane;
        this.plane.userData[PlaneCustomProperties.orientationKey] = false;
        this.plane.userData[PlaneCustomProperties.isDraggable] = false;

        // Create grid
        this.gridHelper = new THREE.GridHelper(canvasSize, CanvasConfig.divisions);
        this.gridHelper.name = AuxiliaryObjectType.GridHelper;
        this.gridHelper.position.y = CanvasConfig.planePostionIn_Y_Coordinate;
    }

    private onWindowResize(): void {
        const { width, height } = this.getContainerDimensions();
        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();
        if (this.renderer) {
            this.renderer.setSize(width, height, false);
        }
        this.interactionService.render();
    }

    private handleBeforeUnload = (event: BeforeUnloadEvent) => {
        if (this.isEditted) {
            event.preventDefault();
            event.returnValue = ""; // Standard way to trigger the prompt
        }
    };

    // Handle OrbitControls change event
    private onOrbitControlChange = (): void => {
        this.interactionService.render();
        // Update the compass cube with the new rotation
        this.componentInteractionSrv.updateRotation(this.camera.position.clone());
        // this.interactionService.render();
    };

    // Event Listeners Setup
    private setupEventListeners(): void {
        window.addEventListener("resize", this.onWindowResize.bind(this));
        window.addEventListener("beforeunload", this.handleBeforeUnload);
        this.orbitControl?.addEventListener("change", this.onOrbitControlChange);
    }

    private setupSubscriptions(): void {
        this.setupGeneratedShapeSubscription();
        this.setupRouteSubscriptions();
        this.setupStateChangeSubscriptions();
        this.setupStructureImageSubscription();
        this.setupOutputPatternSubscription();
        if (this.renderer) {
            this.interactionService.subscribeToEvents(this.renderer, this.camera);
        }
    }

    private setupGeneratedShapeSubscription(): void {
        const generatedShapeSub = this.componentInteractionSrv.getGeneratedShapeInfo().subscribe((shape) => {
            this.handleGeneratedShape(shape as THREE.Mesh);
        });
        this.subscriptions.push(generatedShapeSub);
    }

    private setupStateChangeSubscriptions(): void {
        const merged$ = merge(
            this.interactionService.isEditted$,
            this.generateShapeService.isNewShapeGenerated$,
            this.actionHelperService.isRotated$,
        ).pipe(
            scan((acc, current) => acc || current, false),
            distinctUntilChanged(),
        );

        const isEdittedSub = merged$.pipe(filter(() => this.isEditMode)).subscribe((value) => {
            this.isEditted = value;
        });

        this.subscriptions.push(isEdittedSub);
    }

    private setupStructureImageSubscription(): void {
        const structureImageSub = this.structureService.structureImage$.subscribe(() => {
            this.capturedImageLength = this.structureService.getImagesCount();
            this.isDisabledCaptureBtn = this.capturedImageLength > 9;
        });
        this.subscriptions.push(structureImageSub);
    }

    private setupOutputPatternSubscription(): void {
        const outputPatternSub = this.componentInteractionSrv.getOutputPattern().subscribe((res) => {
            this.outputPattern = res;
        });
        this.subscriptions.push(outputPatternSub);
    }

    private handleGeneratedShape(shape: THREE.Mesh): void {
        this.setShapeUserDataSrv.adjacentData(this.objects, shape);
        this.sceneManagerService.addObjectsToScene([shape]);
        const action = {
            actionType: Action.add,
            object: shape,
        };
        this.undoRedoService.addToUndo(action);
        this.isEditted = true;
        this.interactionService.render();
    }

    private setupRouteSubscriptions(): void {
        const paramSub = this.route.paramMap.subscribe((paramMap) => {
            if (paramMap.has("id")) {
                this.handleStructureIdParam(+paramMap.get("id")!);
            }
        });

        const querySub = this.route.queryParamMap.subscribe((queryParamMap) => {
            if (queryParamMap.has("id")) {
                this.handleStructureIdQuery(+queryParamMap.get("id")!);
            }
        });

        this.subscriptions.push(paramSub, querySub);
    }

    private handleStructureIdParam(id: number): void {
        this.isEditMode = true;
        this.structureId = id;
        this.loadStructure(this.structureId);
    }

    private handleStructureIdQuery(id: number): void {
        if (id) {
            this.isEditMode = true;
            this.structureId = id;
            this.loadStructure(this.structureId);
        } else if (!this.isEditMode) {
            this.isEditMode = false;
            this.structureId = null;
        }
    }

    private loadStructure(structureId: number): void {
        this.showSpinner(true); // Show the spinner before starting the operation
        this.structureService.getStructureDetails(structureId).subscribe({
            next: (res) => {
                if (!res || !res.structureData) {
                    this.showSpinner(false);
                    return;
                }
                this.structureData = res.structureData;
                const url = this.structureData.structureData;
                const isUpdated = this.structureData.isUpdated;
                if (url) {
                    const extension = this.sceneManagerService.getFileExtension(url);
                    switch (extension) {
                        case "json":
                            this.sceneManagerService
                                .loadFromJsonUrl(url, isUpdated)
                                .then(() => {
                                    this.interactionService.render();
                                })
                                .finally(() => {
                                    this.showSpinner(false);
                                });
                            break;
                        case "glb":
                            this.sceneManagerService
                                .loadFromGlbUrl(url, isUpdated)
                                .then(() => {
                                    this.interactionService.render();
                                })
                                .finally(() => {
                                    this.showSpinner(false);
                                });
                            break;
                        default:
                            break;
                    }
                }
            },
            error: () => {
                this.showSpinner(false);
            },
        });
    }

    private animate(): void {
        requestAnimationFrame(() => this.animate());
        this.orbitControl.update();
    }

    // Public Methods
    public zoom(direction: string): void {
        switch (direction) {
            case this.zoomConfig.zoomIn:
                this.handleZoomIn();
                break;
            case this.zoomConfig.zoomOut:
                this.handleZoomOut();
                break;
        }
    }

    private handleZoomIn(): void {
        if (this.camera.zoom < this.zoomConfig.maximumAllowedZoom) {
            this.camera.zoom += this.zoomConfig.zoomIncrement;
            this.camera.updateProjectionMatrix();
        } else {
            this.showZoomLimitMessage();
        }
    }

    private handleZoomOut(): void {
        if (this.camera.zoom > 0.5) {
            this.camera.zoom -= 0.2;
            this.camera.updateProjectionMatrix();
        } else {
            this.showZoomLimitMessage();
        }
    }

    private showZoomLimitMessage(): void {
        this.snackBar.open(this.zoomConfig.maxZoomLimitMessage, SnackBarConfig.Actions.CLOSE, {
            duration: SnackBarConfig.Duration.MEDIUM,
        });
    }

    public redirectPage() {
        if (this.isEditted) {
            this.openDialog();
            this.componentInteractionSrv.setSelectedShape(null);
        } else {
            const previousUrl = this.routeTrackingService.getPreviousUrl();
            this.location.back(); // First back
            if (previousUrl === "/login") {
                setTimeout(() => {
                    // Optional: Only go back second time if still at or near login
                    if (this.router.url === "/login" || previousUrl === "/login") {
                        this.location.back(); // Second back
                    }
                    this.componentInteractionSrv.setSelectedShape(null);
                }, 0);
            }
        }
    }

    private uploadStructureDataToS3(): Promise<string> {
        return new Promise((resolve, reject) => {
            const objectsWithoutPlaneMesh = this.objects.filter((object: THREE.Object3D) => {
                return object.name !== "plane";
            });

            const objectsWithoutPlaneJson = objectsWithoutPlaneMesh.map((object: THREE.Object3D) => {
                const objectJson = object.toJSON();
                if (object instanceof THREE.Mesh) {
                    object.userData["rotation"] = {
                        x: object.rotation.x,
                        y: object.rotation.y,
                        z: object.rotation.z,
                        order: object.rotation.order,
                    };
                }
                return objectJson;
            });

            const partSize = 5 * 1024 * 1024;
            const file = JSON.stringify(objectsWithoutPlaneJson);
            const parts = Math.ceil(file.length / partSize);
            const partNumbers = Array.from({ length: parts }, (_, i) => i + 1);

            const now = new Date();
            const formattedDate = `${now.getFullYear()}${(now.getMonth() + 1).toString().padStart(2, "0")}${now
                .getDate()
                .toString()
                .padStart(2, "0")}${now.getHours().toString().padStart(2, "0")}${now
                .getMinutes()
                .toString()
                .padStart(2, "0")}${now.getSeconds().toString().padStart(2, "0")}`;

            const filePathWithName = `${environment.name}/structures/models/${formattedDate}.json`;

            this.s3UploadService
                .uploadFile(filePathWithName, partNumbers, file)
                .then((url) => {
                    resolve(url); // Resolve the promise with the URL
                })
                .catch((error) => {
                    reject(error); // Reject the promise in case of an error
                });
        });
    }

    private openDialog(): void {
        const dialogRef = this.dialog.open(GenericDialogComponent, {
            disableClose: true,
            data: {
                componentName: DialogInvokingComponents.SaveDesignConfirmation,
                title: this.isEditMode ? "Update Design Confirmation" : "Save Design Confirmation",
                content: this.isEditMode
                    ? "Do you want to update your structure?"
                    : "Do you want to save your structure?",
                firstBtn: "Yes",
                secondBtn: "No",
            },
            autoFocus: false,
            restoreFocus: false,
        });

        dialogRef.afterClosed().subscribe((saveClicked) => {
            if (saveClicked === true) {
                if (!this.sceneManagerService.getMeshes().length) {
                    this.openSnackBar(StructureResponseMessage.NoStructureFound, SnackBarConfig.Actions.OKAY);
                    return;
                } else {
                    const objectsWithoutPlaneMesh = this.objects.filter((object) => {
                        return object.name != "plane";
                    });

                    const hasInvalidShapes = objectsWithoutPlaneMesh.filter((shape) =>
                        this.helperService.isShapeOnAir(shape as THREE.Mesh, objectsWithoutPlaneMesh),
                    );

                    if (hasInvalidShapes.length) {
                        this.openSnackBar(StructureResponseMessage.ShapeIsNotValid, SnackBarConfig.Actions.OKAY);
                        return;
                    }

                    const data = {
                        componentName: DialogInvokingComponents.StructureDetails,
                        title: this.isEditMode ? "Update Structure" : "Save Structure",
                        firstBtn: this.isEditMode ? "Update" : "Save",
                        secondBtn: "Cancel",
                        structureName: this.structureData?.structureName || "",
                        structureDescription: this.structureData?.structureDescription || "",
                    };

                    this.dialogService
                        .openDialog(data)
                        .afterClosed()
                        .subscribe(async (response) => {
                            if (!response) {
                                return;
                            }

                            const file = this.autoCaptureCanvas();
                            const urls = await this.s3UploadService.multipartOperation([file], "structures", "images");
                            this.autoCapturedImageUrl = urls[0];

                            const meshes = this.sceneManagerService.getMeshes();
                            this.countCalculatorService.calculate(meshes);
                            const fileUrl = await this.uploadStructureDataToS3();
                            const body: StructureBaseModel = {
                                structureName: response.structureName,
                                structureDescription: response.structureDescription,
                                structureData: fileUrl,
                                isUpdated: true,
                                status: StructureStatus.Saved,
                                imageurl: this.autoCapturedImageUrl,
                                modelQuantityJson: {
                                    totalcount: this.objects.filter((mesh) => mesh.name !== AuxiliaryObjectType.Plane)
                                        .length,
                                    faceinformation: this.outputPattern,
                                },
                            };
                            if (this.structureData?.id) {
                                this.structureService.updateStructure(this.structureData?.id, body).subscribe({
                                    next: (response) => {
                                        this.openSnackBar(response.message, SnackBarConfig.Actions.OKAY);
                                        this.router.navigateByUrl("myStructures");
                                    },
                                    error: (error) => {
                                        this.openSnackBar(error.error, SnackBarConfig.Actions.OKAY);
                                    },
                                });
                            } else {
                                this.structureService.saveStructure(body).subscribe({
                                    next: (response) => {
                                        this.openSnackBar(response.message, SnackBarConfig.Actions.OKAY);
                                        this.router.navigateByUrl("myStructures");
                                    },
                                    error: (error) => {
                                        this.openSnackBar(error.error, SnackBarConfig.Actions.OKAY);
                                    },
                                });
                            }
                        });
                }
            } else {
                this.location.back();
            }
        });
    }

    private openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            ...SnackBarConfig.Defaults,
        });
    }

    // For download
    public captureImage(): void {
        if (this.isDisabledCaptureBtn) {
            return;
        }

        // Create offscreen renderer with same dimensions and settings
        const offscreenRenderer = new THREE.WebGLRenderer({
            alpha: true,
            antialias: true,
        });
        offscreenRenderer.setSize(this.renderer!.domElement.width, this.renderer!.domElement.height);

        // Clone the scene to avoid modifying original
        const sceneCopy = this.scene.clone();

        // Find and remove grid helper and plane from cloned scene
        const gridHelperForCanvas = sceneCopy.children.find((child) => child instanceof THREE.GridHelper);
        const planeMesh = sceneCopy.children.find(
            (child) => child instanceof THREE.Mesh && child.name === this.plane.name,
        );

        if (gridHelperForCanvas) sceneCopy.remove(gridHelperForCanvas);
        if (planeMesh) sceneCopy.remove(planeMesh);

        // Set up transparent background
        sceneCopy.background = null;
        offscreenRenderer.setClearAlpha(0);

        // Render the scene with the current camera
        offscreenRenderer.render(sceneCopy, this.camera);

        // Capture the image
        const imgData = offscreenRenderer.domElement.toDataURL("image/png");
        const filename = `image_${Date.now()}.png`;
        const file = this.dataURLToFile(imgData, filename);

        // Clean up
        offscreenRenderer.dispose();

        // Add the image to the structure service
        this.structureService.addImage(file);
    }

    private dataURLToFile(dataURL: string, filename: string): File {
        const [header, data] = dataURL.split(",");
        const mime = header.match(/:(.*?);/)![1];
        const binaryString = atob(data);
        const arrayBuffer = new ArrayBuffer(binaryString.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < binaryString.length; i++) {
            uint8Array[i] = binaryString.charCodeAt(i);
        }

        const blob = new Blob([uint8Array], { type: mime });
        return new File([blob], filename, { type: mime });
    }

    public autoCaptureCanvas() {
        // Create a new renderer for off-screen rendering
        const offscreenRenderer = new THREE.WebGLRenderer({
            alpha: true, // Enable alpha channel
            antialias: true,
        });
        offscreenRenderer.setSize(this.renderer!.domElement.width, this.renderer!.domElement.height);

        // Create a clone of the scene
        const sceneCopy = this.scene.clone();

        // Find and remove grid helper and plane from the cloned scene
        const gridHelper = sceneCopy.children.find((child) => child instanceof THREE.GridHelper);
        const planeMesh = sceneCopy.children.find(
            (child) => child instanceof THREE.Mesh && child.name === this.plane.name,
        );

        if (gridHelper) sceneCopy.remove(gridHelper);
        if (planeMesh) sceneCopy.remove(planeMesh);

        // Set up camera for the screenshot
        const cameraCopy = this.camera.clone();
        cameraCopy.position
            .set(
                CanvasConfig.cameraPosition_X_Cooridinate,
                CanvasConfig.cameraPosition_Y_Cooridinate,
                CanvasConfig.cameraPosition_Z_Cooridinate,
            )
            .addScalar(10);
        cameraCopy.lookAt(new THREE.Vector3(0, 0, 0));
        cameraCopy.updateProjectionMatrix();

        // Clear background (make it transparent)
        sceneCopy.background = null;

        // Render the scene
        offscreenRenderer.render(sceneCopy, cameraCopy);

        // Get the image data with PNG format to preserve transparency
        const imgData = offscreenRenderer.domElement.toDataURL("image/png");
        const fileName = this.filenameService.generateTimestampFileName("image", "png");
        const file = this.dataURLToFile(imgData, fileName);

        // Clean up
        offscreenRenderer.dispose();

        return file;
    }

    public async captureScene() {
        this.showSpinner(true);
        await this.sceneManagerService
            .exportToS3("structures", "glb-files")
            .then((url) => {
                const glbUrl = url;
                this.isDisabledCapture360Btn = true;
                this.structureService.captureGlbUrl(glbUrl);
                this.openSnackBar(StructureResponseMessage.Capture360, SnackBarConfig.Actions.OKAY);
            })
            .catch((error) => {
                this.openSnackBar(error.message, SnackBarConfig.Actions.OKAY);
            })
            .finally(() => {
                this.showSpinner(false);
            });
    }

    // Cleanup
    ngOnDestroy(): void {
        this.cleanupEventListeners();
        this.cleanupSubscriptions();

        // Dispose of Three.js resources
        this.dispose();
    }

    private cleanupEventListeners(): void {
        window.removeEventListener("resize", this.onWindowResize);
        window.removeEventListener("beforeunload", this.handleBeforeUnload);
        this.orbitControl?.removeEventListener("change", this.onOrbitControlChange);
    }

    private cleanupSubscriptions(): void {
        this.unsubscribeToInteractiveEvents();
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    private unsubscribeToInteractiveEvents(): void {
        this.interactionService.unsubscribeToEvents();
    }

    private dispose(): void {
        // Dispose of geometries
        this.scene.traverse((object) => {
            if (object instanceof THREE.Mesh) {
                object.geometry.dispose();
                if (Array.isArray(object.material)) {
                    object.material.forEach((material) => material.dispose());
                } else {
                    object.material.dispose();
                }
            }
        });

        // Dispose of renderer
        if (this.renderer) {
            this.renderer.dispose();
            this.renderer = null;
        }

        // Clear scene
        while (this.scene.children.length > 0) {
            this.scene.remove(this.scene.children[0]);
        }
    }

    public showSpinner(show: boolean): void {
        this.isLoading = show;
    }
}
