import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { ProductCategories, productCategories, ProductOperation } from "@models/product.model";
import {
    StructureResponseMessage,
    StructureOptions,
    StructureInstance,
    StructureStatus,
    StructureBaseModel,
    StructureUpdateModel,
} from "@models/structure.model";
import { ProductService } from "../../services/product/product.service";
import { noSpacesValidator } from "../../shared/shared/no-leading-space-validator.directive";
import { nonNegativeValidator } from "../../shared/non-negative-validator.directive";
import { appConfig } from "../../../environments/app.config";
import { AdminService } from "../../services/admin/admin.service";
import { StructureService } from "../../services/structure/structure.service";
import { catchError, of, Subscription, switchMap } from "rxjs";
import { S3UploadService } from "../../services/s3-upoad/s3-upload.service";
import { discountPriceValidator } from "../../shared/shared/discount-price-validator.directive";
import { MatDialog } from "@angular/material/dialog";
import { GenericDialogComponent } from "../../shared/generic-dialog/generic-dialog.component";
import { DialogInvokingComponents } from "@models/generic-dialog.model";
import { S3UploadCategoryName, S3UploadFileType } from "@models/fileUpload.model";
import { SceneManagerService } from "../../services/scene-manager/scene-manager.service";
import { SnackBarConfig } from "../../constants/snackbar.constants";

@Component({
    selector: "app-saved-structures-view",
    templateUrl: "./saved-structures-view.component.html",
    styleUrls: ["./saved-structures-view.component.scss"],
})
export class SavedStructuresViewComponent implements OnInit, OnDestroy {
    public captureImages: string[] = [];
    public structure!: StructureInstance;
    public structureForm: FormGroup;
    public loading: boolean = false;
    public categories: string[] = [];
    public gstRates = appConfig.gstRates;
    public structureStatus = StructureStatus;
    public isReadOnly = false;
    public isGlb: boolean = false;
    private structureId!: number;
    private isRemovingImage = false;
    private structureImageSub!: Subscription;

    constructor(
        private formBuilder: FormBuilder,
        private productService: ProductService,
        private router: Router,
        private adminService: AdminService,
        private snackBar: MatSnackBar,
        private s3UploadService: S3UploadService,
        private structureService: StructureService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private sceneManagerService: SceneManagerService,
    ) {
        this.categories = this.getFilteredCategories();

        this.structureForm = this.formBuilder.group(
            {
                structureName: ["", [Validators.required, noSpacesValidator()]],
                structureDescription: ["", [Validators.required, noSpacesValidator()]],
                actualPrice: [null, [Validators.required, nonNegativeValidator()]],
                discountPrice: [null, nonNegativeValidator()],
                model3DJson: [null],
                modelQuantityJson: [null, [Validators.required]],
                blocksQuantity: [null, [Validators.required]],
                category: [null, [Validators.required]],
                gstRate: [null, [Validators.required]],
            },
            { validators: discountPriceValidator() },
        );
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            this.structureId = +params["id"];
            if (this.structureId) {
                this.loadStructureDetails(this.structureId);
            }
        });

        this.structureImageSub = this.structureService.structureImage$.subscribe((captureImageData) => {
            if (captureImageData.image && captureImageData.action === "add" && !this.isRemovingImage) {
                this.addImageInS3(captureImageData.image);
            }
        });

        this.structureForm.get("modelQuantityJson")?.valueChanges.subscribe((jsonValue) => {
            this.updateBlocksQuantity(jsonValue);
        });

        window.addEventListener("beforeunload", this.handleBeforeUnload); //reload
    }

    private getFilteredCategories(): string[] {
        const excludedCategory = ProductCategories.BuyBlocks;
        return productCategories.filter((category) => category !== excludedCategory);
    }

    private loadStructureDetails(structureId: number): void {
        this.adminService.getStructureDetails(structureId).subscribe({
            next: (structure) => {
                if (structure.structureData) {
                    this.structure = structure.structureData;
                    if (this.structure.structureData) {
                        const extension = this.sceneManagerService.getFileExtension(this.structure.structureData);
                        switch (extension) {
                            case "json":
                                this.isGlb = false;
                                break;
                            case "glb":
                                this.isGlb = true;
                                break;
                            default:
                                this.isGlb = true;
                                break;
                        }
                    }
                    this.patchFormValue();
                }
                this.isReadOnly = structure.structureData?.status !== StructureStatus.Published;
            },
        });
    }

    private patchFormValue(): void {
        this.structureForm.get("structureName")?.setValue(this.structure.structureName);
        this.structureForm.get("structureDescription")?.setValue(this.structure.structureDescription);
        this.structureForm.get("modelQuantityJson")?.setValue(JSON.stringify(this.structure.modelQuantityJson));
        if (this.structure.Product) {
            this.structureForm.patchValue({
                actualPrice: this.structure.Product.price,
                discountPrice: this.structure.Product.discountPrice,
                model3DJson: this.structure.Product.model3DJson
                    ? JSON.stringify(this.structure.Product.model3DJson)
                    : null,
                category: this.structure.Product.category!,
                gstRate: this.structure.Product.gstRate,
            });

            this.captureImages = this.structure.Product.images?.imagesUrl ?? [];
        }
    }

    private updateBlocksQuantity(jsonValue: string): void {
        try {
            const parsedJson = JSON.parse(jsonValue);
            if (parsedJson && typeof parsedJson.totalcount === "number") {
                this.structureForm.patchValue({ blocksQuantity: parsedJson.totalcount });
                this.structureForm.get("modelQuantityJson")?.setErrors(null);
            } else {
                this.structureForm.patchValue({ blocksQuantity: "" });
                this.structureForm.get("modelQuantityJson")?.setErrors({ invalidJson: true });
            }
        } catch (error) {
            this.structureForm.patchValue({ blocksQuantity: "" });
            this.structureForm.get("modelQuantityJson")?.setErrors({ invalidJson: true });
        }
    }

    public async addImageInS3(file: File): Promise<void> {
        this.loading = true;
        await this.s3UploadService
            .multipartOperation([file], S3UploadCategoryName.StructureCaptured, S3UploadFileType.Images)
            .then((newImageUrls) => {
                this.loading = false;
                const captureImageSet = new Set(this.captureImages);
                captureImageSet.add(newImageUrls[0]);
                this.captureImages = Array.from(captureImageSet);
            })
            .catch((error) => {
                this.loading = false;
                this.openSnackBar(error.error, SnackBarConfig.Actions.CLOSE);
            });
    }

    public removeImageFromS3(index: number): void {
        this.isRemovingImage = true; // Set the flag
        const imageUrl = this.captureImages[index];
        this.loading = true;
        this.productService.deleteFile(imageUrl).subscribe({
            next: () => {
                this.loading = false;
                this.captureImages.splice(index, 1);
                this.captureImages = Array.from(new Set(this.captureImages));
                this.structureService.removeImage(index);
                this.isRemovingImage = false; // Reset the flag
            },
            error: (error) => {
                this.loading = false;
                this.openSnackBar(error.error, SnackBarConfig.Actions.CLOSE);
            },
        });
    }

    public async removeAllImagesFromS3(): Promise<void> {
        this.isRemovingImage = true; // Set the flag
        if (!this.captureImages.length) {
            return;
        }
        this.productService.deleteFiles(this.captureImages).subscribe((deleted) => {
            if (deleted) {
                this.captureImages = [];
                this.structureService.removeAllImages();
            }
            this.isRemovingImage = false; // Reset the flag
        });
    }

    public isCheckApprove(): boolean {
        const structureName = this.structureForm.get("structureName")?.value?.trim();
        const actualPrice = this.structureForm.get("actualPrice")?.value;
        const category = this.structureForm.get("category")?.value;
        const isStructureNameValid = structureName && structureName.length > 0;
        const isActualPriceValid = actualPrice !== null && actualPrice !== undefined && actualPrice > 0;
        const isCategorySelected = category && category.length > 0;
        const capturedGlbUrl = this.structureService.capturedStructureGlbUrl;
        return (
            isStructureNameValid &&
            isActualPriceValid &&
            isCategorySelected &&
            this.captureImages.length > 0 &&
            (this.isGlb || capturedGlbUrl)
        );
    }

    public onModel3DJsonBlur(event: Event): void {
        const value = (event.target as HTMLTextAreaElement).value;
        const formattedValue = this.formatJson(value);
        this.structureForm.get("model3DJson")?.setValue(formattedValue);
    }

    public approveStructure(): void {
        if (!this.isCheckApprove()) {
            return;
        }

        const initialStructureData: StructureUpdateModel = {
            status: StructureOptions.Approved,
        };

        this.adminService
            .updateStructureData(+this.structure?.id!, initialStructureData)
            .pipe(
                switchMap(() => {
                    const productData: ProductOperation = {
                        name: this.structureForm.get("structureName")?.value?.trim(),
                        description: this.structureForm.get("structureDescription")?.value?.trim(),
                        price: this.structureForm.get("actualPrice")?.value,
                        discountPrice: this.structureForm.get("discountPrice")?.value,
                        createdBy: this.structure?.userDetails?.id.toString()!,
                        isActive: true,
                        category: this.structureForm.get("category")?.value,
                        gstRate: this.structureForm.get("gstRate")?.value,
                        glbUrl: this.isGlb
                            ? this.structure.structureData
                            : this.structureService.capturedStructureGlbUrl,
                        model3DJson: this.structureForm.get("model3DJson")?.value,
                        modelQuantityJson: this.structureForm.get("modelQuantityJson")?.value,
                        images: { imagesUrl: this.captureImages },
                    };
                    return this.productService.addProduct(productData);
                }),
                switchMap((response) => {
                    const finalStructureData: StructureUpdateModel = {
                        productId: response.product.id,
                    };
                    return this.adminService.updateStructureData(+this.structure.id, finalStructureData);
                }),
                catchError((error) => {
                    this.openSnackBar(error.error, SnackBarConfig.Actions.OKAY);
                    return of(null); // Return an observable to continue the stream
                }),
            )
            .subscribe({
                next: (response) => {
                    if (response) {
                        this.openSnackBar(StructureResponseMessage.ApprovedSuccess, SnackBarConfig.Actions.OKAY);
                        this.router.navigate(["/admin/structureReview"]);
                    }
                },
                error: (error) => {
                    this.openSnackBar(error.error, SnackBarConfig.Actions.OKAY);
                },
            });
    }

    public rejectStructure(): void {
        this.openRejectStructuredialogbox();
    }

    public openRejectStructuredialogbox(): void {
        const dialogRef = this.dialog.open(GenericDialogComponent, {
            data: {
                componentName: DialogInvokingComponents.RejectStructure,
                title: "Reason for rejection",
                inputs: [
                    {
                        controlName: "reason",
                        value: "",
                        placeholder: "Reason for rejection",
                        type: "text",
                    },
                ],
                firstBtn: "Reject",
                secondBtn: "Cancel",
            },
            autoFocus: false,
            restoreFocus: false,
        });
        dialogRef.componentInstance.saveClicked.subscribe((data) => {
            if (data) {
                const structureData: StructureUpdateModel = {
                    status: StructureOptions.Rejected,
                    rejectReason: data.reason,
                };

                this.adminService.updateStructureData(+this.structure?.id!, structureData).subscribe({
                    next: () => {
                        this.openSnackBar(StructureResponseMessage.RejectSuccessfully, SnackBarConfig.Actions.OKAY);
                        this.router.navigate(["/admin/structureReview"]);
                    },
                    error: (error) => {
                        this.openSnackBar(error.error, SnackBarConfig.Actions.OKAY);
                    },
                });
            }
        });
    }

    private openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            ...SnackBarConfig.Defaults,
        });
    }

    public redirectBack(): void {
        if (this.captureImages.length && this.structure.status === StructureStatus.Published) {
            this.removeAllImagesFromS3().then(() => {
                this.router.navigateByUrl("admin/structureReview");
            });
        } else {
            this.router.navigateByUrl("admin/structureReview");
        }
    }

    private handleBeforeUnload = (event: BeforeUnloadEvent) => {
        if (!this.captureImages.length) {
            return;
        }
        event.preventDefault();
        event.returnValue = "";
    };

    private formatJson(jsonString: string): string {
        try {
            return JSON.stringify(JSON.parse(jsonString), null, 2);
        } catch (e) {
            return jsonString;
        }
    }

    ngOnDestroy(): void {
        this.structureImageSub.unsubscribe();
        window.removeEventListener("beforeunload", this.handleBeforeUnload);
    }

    public openDialogImage(image: string) {
        const dialogRef = this.dialog.open(GenericDialogComponent, {
            data: { image: image, componentName: DialogInvokingComponents.PreviewImage },
        });
    }
}
