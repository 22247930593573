<div class="canvas-container">
    <div *ngIf="isLoading" class="loader-overlay">
        <mat-spinner diameter="60"></mat-spinner>
    </div>
    <canvas #main_canvas class="main-canvas" (mousedown)="$event.preventDefault()"></canvas>
    <div class="mini-canvas" *ngIf="!isViewMode" (mousedown)="$event.preventDefault()">
        <shape-selector #shapeSelector></shape-selector>
    </div>
    <div class="compass" (mousedown)="$event.preventDefault()">
        <compass-cube></compass-cube>
    </div>
    <div class="capture-btn-container" *ngIf="isViewMode && structureData?.status === structureStatus.Published">
        <button mat-button class="capture-btn" (click)="captureImage()" [disabled]="isDisabledCaptureBtn">
            Capture
        </button>
        <button
            mat-button
            class="capture-btn"
            *ngIf="!isGlb"
            (click)="captureScene()"
            [disabled]="isDisabledCapture360Btn"
        >
            Capture 360
        </button>
    </div>
    <div class="zoom-actions" *ngIf="!isViewMode">
        <mat-icon class="in" svgIcon="zoomIn" (click)="zoom(zoomConfig.zoomIn)"> </mat-icon>
        <mat-icon class="out" svgIcon="zoomOut" (click)="zoom(zoomConfig.zoomOut)"> </mat-icon>
    </div>
    <div class="drawer-container" *ngIf="!isViewMode" (click)="$event.stopPropagation()">
        <action-button-container
            [isEditMode]="isEditMode"
            [isEditted]="isEditted"
            [structureData]="structureData"
            [autoCaptureCanvasFunction]="autoCaptureCanvas.bind(this)"
            (showSpinner)="showSpinner($event)"
        >
            >
        </action-button-container>
    </div>
    <div class="back-button" (click)="redirectPage()" *ngIf="!isViewMode">
        <mat-icon>arrow_back</mat-icon>
    </div>
</div>
