export enum CanvasConfig {
    perspectiveCameraFov = 75,
    // perspectiveCameraAspectRatio = 1,
    perspectiveCameraNearField = 0.1,
    perspectiveCameraFarField = 3000,
    cameraPosition_X_Cooridinate = 500,
    cameraPosition_Y_Cooridinate = 500,
    cameraPosition_Z_Cooridinate = 500,
    orbitControlMinDistance = 20,
    orbitControlMaxDistance = 1500,
    orbitControlTarget_X_Coordinate = 0,
    orbitControlTarget_Y_Coordinate = 0,
    orbitControlTarget_Z_Coordinate = 0,
    oribitControlRotateSpeed = 1.0,
    oribitControlPanSpeed = 0.8,
    oribitControlZoomSpeed = 1.2,
    orbitControlMaxPolarAngleDivisor = 2,
    orbitControlDampingFactor = 0.2,
    divisions = 49, // number of rows and columns must be an odd
    cellSize = 20,
    maxMinConstant = (divisions * cellSize) / 2 - 10,
    planePostionIn_Y_Coordinate = -9.5,
    planePositionOffset = 0.8,
    transparentOpacity = 0.3,
    solidOpacity = 1,
}

export enum ZoomConfig {
    maximumAllowedZoom = 4,
    minimumAllowedZoom = 0.5,
    zoomIncrement = 0.2,
    maxZoomLimitMessage = "You cannot zoom further",
    closeMessage = "Close",
    zoomIn = "in",
    zoomOut = "out",
    distancePointLight1 = 20,
    distancePointLight2 = 10,
}

export enum HalfScreenCanvasConfig {
    perspectiveCameraFov = 65,
    perspectiveCameraNearField = 0.1,
    perspectiveCameraFarField = 1000,
    cameraPosition_X_Cooridinate = 10, // Adjusted for half screen
    cameraPosition_Y_Cooridinate = 10, // Adjusted for half screen
    cameraPosition_Z_Cooridinate = 10, // Adjusted for half screen
    pointLightIntensity = 0.7,
    pointLightIntensity1 = 2,
    pointLightIntensity2 = 1.5,
    pointLightPosition1_X_Coordinate = 2,
    pointLightPosition1_Y_Coordinate = 5,
    pointLightPosition1_Z_Coordinate = 5,
    pointLightPosition2_X_Coordinate = -2,
    pointLightPosition2_Y_Coordinate = -5,
    pointLightPosition2_Z_Coordinate = -5,
    ambientLightIntensity = 4,
    directionalLightIntensity = 0.8,
    directionalLightIntensity1 = 0.1,
    spotLightIntensity = 2,
    orbitControlMaxDistance = 325, // Adjusted for half screen
    orbitControlTarget_X_Coordinate = 0,
    orbitControlTarget_Y_Coordinate = 0,
    orbitControlTarget_Z_Coordinate = 0,
    oribitControlRotateSpeed = 1.0,
    oribitControlPanSpeed = 0.8,
    oribitControlZoomSpeed = 1.2,
    orbitControlMaxPolarAngleDivisor = 2,
    orbitControlDynamicDampingFactor = 0.1,
    gridHelperSize = 100, // Adjusted for half screen
    gridHelperDivisions = 10, // Adjusted for half screen
    canvasWidth = 100, // Adjusted for half screen
    canvasHeight = 5, // Adjusted for half screen
    canvasDepth = 10, // Adjusted for half screen
    canavasPostionIn_Y_Coordinate = -10, // Adjusted for half screen
    spotLightPositionY = 3,
    spotLightPositionX = 2,
    nodeHoleRightTrianglePositionY = 1,
    nodeHoleRightTrianglePositionX = 0.7,
    nodeHoleConcavePrismX = 0.7,
    directionalLightValue = 0.5,
    ambientLightValue = 0.7,
}
