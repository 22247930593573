import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ComponentInteractionSrevice } from "src/app/services/component-interaction/component-interaction.service";
import { CompassConfig, CompassLabels } from "src/app/shared/models/compass/compass.model";
import { CanvasConfig } from "src/app/shared/shape-selector/shape-selector.model";
import { ColorCodes } from "@utils/color-constants";
import * as THREE from "three";
import { ColorRepresentation } from "three";

@Component({
    selector: "compass-cube",
    templateUrl: "./compass-cube.component.html",
    styleUrls: ["./compass-cube.component.scss"],
})
export class CompassCubeComponent implements OnInit, AfterViewInit {
    @ViewChild("compass", { static: true })
    compass: ElementRef | any;

    private renderer = new THREE.WebGLRenderer({
        alpha: true,
        preserveDrawingBuffer: true,
        antialias: true,
    });

    private scene: THREE.Scene;
    private camera: THREE.PerspectiveCamera;
    private compassNavigationCube!: THREE.Mesh;

    constructor(private componentInteractionSrv: ComponentInteractionSrevice) {
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(
            CanvasConfig.cameraConfig.fov,
            CanvasConfig.RenderConfig.width / CanvasConfig.RenderConfig.height, // width by height gives proper angle for camera
        );
        this.renderer.setPixelRatio(3);
    }

    ngOnInit(): void {
        this.componentInteractionSrv.getRotationObservable().subscribe((rotation) => {
            // Here we get the camera rotation of the main scene.
            const initialOrientation = this.calculateInitialOrientation(rotation);
            this.compassNavigationCube.rotation.copy(initialOrientation);
            this.render();
        });
    }

    ngAfterViewInit(): void {
        this.canvasConfiguration();
    }

    private canvasConfiguration(): void {
        this.renderer.setSize(CanvasConfig.RenderConfig.width, CanvasConfig.RenderConfig.height);
        this.renderer.setPixelRatio(2);
        this.camera.position.z = CanvasConfig.RenderConfig.cameraPositionZ;
        this.compass.nativeElement.appendChild(this.renderer.domElement);
        const ambientLight = new THREE.AmbientLight(ColorCodes.white, 1); // 1 is the intensity of the light.
        this.scene.add(ambientLight);
        this.scene.add(this.camera);
        this.createCompassNavigationCube();
    }

    private render(): void {
        this.renderer.render(this.scene, this.camera);
    }

    // Creates a cube for navigation.
    private createCompassNavigationCube(): void {
        const compassGeometry = new THREE.BoxGeometry(
            CompassConfig.cubeSize,
            CompassConfig.cubeSize,
            CompassConfig.cubeSize,
        );
        const compassMesh = new THREE.Mesh(compassGeometry, this.createCompassNavigationMaterials());
        const outlineContainer = new THREE.BoxHelper(compassMesh);
        compassMesh.add(outlineContainer);
        this.scene.add(compassMesh);
        this.compassNavigationCube = compassMesh;
    }

    // This will append the text on the cube representing the faces.
    private createCompassNavigationMaterials(): THREE.MeshBasicMaterial[] {
        const materials: THREE.MeshBasicMaterial[] = [];

        const labels = CompassLabels;

        labels.forEach((label) => {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            if (context) {
                canvas.width = CompassConfig.canvasWidth;
                canvas.height = CompassConfig.canvasHeight;
                context.fillStyle = CompassConfig.cubeColor;
                context.fillRect(0, 0, canvas.width, canvas.height);

                context.font = CompassConfig.cubeFont;
                context.fillStyle = CompassConfig.textColor;

                const textMetrics = context.measureText(label);
                const textWidth = textMetrics.width;
                const x = (canvas.width - textWidth) / 2; // Calculate the centered position
                const y = canvas.height / 2; // Vertically center

                context.fillText(label, x, y);

                const texture = new THREE.CanvasTexture(canvas);
                const material = new THREE.MeshBasicMaterial({
                    map: texture,
                    color: CompassConfig.textColor as ColorRepresentation,
                    transparent: true,
                    opacity: 0.4,
                });
                materials.push(material);
            }
        });

        return materials;
    }

    /*
    Since our main camera and scene is not set to exact center of the screen, creating a new compass canvas is quite challenging,
    we need to calculate the orientation against the initial position of the camera of main scene, right now it is only done for
    y axis, since we wont be able to see the bottom and the top is obvious.
  */
    private calculateInitialOrientation(cameraPosition: THREE.Vector3): THREE.Euler {
        const orientation = new THREE.Euler();

        // Calculate the orientation based on the camera's position
        orientation.set(0, Math.atan2(cameraPosition.z, cameraPosition.x), 0);

        return orientation;
    }
}
