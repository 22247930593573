export enum ColorCodes {
    sceneBackground = 0xbfd1e5,
    miniSceneBackground = 0x212124,
    renderSetClear = 0x333333,
    pointLight = 0xffffff,
    pointLight1 = 0xffaa00,
    pointLight2 = 0x00aaff,
    spotLight = 0xffffff,
    ambientLight = 0x404040,
    canvasMaterial = 0xffffff,
    red = 0xe64a4a, // default cube color
    black = 0x0a0a0a, // shapes border color
    white = 0xffffff, // for light
    transparentBlue = 0xadd8e6, // Aqua Blue
}

export enum ColorPickerInputConfg {
    inputType = "radio",
    name = "color",
    accessbilityDarkGreen = "dark green",
    valueAttributeForDarkGreen = "#117A65",
    accessbilityGreen = "green",
    valueAttributeForGreen = "#21BA45",
    accessbilityYellow = "yellow",
    valueAttributeForYellow = "#5DADE2",
    accessbilityOlive = "olive",
    valueAttributeForOlive = "#3633BF",
    accessbilityOrange = "orange",
    valueAttributeForOrange = "#CF871B",
    accessbilityTeal = "teal",
    valueAttributeForTeal = "#00B5AD",
    accessbilityBlue = "lightblue",
    valueAttributeForBlue = "#2185D0",
    accessbilityViolet = "darkblue",
    valueAttributeForViolet = "#3C79B7",
    accessbilityWhite = "white",
    valueAttributeForWhite = "#FFFFFF",
    accessbilityRed = "red",
    valueAttributeForRed = "#FF0000",
    accessbilityDarkYellow = "darkYellow",
    valueAttributeForDarkYellow = "#FFFF00",
    accessbilityMagenta = "magenta",
    valueAttributeForMagenta = "#FF00FF",
    accessbilityGray = "gray",
    valueAttributeForGray = "#808080",
    accessbilityDim = "dim",
    valueAttributeForDim = "#acacac",
    accessbilityDarkGrey = "darkGrey",
    valueAttributeForDarkGrey = "#6b6b6b",
}
