import { Injectable } from "@angular/core";
import * as THREE from "three";
import { ShapeGeneratorService } from "../services/shape-generator/shape-generator.service";
import { setFaceTypeForShape } from "src/utils/shape-facetype";
import { Subject } from "rxjs";
import { initialOrientation, Object3DUserData } from "~/src/utils/shape";
import { ActionHelperService } from "../action-helper.service";
import { FaceIdentifierService } from "./face-identifier/face-identifier.service";
import { ShapeValidationService } from "../services/shape-validation.service";
import { Face, FaceType } from "../../../../utils/count-calculator/count-calculator.model";
import { Intersection, Object3D } from "three";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DynamicGenerateShape } from "./face-identifier/face-identifier.model";
import { AlteredShapeConfiguration } from "../../../../utils/shape";
import { MeshType } from "../../utils/shape-type";

@Injectable({
    providedIn: "root",
})
export class GenerateNewShapes {
    private isNewShapeGeneratedSubject = new Subject<boolean>();
    public isNewShapeGenerated$ = this.isNewShapeGeneratedSubject.asObservable();
    public intersect!: Intersection<Object3D<THREE.Event>>;
    private isCurvedFace = false;
    private object: THREE.Mesh = new THREE.Mesh();

    constructor(
        private shapeGeneratorService: ShapeGeneratorService,
        private faceIdentifierService: FaceIdentifierService,
        private shapeValidationService: ShapeValidationService,
        private snackBar: MatSnackBar,
    ) {}

    private markAsGenerated(): void {
        this.isNewShapeGeneratedSubject.next(true);
    }

    public generateNewShapes(position: THREE.Vector3, shape: MeshType, color: THREE.Color): THREE.Mesh {
        let boundingBox = new THREE.Box3(); // To store the bounding box of the object

        // Generate shape based on the provided type
        if (Object.values(MeshType).includes(shape)) {
            this.object = this.shapeGeneratorService.generateShape(shape, color, false);
        }

        // Get the bounding box of the object
        this.object.geometry.computeBoundingBox();
        boundingBox.setFromObject(this.object);

        // Get the center of the bounding box
        const boundingBoxCenter = boundingBox.getCenter(new THREE.Vector3());
        switch (this.object.name) {
            case MeshType.HalfCylinder:
                boundingBoxCenter.y = boundingBox.max.y;
                break;
            case MeshType.HemiSphere:
                boundingBoxCenter.y = boundingBox.max.y;
                break;
            default:
                break;
        }

        // Calculate the offset to move the object such that its bounding box center aligns with the provided position
        const offset = new THREE.Vector3(
            position!.x - boundingBoxCenter.x,
            position!.y - boundingBoxCenter.y,
            position!.z - boundingBoxCenter.z,
        );
        this.object.position.add(offset);

        // User data for the shape
        const faceType = setFaceTypeForShape(this.object.name as MeshType);
        if (Object.keys(faceType).length > 0) {
            this.object.userData[Object3DUserData.faceType] = faceType;
        }

        // Mark the object's orientation data
        this.object.userData[Object3DUserData.orientationData] = initialOrientation;

        let meshRealFace;
        if (this.faceIdentifierService.state.frontFace) {
            meshRealFace = this.getOrientationFace(0);
            this.isCurvedFace = this.checkCurvedFaceAndHalfPlane(Face.Back, meshRealFace, this.object)!;
        } else if (this.faceIdentifierService.state.rightFace) {
            meshRealFace = this.getOrientationFace(1);
            this.isCurvedFace = this.checkCurvedFaceAndHalfPlane(Face.Left, meshRealFace, this.object)!;
        } else if (this.faceIdentifierService.state.backFace) {
            meshRealFace = this.getOrientationFace(2);
            this.isCurvedFace = this.checkCurvedFaceAndHalfPlane(Face.Front, meshRealFace, this.object)!;
        } else if (this.faceIdentifierService.state.leftFace) {
            meshRealFace = this.getOrientationFace(3);
            this.isCurvedFace = this.checkCurvedFaceAndHalfPlane(Face.Right, meshRealFace, this.object)!;
        } else if (this.faceIdentifierService.state.topFace) {
            meshRealFace = this.getOrientationFace(4);
            this.isCurvedFace = this.checkCurvedFaceAndHalfPlane(Face.Bottom, meshRealFace, this.object)!;
        } else if (this.faceIdentifierService.state.bottomFace) {
            meshRealFace = this.getOrientationFace(5);
            this.isCurvedFace = this.checkCurvedFaceAndHalfPlane(Face.Top, meshRealFace, this.object)!;
        }

        if (this.isCurvedFace) {
            this.checkValidation(this.object);
        }

        // Notify that the shape has been generated
        this.markAsGenerated();

        return this.object;
    }

    private checkCurvedFaceAndHalfPlane(
        oppositeFace: Face,
        meshRealFace: Face | null,
        object: THREE.Mesh,
    ): boolean | null {
        const selectedShapeFaceType = setFaceTypeForShape(object.name);
        const objectHit = this.faceIdentifierService.objectHit;

        const generateShapeName = object.name;
        const objectHitName = objectHit.name;

        if (generateShapeName === objectHitName && selectedShapeFaceType[meshRealFace!] === FaceType.HalfPlane) {
            this.object.rotation.set(objectHit.rotation.x, objectHit.rotation.y, objectHit.rotation.z);
            this.object.userData[Object3DUserData.orientationData] =
                objectHit.userData[Object3DUserData.orientationData];
            if (generateShapeName === MeshType.HalfCylinder) {
                this.object.position.set(objectHit.position.x, objectHit.position.y, objectHit.position.z);
                if (this.faceIdentifierService.state.rightFace) {
                    this.object.position.z = this.object.position.z - AlteredShapeConfiguration.standardHeigthOfShape;
                    this.object.position.y = this.object.position.y;
                } else if (this.faceIdentifierService.state.leftFace) {
                    this.object.position.z = this.object.position.z + AlteredShapeConfiguration.standardHeigthOfShape;
                    this.object.position.y = this.object.position.y;
                } else if (this.faceIdentifierService.state.topFace) {
                    this.object.position.y = this.object.position.y + AlteredShapeConfiguration.standardHeigthOfShape;
                } else if (this.faceIdentifierService.state.bottomFace) {
                    this.object.position.y = this.object.position.y - AlteredShapeConfiguration.standardHeigthOfShape;
                } else if (this.faceIdentifierService.state.frontFace) {
                    this.object.position.x = this.object.position.x + DynamicGenerateShape.AdjustmentConstant;
                    this.object.position.y = this.object.position.y;
                } else if (this.faceIdentifierService.state.backFace) {
                    this.object.position.x = this.object.position.x - DynamicGenerateShape.AdjustmentConstant;
                    this.object.position.y = this.object.position.y;
                }
            }
            return false;
        }

        if (
            selectedShapeFaceType[oppositeFace] === FaceType.Curved ||
            selectedShapeFaceType[oppositeFace] === FaceType.HalfPlane
        ) {
            return true;
        }
        return false;
    }

    private getOrientationFace(index: number): Face | null {
        return this.faceIdentifierService.objectHit?.userData?.[Object3DUserData.orientationData]?.[index] ?? null;
    }

    public checkValidation(object: THREE.Mesh): void {
        this.shapeValidationService.validateAndFlipSequentially(object);
    }
}
