import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, UrlTree, Router } from "@angular/router";
import { BehaviorSubject, Observable, catchError, map, of } from "rxjs";
import { LocalStorageService } from "../local-storage/local-storage.service";
import { AdminService } from "../admin/admin.service";
import { RouteTrackingService } from "../route-tracking/route-tracking.service";
import { UserService } from "../user/user.service";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    public isLoggedIn = new BehaviorSubject<boolean>(false);
    public permissionData: string[] = [];

    constructor(
        private router: Router,
        private localStorageService: LocalStorageService,
        private adminService: AdminService,
        private routeTrackingService: RouteTrackingService,
        private userService: UserService,
    ) {}

    public hasPermission(permission: string[]): Observable<boolean> {
        return this.adminService.setUserPermissions().pipe(
            map((permissions: string[]) => permission.some((permission) => permissions.includes(permission))),
            catchError((error) => {
                return of(false); // or handle error accordingly
            }),
        );
    }

    public isAdminLoggedIn(): boolean {
        return !!this.adminToken;
    }

    public get isUserLoggedIn(): boolean {
        return !!this.userToken;
    }

    private get userToken(): string | null {
        return localStorage.getItem("token");
    }

    private get adminToken(): string | null {
        return localStorage.getItem("adminToken");
    }

    public removeUserToken(): void {
        localStorage.removeItem("token");
    }

    public removeAdminToken(): void {
        localStorage.removeItem("adminToken");
    }

    public logout(isAdmin: boolean = false): void {
        if (isAdmin) {
            localStorage.removeItem("adminToken");
            this.router.navigate(["/admin/login"]);
        } else {
            localStorage.removeItem("token");
            this.localStorageService.removeAllCartItems();
            this.localStorageService.removeAllWishListItems();
            sessionStorage.clear();
            this.router.navigate(["/home"]);
        }
    }

    public handleAdminAccess(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        if (!this.isAdminLoggedIn()) {
            return of(this.router.parseUrl("/admin/login"));
        }

        const requiredPermission = route.data["permission"];
        if (requiredPermission) {
            return this.hasPermission(requiredPermission).pipe(
                map((hasPermission) => {
                    if (!hasPermission) {
                        return this.router.parseUrl("/admin/noAccess");
                    }
                    return true;
                }),
            );
        }

        return of(true);
    }

    public handleUserAccess(targetUrl: string): Observable<boolean | UrlTree> {
        // If not logged in, always redirect to login
        if (!this.isUserLoggedIn) {
            this.routeTrackingService.setIntendedUrl(targetUrl);
            return of(this.router.parseUrl("/login"));
        }

        // Special handling for user details page
        if (targetUrl === "/userDetails") {
            return this.userService.isUserDetailsExist().pipe(
                map((exists) => {
                    // If details exist, redirect to profile
                    if (exists) {
                        return this.router.parseUrl("/profile");
                    }

                    return true;
                }),
                catchError(() => {
                    return of(true);
                }),
            );
        }
        return of(true);
    }
}
