import { Injectable } from "@angular/core";
import * as THREE from "three";
import { ShapeResourceManagerService } from "../../shared/helpers/shape-resource-manager.service";
import { CanvasConfig } from "~/src/utils/canvas-configuration";
import { MeshType } from "~/src/utils/shape-type";

@Injectable({
    providedIn: "root",
})
export class ShapeGeneratorService {
    constructor(private shapeResourceManagerService: ShapeResourceManagerService) {}
    /*
    This is the generic method which creates the shape for all the type
    */
    public generateShape(type: MeshType, color: THREE.ColorRepresentation, isForSelector: boolean): THREE.Mesh {
        const geometry = this.shapeResourceManagerService.getGeometry(type, isForSelector);
        const material = new THREE.MeshBasicMaterial({
            color: color as THREE.ColorRepresentation,
            side: THREE.DoubleSide,
            transparent: isForSelector ? true : false,
            opacity: isForSelector ? CanvasConfig.transparentOpacity : CanvasConfig.solidOpacity,
        });

        const shape = new THREE.Mesh(geometry, material);
        shape.name = type;

        this.shapeResourceManagerService.addOutlineToMesh(shape, isForSelector);

        const offsetCoordinates = this.shapeResourceManagerService.getOffsetCoordinatesForSelector(type);

        if (offsetCoordinates && isForSelector) {
            shape.position.copy(offsetCoordinates);
        }

        return shape;
    }
}
