import { Injectable } from "@angular/core";
import * as THREE from "three";
import { ActionHelperService } from "../action-helper.service";
import { FaceIdentifierService } from "../shared/face-identifier/face-identifier.service";
import { Face, FaceType } from "../../../../utils/count-calculator/count-calculator.model";
import { Object3DUserData } from "../../utils/shape";
import { MeshType } from "~/src/utils/shape-type";

@Injectable({
    providedIn: "root",
})
export class ShapeValidationService {
    private isCurvedFace = false;
    private isValidationFlip = false;

    constructor(
        private actionHelperService: ActionHelperService,
        private faceIdentifierService: FaceIdentifierService,
    ) {}

    public async validateAndFlipSequentially(object: THREE.Mesh, maxIterations: number = 100): Promise<boolean> {
        this.isValidationFlip = true;
        let flipSequence: any;

        if (this.faceIdentifierService.state.rightFace || this.faceIdentifierService.state.backFace) {
            flipSequence = [
                () => this.actionHelperService.flipTop(object),
                () => this.actionHelperService.flipRight(object),
                () => this.actionHelperService.flipLeft(object),
                () => this.actionHelperService.flipBack(object),
                () => this.actionHelperService.flipFront(object),
            ];
        } else if (this.faceIdentifierService.state.leftFace) {
            flipSequence = [
                () => this.actionHelperService.flipTop(object),
                () => this.actionHelperService.flipLeft(object),
                () => this.actionHelperService.flipLeft(object),
                () => this.actionHelperService.flipLeft(object),
                () => this.actionHelperService.flipLeft(object),
            ];
        } else if (this.faceIdentifierService.state.frontFace) {
            flipSequence = [
                () => this.actionHelperService.flipTop(object),
                () => this.actionHelperService.flipFront(object),
                () => this.actionHelperService.flipFront(object),
                () => this.actionHelperService.flipFront(object),
                () => this.actionHelperService.flipFront(object),
            ];
        } else if (this.faceIdentifierService.state.bottomFace) {
            if (object.name === MeshType.Prism || object.name === MeshType.CurvedCube2) {
                flipSequence = [
                    () => this.actionHelperService.flipFront(object),
                    () => this.actionHelperService.flipLeft(object),
                ];
            } else {
                flipSequence = [
                    () => this.actionHelperService.flipLeft(object),
                    () => this.actionHelperService.flipTop(object),
                ];
            }
        }

        for (let iteration = 0; iteration < maxIterations; iteration++) {
            for (const flip of flipSequence) {
                await flip(); // Execute the flip

                if (this.faceIdentifierService.state.frontFace) {
                    this.isCurvedFace = this.checkCurvedFace(Face.Back, object);
                } else if (this.faceIdentifierService.state.rightFace) {
                    this.isCurvedFace = this.checkCurvedFace(Face.Left, object);
                } else if (this.faceIdentifierService.state.backFace) {
                    this.isCurvedFace = this.checkCurvedFace(Face.Front, object);
                } else if (this.faceIdentifierService.state.leftFace) {
                    this.isCurvedFace = this.checkCurvedFace(Face.Right, object);
                } else if (this.faceIdentifierService.state.topFace) {
                    this.isCurvedFace = this.checkCurvedFace(Face.Bottom, object);
                } else if (this.faceIdentifierService.state.bottomFace) {
                    this.isCurvedFace = this.checkCurvedFace(Face.Top, object);
                }

                // Check validity immediately after flip
                if (!this.isCurvedFace) {
                    this.isValidationFlip = false;
                    return true;
                }
            }
        }
        this.isValidationFlip = false;

        return false;
    }

    private checkCurvedFace(oppositeFace: Face, object: THREE.Mesh): boolean {
        const oreintationData = object.userData[Object3DUserData.orientationData];
        let meshRealFace = "";
        let meshRealFaceType = "";
        if (oppositeFace === Face.Front) {
            meshRealFace = oreintationData[0];
            meshRealFaceType = object.userData[Object3DUserData.faceType][meshRealFace];
        } else if (oppositeFace === Face.Right) {
            meshRealFace = oreintationData[1];
            meshRealFaceType = object.userData[Object3DUserData.faceType][meshRealFace];
        } else if (oppositeFace === Face.Back) {
            meshRealFace = oreintationData[2];
            meshRealFaceType = object.userData[Object3DUserData.faceType][meshRealFace];
        } else if (oppositeFace === Face.Left) {
            meshRealFace = oreintationData[3];
            meshRealFaceType = object.userData[Object3DUserData.faceType][meshRealFace];
        } else if (oppositeFace === Face.Top) {
            meshRealFace = oreintationData[4];
            meshRealFaceType = object.userData[Object3DUserData.faceType][meshRealFace];
        } else if (oppositeFace === Face.Bottom) {
            meshRealFace = oreintationData[5];
            meshRealFaceType = object.userData[Object3DUserData.faceType][meshRealFace];
        }

        if (meshRealFaceType === FaceType.Curved || meshRealFaceType === FaceType.HalfPlane) {
            return true;
        }
        return false;
    }
    public isValidationActive(): boolean {
        return this.isValidationFlip;
    }
}
