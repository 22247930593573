import { Component, OnDestroy, OnInit } from "@angular/core";
import * as THREE from "three";
import { GenerateNewShapes } from "../shared/generate-new-shapes";
import { AlteredShapeConfiguration, Object3DUserData, selectedShapeColor } from "@utils/shape";
import { ComponentInteractionSrevice } from "../services/component-interaction/component-interaction.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HelperService } from "../shared/helpers/helper.service";
import { Vector2 } from "three";
import { DynamicGenerateShape } from "../shared/face-identifier/face-identifier.model";
import { MatSnackBarMessages } from "../shared/mat-snackbar/mat-snackbar.model";
import { FaceIdentifierService } from "../shared/face-identifier/face-identifier.service";
import { tiltedShapeConfiguration } from "@utils/shape-facetype";
import { Subscription } from "rxjs";
import { StructureService } from "../services/structure/structure.service";
import { CanvasConfig } from "../../../../utils/canvas-configuration";
import { InteractionService } from "../shared/helpers/interaction.service";
import { AuxiliaryObjectType, MeshType } from "~/src/utils/shape-type";
import { SceneManagerService } from "../services/scene-manager/scene-manager.service";
import { SnackBarConfig } from "../constants/snackbar.constants";
import { environment } from "~/src/environments/environment";

@Component({
    selector: "generate-shape",
    templateUrl: "./generate-shape.component.html",
    styleUrls: ["./generate-shape.component.scss"],
})
export class GenerateShapeComponent implements OnInit, OnDestroy {
    private meshes: THREE.Mesh[] = [];
    private intersects: THREE.Intersection<THREE.Object3D<THREE.Event>>[] = [];
    private selectedShape: MeshType | null = null;
    private coOrdinates: THREE.Vector3 = new THREE.Vector3();
    private locationX = 0;
    private locationY = 0;
    private locationZ = 0;
    private nearestGridCords: THREE.Vector2 = new THREE.Vector2();
    private invalidJoin = false;
    private invalidPointGeneration = false;
    private generatedShape: THREE.Mesh<THREE.BufferGeometry, THREE.Material | THREE.Material[]> = new THREE.Mesh();
    public selectedColor: THREE.ColorRepresentation | undefined;
    public isTilted!: boolean;
    private interceptInfoSub!: Subscription;
    private selectedShapeSub!: Subscription;
    private selectedColorSub!: Subscription;

    constructor(
        private generateNewShapes: GenerateNewShapes,
        private componetInteractionSrv: ComponentInteractionSrevice,
        private snackBar: MatSnackBar,
        private helperService: HelperService,
        private faceIdentifierSrv: FaceIdentifierService,
        private interactionService: InteractionService,
        private sceneManagerService: SceneManagerService,
    ) {}

    ngOnInit(): void {
        this.interceptInfoSub = this.componetInteractionSrv.getInterceptInfo().subscribe((intersects) => {
            if (this.sceneManagerService.getMeshes().length >= environment.MAX_MESHES) {
                this.snackBar.open(MatSnackBarMessages.MESH_LIMIT_REACHED, SnackBarConfig.Actions.OKAY, {
                    duration: SnackBarConfig.Duration.MEDIUM,
                });
                return;
            }
            this.invalidJoin = false;
            this.invalidPointGeneration = false;
            this.intersects = intersects;
            if (this.intersects[0]) {
                this.addShapeOnPlane();
            }
        });
        this.selectedShapeSub = this.componetInteractionSrv.getSeletedShape().subscribe((shape) => {
            this.selectedShape = shape;
            this.invalidJoin = false;
        });
        this.selectedColorSub = this.componetInteractionSrv.getShapeColor().subscribe((color) => {
            this.selectedColor = color;
        });
    }

    private addShapeOnPlane(): void {
        const hitObject = this.intersects[0].object as THREE.Mesh;
        let point = this.intersects[0].point;
        const isGridNotAvailable = this.interactionService.checkIsGridNotAvailable(hitObject, point);

        if (isGridNotAvailable) {
            this.snackBar.open(MatSnackBarMessages.ErrorMessage5, SnackBarConfig.Actions.CLOSE, {
                duration: SnackBarConfig.Duration.SHORT,
            });
            return;
        }

        let boundingBox = new THREE.Box3();
        hitObject.geometry.computeBoundingBox();

        if (hitObject.name === MeshType.HalfCylinder || hitObject.name === MeshType.HemiSphere) {
            boundingBox = hitObject.geometry.boundingBox as THREE.Box3;
        } else {
            boundingBox.setFromObject(hitObject);
        }

        const boundingBoxCenter = boundingBox.getCenter(new THREE.Vector3());

        switch (hitObject.name) {
            case MeshType.HalfCylinder:
                boundingBoxCenter.y = boundingBox.max.y;
                boundingBoxCenter.applyMatrix4(hitObject.matrixWorld);
                break;
            case MeshType.HemiSphere:
                boundingBoxCenter.y = boundingBox.max.y;
                boundingBoxCenter.applyMatrix4(hitObject.matrixWorld);
                break;
            default:
                break;
        }

        let objectName = this.intersects[0].object.name;
        if (objectName === AuxiliaryObjectType.Plane) {
            this.locationX = point.x; // GIVES THE POINT OF INTERSECTION IN WORLD COORDS
            this.locationY = point.y;
            this.locationZ = point.z;

            // Here we make the shape sit on the grid avoiding them to go inside.
            this.locationY = this.helperService.getGroundLocationY(this.locationY);
        } else {
            this.locationX = point.x; // GIVES THE POINT OF INTERSECTION IN WORLD COORDS
            this.locationY = point.y;
            this.locationZ = point.z;

            if (this.faceIdentifierSrv.invalidPointOfGeneration) {
                this.invalidPointGeneration = true;
            } else if (this.faceIdentifierSrv.state.rightFace) {
                if (this.faceIdentifierSrv.invalidJoin) {
                    this.invalidJoin = true;
                } else {
                    this.invalidJoin = false;
                    this.invalidPointGeneration = false;
                    this.locationZ = this.locationZ - DynamicGenerateShape.AdjustmentConstant;
                    this.locationY = boundingBoxCenter.y;
                }
            } else if (this.faceIdentifierSrv.state.leftFace) {
                if (this.faceIdentifierSrv.invalidJoin) {
                    this.invalidJoin = true;
                } else {
                    this.invalidJoin = false;
                    this.invalidPointGeneration = false;
                    this.locationZ = this.locationZ + DynamicGenerateShape.AdjustmentConstant;
                    this.locationY = boundingBoxCenter.y;
                }
            } else if (this.faceIdentifierSrv.state.topFace) {
                if (this.faceIdentifierSrv.invalidJoin) {
                    this.invalidJoin = true;
                } else {
                    this.invalidJoin = false;
                    this.invalidPointGeneration = false;
                    this.locationY = boundingBoxCenter.y + AlteredShapeConfiguration.standardHeigthOfShape;
                }
            } else if (this.faceIdentifierSrv.state.bottomFace) {
                if (this.faceIdentifierSrv.invalidJoin) {
                    this.invalidJoin = true;
                } else {
                    this.invalidJoin = false;
                    this.invalidPointGeneration = false;
                    this.locationY = boundingBoxCenter.y - AlteredShapeConfiguration.standardHeigthOfShape;
                }
            } else if (this.faceIdentifierSrv.state.frontFace) {
                if (this.faceIdentifierSrv.invalidJoin) {
                    this.invalidJoin = true;
                } else {
                    this.invalidJoin = false;
                    this.invalidPointGeneration = false;
                    this.locationX = this.locationX + DynamicGenerateShape.AdjustmentConstant;
                    this.locationY = boundingBoxCenter.y;
                }
            } else if (this.faceIdentifierSrv.state.curveFace) {
                if (this.faceIdentifierSrv.invalidJoin) {
                    this.invalidJoin = true;
                } else {
                    this.invalidJoin = false;
                    this.invalidPointGeneration = false;
                    this.locationY = boundingBoxCenter.y;
                }
            } else if (this.faceIdentifierSrv.state.backFace && !this.faceIdentifierSrv.state.topFace) {
                if (this.faceIdentifierSrv.invalidJoin) {
                    this.invalidJoin = true;
                } else {
                    this.invalidJoin = false;
                    this.invalidPointGeneration = false;
                    this.locationX = this.locationX - DynamicGenerateShape.AdjustmentConstant;
                    this.locationY = boundingBoxCenter.y;
                }
            } else if (this.faceIdentifierSrv.invalidJoin) {
                this.invalidJoin = true;
            }
        }

        this.nearestGridCords = this.helperService.getNearestGridCords(new Vector2(this.locationX, this.locationZ));

        this.coOrdinates = new THREE.Vector3(this.nearestGridCords.x, this.locationY, this.nearestGridCords.y);

        let selectedShapeColour = this.selectedColor ? this.selectedColor : selectedShapeColor.code;

        if (
            this.coOrdinates.z >= -CanvasConfig.maxMinConstant &&
            this.coOrdinates.z <= CanvasConfig.maxMinConstant &&
            this.coOrdinates.x >= -CanvasConfig.maxMinConstant &&
            this.coOrdinates.x <= CanvasConfig.maxMinConstant
        ) {
            if (this.invalidJoin) {
                this.snackBar.open(MatSnackBarMessages.ErrorMessage2, SnackBarConfig.Actions.CLOSE, {
                    duration: SnackBarConfig.Duration.SHORT,
                });
            } else if (this.invalidPointGeneration) {
                this.snackBar.open(MatSnackBarMessages.ErrorMessage3, SnackBarConfig.Actions.CLOSE, {
                    duration: SnackBarConfig.Duration.SHORT,
                });
            } else if (!this.invalidJoin && this.selectedShape && !this.invalidPointGeneration) {
                // This is where generation happens
                this.generateShape(this.coOrdinates, this.selectedShape, selectedShapeColour);
            } else {
                this.snackBar.open(MatSnackBarMessages.ErrorMessage1, SnackBarConfig.Actions.CLOSE, {
                    duration: SnackBarConfig.Duration.SHORT,
                });
            }
        } else {
            this.snackBar.open(MatSnackBarMessages.ErrorMessage4, SnackBarConfig.Actions.CLOSE, {
                duration: SnackBarConfig.Duration.SHORT,
            });
        }
    }

    private generateShape(
        coOrdinates: THREE.Vector3,
        shapeType: MeshType,
        shapeColor: THREE.Color | string | number,
    ): void {
        this.handlegenerationOfShapes(coOrdinates, shapeType, shapeColor);
        const { row, column, cell } = this.helperService.calculateRowAndColumn(this.generatedShape as THREE.Mesh);
        this.generatedShape.userData[Object3DUserData.colour] = shapeColor;
        this.generatedShape.userData[Object3DUserData.initialPosition] = new THREE.Vector3();
        this.generatedShape.userData[Object3DUserData.initialPosition].copy(this.generatedShape.position);

        this.generatedShape.userData[Object3DUserData.isDraggable] = true;
        this.generatedShape.userData[Object3DUserData.initialMaterial] = this.generatedShape.material;
        this.generatedShape.userData[Object3DUserData.isInCollision] = false;
        this.generatedShape.userData[Object3DUserData.collidedObjectId] = ""; // initially we wont be having any collided object
        const cellReference = {
            row: row,
            column: column,
            cell: cell,
        };
        this.generatedShape.userData[Object3DUserData.cellReference] = cellReference;
        this.componetInteractionSrv.setGeneratedShapeInfo(this.generatedShape);
    }

    private handlegenerationOfShapes(coOrdinates: THREE.Vector3, shapeType: MeshType, shapeColor: any): void {
        const objectHit = this.faceIdentifierSrv.objectHit;
        const generateAndTiltShape = (offsetX: number, offsetY: number, rotation: number) => {
            if (objectHit) {
                coOrdinates.x = objectHit.position.x + offsetX;
                coOrdinates.y = objectHit.position.y + offsetY;
            }
            this.generatedShape = this.generateNewShapes.generateNewShapes(coOrdinates, shapeType, shapeColor);
            this.generatedShape.rotateZ(rotation);
            this.generatedShape.userData[Object3DUserData.isTilted] = true;
            this.generatedShape.userData[Object3DUserData.initialPosition] = this.generatedShape.position;
            this.generatedShape.userData[Object3DUserData.rotationData];
        };

        if (objectHit && objectHit.name === MeshType.Cube && objectHit.userData[Object3DUserData.isTilted]) {
            const offset = {
                x: 0,
                y: tiltedShapeConfiguration.defaultOffsetY,
                rotation: 0,
            };

            if (this.faceIdentifierSrv.state.rightFace) {
                generateAndTiltShape(
                    tiltedShapeConfiguration.offsetY,
                    offset.y,
                    tiltedShapeConfiguration.rotationforRightAndLeftFace,
                );
            } else if (this.faceIdentifierSrv.state.leftFace) {
                generateAndTiltShape(
                    -tiltedShapeConfiguration.offsetY,
                    offset.y,
                    -tiltedShapeConfiguration.rotationforRightAndLeftFace,
                );
            } else if (this.faceIdentifierSrv.state.topFace) {
                generateAndTiltShape(
                    objectHit.rotation.z === tiltedShapeConfiguration.rotationforRightAndLeftFace
                        ? -tiltedShapeConfiguration.offsetX
                        : tiltedShapeConfiguration.offsetX,
                    tiltedShapeConfiguration.offsetY,
                    objectHit.rotation.z,
                );
            } else if (this.faceIdentifierSrv.state.bottomFace) {
                generateAndTiltShape(
                    objectHit.rotation.z === tiltedShapeConfiguration.rotationforRightAndLeftFace
                        ? tiltedShapeConfiguration.offsetX
                        : -tiltedShapeConfiguration.offsetX,
                    -tiltedShapeConfiguration.offsetY,
                    objectHit.rotation.z,
                );
            } else if (this.faceIdentifierSrv.state.frontFace || this.faceIdentifierSrv.state.backFace) {
                generateAndTiltShape(0, 0, objectHit.rotation.z);
            }
        } else {
            this.generatedShape = this.generateNewShapes.generateNewShapes(coOrdinates, shapeType, shapeColor);
        }
    }

    ngOnDestroy(): void {
        this.interceptInfoSub.unsubscribe();
        this.selectedShapeSub.unsubscribe();
        this.selectedColorSub.unsubscribe();
    }
}
