import { Injectable } from "@angular/core";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter.js";
import { Subject, Observable, firstValueFrom } from "rxjs";
import { AlteredShapeConfiguration, Object3DUserData } from "../../../../../utils/shape";
import { S3UploadService } from "../s3-upoad/s3-upload.service";
import { FilenameService } from "../filename/filename.service";
import { HelperService } from "../../shared/helpers/helper.service";
import { CellReference } from "@models/mesh.model";
import { StructureResponseMessage } from "@models/structure.model";
import { AuxiliaryObjectType, MeshType, ShapeType } from "~/src/utils/shape-type";
import { ColorCodes } from "~/src/utils/color-constants";
import { SetShapeUserDataService } from "../../shared/helpers/set-shape-userdata.service";
import { StructureService } from "../structure/structure.service";
import { ShapeResourceManagerService } from "../../shared/helpers/shape-resource-manager.service";

@Injectable({
    providedIn: "root",
})
export class SceneManagerService {
    public scene: THREE.Scene = new THREE.Scene();
    private loader: GLTFLoader;
    private exporter: GLTFExporter;
    public isSaveAsGLB: boolean = false;

    private objectsSubject: Subject<THREE.Object3D[]> = new Subject<THREE.Object3D[]>();
    public objects$: Observable<THREE.Object3D[]> = this.objectsSubject.asObservable();

    private geometriesData: any[] = [];
    private materialCache: Map<string, THREE.Material>;

    constructor(
        private helperService: HelperService,
        private filenameService: FilenameService,
        private s3UploadService: S3UploadService,
        private setShapeUserDataService: SetShapeUserDataService,
        private structureService: StructureService,
        private shapeResourceManagerService: ShapeResourceManagerService,
    ) {
        this.loader = new GLTFLoader();
        this.exporter = new GLTFExporter();
        this.materialCache = new Map();
    }
    /**
     * Set the Three.js scene where objects will be added
     * @param scene - The Three.js scene
     */
    public setScene(scene: THREE.Scene): void {
        this.scene = scene;
        this.objectsSubject.next(this.scene.children);
    }

    /**
     * Get the current Three.js scene
     * @returns scene - The Three.js scene
     */
    public getScene(): THREE.Scene {
        return this.scene;
    }

    /**
     * Get all meshes in the scene, excluding Plane and other objects.
     * @returns Array of meshes in the scene excluding plane and other objects.
     */
    public getMeshes(): THREE.Mesh[] {
        if (!this.scene) {
            return [];
        }

        // Filter the scene's children to return only meshes that are not of type Plane or Light
        return this.scene.children
            .filter((child) => child instanceof THREE.Mesh && child.name !== AuxiliaryObjectType.Plane)
            .map((child) => child as THREE.Mesh);
    }

    /**
     * Add objects to the scene and update the scene state
     * @param objects - Objects to add to the scene
     */
    public addObjectsToScene(objects: THREE.Object3D[]): void {
        this.scene.add(...objects);
        this.objectsSubject.next(this.scene.children);
    }

    /**
     * Removes all meshes from the scene except the plane
     */
    public removeAllMeshesFromScene(): void {
        this.scene.children = this.scene.children.filter(
            (child) => !(child instanceof THREE.Mesh) || child.name === AuxiliaryObjectType.Plane,
        );
        this.objectsSubject.next(this.scene.children);
    }

    /**
     * Removes selected meshes from the scene
     */
    public removeMeshesFromScene(deletingMeshes: THREE.Mesh[]): THREE.Mesh[] {
        let existingMeshes: THREE.Mesh[] = this.getMeshes();

        if (!existingMeshes.length) {
            return [];
        }

        // Convert deletingMeshes to a Set for faster lookups
        const deletingMeshesSet = new Set(deletingMeshes.map((mesh) => mesh.uuid));

        // Loop through and remove meshes
        existingMeshes = existingMeshes.filter((mesh) => {
            // If the shape is in deletingMeshes, remove it
            if (deletingMeshesSet.has(mesh.uuid)) {
                // Clean up resources before removal
                this.cleanupMeshResources(mesh);
                // Remove the mesh from the scene and clean up user data
                this.setShapeUserDataService.clearAdjacentDataOnDragStart(mesh, existingMeshes);
                this.scene.remove(mesh);
                // this.sceneSubject.next(this.scene);
                this.objectsSubject.next(this.scene.children);

                return false; // Don't keep the mesh
            }

            return true; // Keep the mesh
        });

        // Iterate through remaining meshes to update face types
        const FaceType = {
            Right: "Right",
            Left: "Left",
            Top: "Top",
            Bottom: "Bottom",
            Front: "Front",
            Back: "Back",
        };

        existingMeshes.forEach((mesh) => {
            const adjacentData = mesh.userData[Object3DUserData.adjacentData];
            if (adjacentData) {
                Object.keys(FaceType).forEach((faceTypeName) => {
                    const adjacentShapeUUID = adjacentData[faceTypeName];
                    if (deletingMeshesSet.has(adjacentShapeUUID)) {
                        mesh.userData[Object3DUserData.faceType][faceTypeName] = "Curved"; // Or "Plane" logic
                    }
                });
            }
        });

        return existingMeshes;
    }

    /**
     * Cleans up mesh resources to prevent memory leaks
     */
    private cleanupMeshResources(mesh: THREE.Mesh): void {
        // Cleanup child elements
        mesh.children.forEach((child) => {
            if (child instanceof THREE.LineSegments || child instanceof THREE.Mesh) {
                if (child.geometry) {
                    child.geometry.dispose();
                }
                if (child.material) {
                    if (Array.isArray(child.material)) {
                        child.material.forEach((material) => material.dispose());
                    } else {
                        (child.material as THREE.Material).dispose();
                    }
                }
            }
        });

        // Cleanup main mesh
        if (mesh.geometry) {
            mesh.geometry.dispose();
        }
        if (mesh.material) {
            if (Array.isArray(mesh.material)) {
                mesh.material.forEach((material) => material.dispose());
            } else {
                (mesh.material as THREE.Material).dispose();
            }
        }
    }

    /**
     * Filter meshes in the scene for export
     * @returns An array of meshes to export
     */
    private getMeshesToExport(): THREE.Mesh[] {
        return this.scene.children.filter(
            (child) => child instanceof THREE.Mesh && child.name !== "plane",
        ) as THREE.Mesh[];
    }

    /**
     * Export the scene and upload it to S3
     * @param categoryName - S3 category for the upload
     * @param fileType - The file type (e.g., 'images', 'videos', 'glb-files')
     * @param filename - Optional filename
     * @returns The uploaded URL
     */
    public exportToS3(categoryName: string, fileType: string, filename?: string): Promise<string> {
        return new Promise((resolve, reject) => {
            const finalFilename = this.filenameService.generateTimestampFileName(filename || "scene", "glb");
            const meshesToExport = this.getMeshesToExport();
            const exportOptions = { trs: false, onlyVisible: true, binary: true };

            this.exporter.parse(
                meshesToExport,
                async (gltf) => {
                    if (gltf instanceof ArrayBuffer) {
                        try {
                            const file = new File([gltf], finalFilename, { type: "model/gltf-binary" });
                            const uploadedUrls = await this.s3UploadService.multipartOperation(
                                [file],
                                categoryName,
                                fileType,
                            );
                            resolve(uploadedUrls[0]);
                        } catch (error) {
                            reject(error);
                        }
                    } else {
                        reject(new Error("Unexpected export result"));
                    }
                },
                reject,
                exportOptions,
            );
        });
    }

    /**
     * Extracts the file extension from a given URL.
     *
     * This function splits the URL by the dot (`.`) character and returns the last segment as the file extension,
     * converting it to lowercase to ensure case-insensitivity. If no valid extension is found, an empty string is returned.
     *
     * @param url - The URL from which to extract the file extension.
     * @returns The file extension in lowercase, or an empty string if no extension is found.
     */
    public getFileExtension(url: string): string {
        const extension = url.split(".").pop()?.toLowerCase();
        return extension ?? "";
    }

    /**
     * Load a GLTF/GLB file and add its children to the scene
     * @param glbUrl - URL or file path to the GLTF/GLB file
     */
    public loadFromGlbUrl(glbUrl: string, isUpdated: boolean = false): Promise<void> {
        return new Promise((resolve, reject) => {
            this.loader.load(
                glbUrl,
                (gltf) => {
                    this.cleanAndProcessScene(gltf.scene);
                    this.scene.add(...gltf.scene.children);
                    this.objectsSubject.next(this.scene.children);
                    this.recalculateCellReferences(isUpdated);
                    resolve();
                },
                this.onProgress.bind(this),
                (error) => {
                    reject(error);
                },
            );
        });
    }

    /**
     * Logs loading progress
     * @param xhr - XMLHttpRequest object
     */
    private onProgress(xhr: ProgressEvent): void {
        // Progress handler
        if (xhr.lengthComputable) {
            const percentComplete = (xhr.loaded / xhr.total) * 100;
        }
    }

    /**
     * Clean up mesh names and process userdata recursively
     * @param parent - Parent object in the scene (e.g., Mesh or Group)
     */
    private cleanAndProcessScene(group: THREE.Group): void {
        group.children.map((mesh) => {
            if (mesh instanceof THREE.Mesh) {
                this.cleanMeshNames(mesh);
                this.processUserData(mesh);
                this.processOutLine(mesh);
            }
        });
    }

    /**
     * Recursively clean numeric suffixes from mesh and its children names
     * @param parent - The parent object (mesh, group, etc.)
     */
    private cleanMeshNames(parent: THREE.Object3D): void {
        if (parent.name) {
            parent.name = this.removeNameSuffix(parent.name);
        }
        parent.children.forEach((child) => this.cleanMeshNames(child));
    }

    /**
     * Removes numeric suffixes (e.g., "_1", "_2") from mesh names
     * @param name - The name of the object
     * @returns Cleaned name
     */
    private removeNameSuffix(name: string): string {
        return name.replace(/(_\d+|mesh_\d+)$/i, "");
    }

    /**
     * Process user data and convert properties to Three.js types
     * @param parent - The parent object in the scene
     */
    private processUserData(parent: THREE.Object3D): void {
        if (parent instanceof THREE.Mesh && parent.userData) {
            this.convertUserDataToThreeTypes(parent);
        }
        parent.children.forEach((child) => this.processUserData(child));
    }

    /**
     * Convert user data properties to Three.js types (Vector3, Material, Euler)
     * @param mesh - The mesh object with user data to process
     */
    private convertUserDataToThreeTypes(mesh: THREE.Mesh): void {
        const userData = mesh.userData;

        // Convert 'initialPosition' to THREE.Vector3 if available
        if (userData[Object3DUserData.initialPosition]) {
            const { x, y, z } = userData[Object3DUserData.initialPosition];
            mesh.userData[Object3DUserData.initialPosition] = new THREE.Vector3(x, y, z);
        }

        // Convert 'initialMaterial' to THREE.MeshBasicMaterial if available
        if (userData[Object3DUserData.initialMaterial]) {
            mesh.userData[Object3DUserData.initialMaterial] = mesh.material;
        }

        // Convert 'rotation' to THREE.Euler if available
        if (userData[Object3DUserData.rotation]) {
            const { x, y, z, order } = userData[Object3DUserData.rotation];
            mesh.userData[Object3DUserData.rotation] = new THREE.Euler(x, y, z, order);
        }
    }

    private processOutLine(parent: THREE.Object3D) {
        if (parent.name === AuxiliaryObjectType.Outline) {
            // Check if parent exists and is a Mesh
            const parentOfOutLine = parent.parent;
            if (!parentOfOutLine || !(parentOfOutLine instanceof THREE.Mesh)) {
                console.warn("Outline object has no valid parent mesh");
                return;
            }

            // Recreate the original material
            const originalMaterial = new THREE.MeshBasicMaterial({
                color: ColorCodes.black,
            });

            // Create new EdgesGeometry from the parent mesh's geometry
            const newEdgesGeometry = new THREE.EdgesGeometry(
                parentOfOutLine.geometry,
                AlteredShapeConfiguration.standardThreshHoldAngle,
            );

            // Apply the original geometry and material
            (parent as THREE.LineSegments).geometry = newEdgesGeometry;
            (parent as THREE.LineSegments).material = originalMaterial;
        }
        parent.children.forEach((child) => this.processOutLine(child));
    }

    /**
     * Recalculate cell reference for every mesh in the scene
     * @param isUpdated - A flag indicating if the mesh is updated or not
     */
    private recalculateCellReferences(isUpdated: boolean = false): void {
        if (isUpdated) {
            return;
        }
        // Iterate over all children in the scene
        this.scene.children.forEach((child) => {
            // Check if the child is a Mesh
            if (child instanceof THREE.Mesh && child.name !== AuxiliaryObjectType.Plane) {
                this.updateCellReference(child, isUpdated);
            }

            // Recursively process child objects (in case of nested groups)
            if (child.children && child.children.length > 0) {
                child.children.forEach((nestedChild) => {
                    if (nestedChild instanceof THREE.Mesh && child.name !== AuxiliaryObjectType.Plane) {
                        this.updateCellReference(nestedChild, isUpdated);
                    }
                });
            }
        });
    }

    /**
     * Update the cell reference for a single mesh
     * @param mesh - The mesh to update the cell reference for
     * @param isUpdated - A flag indicating if the mesh is updated or not
     */
    private updateCellReference(mesh: THREE.Mesh, isUpdated: boolean): void {
        const { row, column, cell } = this.helperService.calculateRowAndColumn(mesh);
        const cellReference: CellReference = {
            row: row,
            column: column,
            cell: cell,
        };
        mesh.userData[Object3DUserData.cellReference] = cellReference;
    }

    // Load from JSON
    public async loadFromJsonUrl(jsonUrl: string, isUpdated: boolean = false): Promise<void> {
        try {
            // Convert Observable to Promise for better async/await pattern
            const jsonArray = await firstValueFrom(this.structureService.fetchStructureData(jsonUrl));

            // Validate input array
            if (!Array.isArray(jsonArray)) {
                throw new Error("Invalid JSON structure: Expected an array");
            }

            // Process all meshes concurrently for better performance
            const meshCreationPromises = jsonArray.map(async (object) => {
                if (!object?.object?.type) {
                    throw new Error("Invalid mesh object: Missing type property");
                }

                if (object.object.type !== "Mesh") {
                    throw new Error(`Invalid mesh type: ${object.object.type}`);
                }

                return this.createMesh(object, true, isUpdated);
            });

            // Wait for all mesh creations to complete
            await Promise.all(meshCreationPromises);
        } catch (error) {
            throw new Error(StructureResponseMessage.LoadFail);
        }
    }

    public createMesh(object: any, ishavingoutline: boolean, isUpdated: boolean): THREE.Mesh {
        if (!object.geometries || !object.materials) {
            throw Error(StructureResponseMessage.InvalidObject);
        }

        const geometries = Array.isArray(object.geometries) ? object.geometries : [object.geometries];

        let mesh: THREE.Mesh | undefined;

        geometries.forEach(() => {
            let originalmaterial: THREE.MeshBasicMaterial | undefined;

            const geometry = this.shapeResourceManagerService.getGeometry(object.object.name as MeshType, false);
            originalmaterial = (object.materials as THREE.MeshBasicMaterial[]).find(
                (material) => material.uuid === object.object.material,
            );
            const material = new THREE.MeshBasicMaterial({
                color: originalmaterial!.color,
                side: THREE.DoubleSide,
                wireframe: false,
            });

            // Create the mesh
            if (geometry) {
                mesh = new THREE.Mesh(geometry, material);
                mesh.name = object.object.name;

                // Set initial position, rotation, and other properties
                mesh.position.set(
                    object.object.userData?.initialPosition?.x || 0,
                    object.object.userData?.initialPosition?.y || 0,
                    object.object.userData?.initialPosition?.z || 0,
                );

                // Handle rotation if any
                if (object.object.userData?.rotation) {
                    mesh.rotation.set(
                        object.object.userData?.rotation?.x,
                        object.object.userData?.rotation?.y,
                        object.object.userData?.rotation?.z,
                    );
                }

                // Add outline if needed
                if (ishavingoutline) {
                    this.shapeResourceManagerService.addOutlineToMesh(mesh, false);
                }

                // Set the userData and name for the mesh
                mesh.userData = {
                    ...object.object.userData,
                    initialPosition: object.object.userData?.initialPosition
                        ? this.toVector3(object.object.userData.initialPosition)
                        : undefined,
                    initialMaterial: mesh.material,
                    rotation: object.object.userData?.rotation
                        ? this.toEuler(object.object.userData?.rotation)
                        : undefined,
                };
                mesh.name = object.object.name;
            }
        });

        // Recalculate cell reference
        if (mesh && !isUpdated) {
            const { row, column, cell } = this.helperService.calculateRowAndColumn(mesh as THREE.Mesh);
            const cellReference: CellReference = {
                row: row,
                column: column,
                cell: cell,
            };
            mesh.userData[Object3DUserData.cellReference] = cellReference;
        }
        this.scene.add(mesh!);
        this.objectsSubject.next(this.scene.children);
        return mesh!;
    }

    public createMeshForBlock(
        jsonData: any,
        ishavingoutline: boolean,
        color: THREE.ColorRepresentation | string,
        useExistingMaterial: boolean = false,
    ): THREE.Mesh | null {
        try {
            this.cacheGeometriesAndMaterials(jsonData, color, useExistingMaterial);
            // Create geometry
            const geometry = this.createGeometryForBlock(jsonData.object.geometry, jsonData);
            if (!geometry) return null;

            const materialArray: THREE.Material[] = [];
            const textureLoader = new THREE.TextureLoader();

            // Process images/textures if they exist
            if (jsonData.images && Array.isArray(jsonData.images)) {
                jsonData.images.forEach((textured: any) => {
                    if (textured && textured.url) {
                        const texture = textureLoader.load(textured.url);
                        materialArray.push(
                            new THREE.MeshBasicMaterial({
                                map: texture,
                                color: color as THREE.ColorRepresentation,
                                transparent: true,
                                side: THREE.DoubleSide,
                            }),
                        );
                    }
                });
            }

            // If no textures were processed, create a default material
            const defaultMaterial = this.materialCache.get(jsonData.object.material);

            // Create the mesh with appropriate material
            const mesh = new THREE.Mesh(geometry, materialArray.length > 0 ? materialArray : defaultMaterial);

            // Apply matrix transformation if present
            if (jsonData.object.matrix) {
                const matrix = new THREE.Matrix4();
                matrix.fromArray(jsonData.object.matrix);
                mesh.applyMatrix4(matrix);
            }

            // Set name and other properties
            mesh.name = jsonData.object.name || "";

            // Handle any additional properties from the JSON
            if (jsonData.object.userData) {
                mesh.userData = { ...jsonData.object.userData };
            }

            // Add outline if needed
            if (ishavingoutline) {
                if (geometry instanceof THREE.BoxGeometry) {
                    const edges = new THREE.EdgesGeometry(mesh.geometry);
                    const lineMaterial = new THREE.LineBasicMaterial({ color: Number(ColorCodes.black) });
                    const lineSegments = new THREE.LineSegments(edges, lineMaterial);
                    mesh.add(lineSegments);
                } else {
                    const edgesGeometry = new THREE.EdgesGeometry(
                        geometry,
                        AlteredShapeConfiguration.standardThreshHoldAngle,
                    );
                    const edgesMaterial = new THREE.LineBasicMaterial({ color: Number(ColorCodes.black) });
                    const edges = new THREE.LineSegments(edgesGeometry, edgesMaterial);
                    mesh.add(edges);
                }
            }

            return mesh;
        } catch (error) {
            return null;
        }
    }

    private createGeometryForBlock(geometryUuid: string, jsonData: any): THREE.BufferGeometry | null {
        const geometryData = this.findGeometryByUuid(geometryUuid);
        if (!geometryData) return null;

        let geometry: THREE.BufferGeometry | null = null;

        switch (geometryData.type) {
            case ShapeType.Geometry.BoxGeometry:
                geometry = new THREE.BoxGeometry(geometryData.width, geometryData.height, geometryData.depth);
                geometry.center();
                break;

            case ShapeType.Geometry.SphereGeometry:
                geometry = new THREE.SphereGeometry(4, 30, 30);

                const positionAttribute = geometry.attributes["position"] as THREE.BufferAttribute;

                if (positionAttribute && positionAttribute.array) {
                    const verts = positionAttribute.array as Float32Array;
                    for (let i = 0; i < verts.length; i += 3) {
                        if (verts[i + 1] < 0) {
                            verts[i + 1] = 0;
                        }
                    }
                }
                geometry.center();

                break;

            case ShapeType.Geometry.CircleGeometry:
                geometry = new THREE.CircleGeometry(
                    geometryData.radius,
                    geometryData.segments,
                    geometryData.thetaStart,
                    geometryData.thetaLength,
                );
                break;

            case ShapeType.Geometry.ConeGeometry:
                geometry = new THREE.ConeGeometry(
                    geometryData.radius,
                    geometryData.height,
                    geometryData.radialSegments,
                    geometryData.heightSegments,
                    false,
                    geometryData.thetaStart,
                );
                break;

            case ShapeType.Geometry.CylinderGeometry:
                geometry = new THREE.CylinderGeometry(
                    geometryData.radiusTop,
                    geometryData.radiusBottom,
                    geometryData.height,
                    geometryData.radialSegments,
                    geometryData.heightSegments,
                    false,
                    geometryData.thetaStart,
                    geometryData.thetaLength,
                );
                geometry.center();
                break;

            case ShapeType.Geometry.ExtrudeGeometry:
                const shape = new THREE.Shape();
                const shapesData = jsonData.shapes || [];
                shapesData.forEach((shapeData: any) => {
                    const path = this.createPathFromCurves(shapeData.curves);
                    shape.add(path);
                });
                geometry = new THREE.ExtrudeGeometry(shape, geometryData.options);
                geometry.center();
                break;
            case "EdgesGeometry":
                const baseGeometry = this.createGeometryForBlock(geometryData.geometry.uuid, jsonData);
                if (baseGeometry) {
                    geometry = new THREE.EdgesGeometry(baseGeometry, geometryData.thresholdAngle);
                }
                break;
        }
        return geometry;
    }

    private findGeometryByUuid(uuid: string): any {
        return uuid && this.geometriesData?.find((g: any) => g.uuid === uuid);
    }

    private cacheGeometriesAndMaterials(
        jsonData: any,
        color: THREE.ColorRepresentation | string,
        useExistingMaterial: boolean,
    ): void {
        this.geometriesData = jsonData.geometries || [];

        // Cache materials
        if (jsonData.materials) {
            jsonData.materials.forEach((materialData: any) => {
                let material: THREE.Material;

                if (useExistingMaterial) {
                    switch (materialData.type) {
                        case ShapeType.Material.MeshBasicMaterial:
                            material = new THREE.MeshBasicMaterial({
                                color: materialData.color,
                                side: THREE.DoubleSide,
                                transparent: false,
                            });
                            break;
                        default:
                            material = new THREE.MeshStandardMaterial({
                                color: materialData.color,
                                side: THREE.DoubleSide,
                                transparent: false,
                            });
                            break;
                    }
                } else {
                    material = new THREE.MeshStandardMaterial({
                        color: materialData.color,
                        side: THREE.DoubleSide,
                        transparent: false,
                    });
                }

                this.materialCache.set(materialData.uuid, material);
            });
        }
    }

    // Helper function for creating materials
    private createMaterials(
        texturesData: any[],
        textureLoader: THREE.TextureLoader,
        selectedColour: THREE.ColorRepresentation | string,
        materialData: any,
        isForBlock: boolean,
    ): THREE.Material[] | THREE.Material {
        const color = selectedColour || "#117A65";
        const materialArray: THREE.Material[] = [];
        let material: THREE.Material | undefined;

        if (!isForBlock) {
            material = new THREE.MeshBasicMaterial({
                color: materialData.color || "#117A65",
                side: THREE.DoubleSide,
                transparent: false,
            });
        } else {
            texturesData.forEach((textured: any) => {
                if (textured) {
                    const texture = textureLoader.load(textured.url);
                    materialArray.push(
                        new THREE.MeshBasicMaterial({
                            map: texture,
                            color: selectedColour as THREE.ColorRepresentation,
                            transparent: true,
                            side: THREE.DoubleSide,
                        }),
                    );
                } else {
                    material = new THREE.MeshStandardMaterial({
                        color: color as THREE.ColorRepresentation,
                        side: THREE.DoubleSide,
                        transparent: false,
                    });
                }
            });
        }
        return materialArray.length > 0 ? materialArray : material || new THREE.MeshBasicMaterial({ color: "#117A65" });
    }

    private toVector3(position: { x: number; y: number; z: number }): THREE.Vector3 {
        return new THREE.Vector3(position.x, position.y, position.z);
    }

    private toEuler(rotation: { x: number; y: number; z: number; order: THREE.EulerOrder }) {
        return new THREE.Euler(rotation.x, rotation.y, rotation.z, rotation.order);
    }

    private createPathFromCurves(curveData: any) {
        const path = new THREE.Path();
        curveData.forEach((curve: any) => {
            if (curve.type === "EllipseCurve") {
                path.absellipse(
                    curve.aX,
                    curve.aY,
                    curve.xRadius,
                    curve.yRadius,
                    curve.aStartAngle,
                    curve.aEndAngle,
                    curve.aClockwise,
                    curve.aRotation || 0,
                );
            } else if (curve.type === "LineCurve") {
                path.lineTo(curve.v2[0], curve.v2[1]);
            } else if (curve.type === "Path" && curve.curves) {
                path.add(this.createPathFromCurves(curve.curves));
            }
        });
        return path;
    }

    public hasShapes(): boolean {
        return this.scene.children.some(
            (obj) => obj instanceof THREE.Mesh && obj.name !== "plane", // Ignore plane
        );
    }
}
