import { CanvasConfig } from "./canvas-configuration";
import { Face } from "./count-calculator/count-calculator.model";

export enum Object3DUserData {
    initialPosition = "initialPosition",
    isDraggable = "isDraggable",
    initialMaterial = "initialMaterial",
    isInCollision = "isInCollision",
    collidedObjectId = "collidedObjectId",
    adjacentData = "adjacentData",
    cellReference = "cellReference",
    faceType = "faceType",
    facePattern = "facePattern",
    colour = "colour",
    scaleCount = "scaleCount",
    connectionData = "connectionData",
    isTilted = "isTilted",
    rotationData = "rotation",
    orientationData = "orientation",
    rotation = "rotation",
    originalColor = "originalColor",
}

export enum TransformShapeOperations {
    expand = "expand",
    compress = "compress",
}

export enum PlaneCustomProperties {
    orientationKey = "transformOrientationObject",
    isDraggable = "isDraggable",
}

/*
  We use this altered configuration because we are manually calculating the centroid of the custom shapes,
  This minor adjustment done for all the custom shape to match with that of the cube which is
  default given shape from three.js. And altered configurations are done only once for all the custom shapes.
*/
export enum AlteredShapeConfiguration {
    divisorAdjustYPosition = 2,
    gridHelperYCoOrdinatesAdjustment = 5.5, // this is to place the grid on the plane in a visible distance
    standardHeigthOfShape = 20, // as a base constant for offsetCalculation.
    standardThreshHoldAngle = 14, // For customShapes
    yOffsetForHalfcylinder = 15,
    yOffsetForPrism = 3.332,
    yOffsetForRightTriangle = 3.338,
    xOffsetForRightTriangle = 3.34,
    yOffsetForHemiSphere = 10,
}

export enum selectedShapeColor {
    code = "#117A65",
}

export enum ActionConfiguration {
    offsetHemisphere = 10,
    offsetSquarePyramidPositionY = 1,
}

export const initialOrientation = ["Front", "Right", "Back", "Left", "Top", "Bottom"];

export enum ReferenceMeshName {
    LeftMesh = "LeftMesh",
    RightMesh = "RightMesh",
    BackMesh = "BackMesh",
    FrontMesh = "FrontMesh",
    TopMesh = "TopMesh",
    BottomMesh = "BottomMesh",
}

export const validOrientations = [
    [Face.Top, Face.Front, Face.Bottom, Face.Back, Face.Right, Face.Left],
    [Face.Back, Face.Top, Face.Front, Face.Bottom, Face.Right, Face.Left],
    [Face.Bottom, Face.Back, Face.Top, Face.Front, Face.Right, Face.Left],
    [Face.Front, Face.Bottom, Face.Back, Face.Top, Face.Right, Face.Left],
    [Face.Front, Face.Top, Face.Back, Face.Bottom, Face.Left, Face.Right],
    [Face.Bottom, Face.Front, Face.Top, Face.Back, Face.Left, Face.Right],
    [Face.Back, Face.Bottom, Face.Front, Face.Top, Face.Left, Face.Right],
    [Face.Top, Face.Back, Face.Bottom, Face.Front, Face.Left, Face.Right],
];
